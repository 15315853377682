import React from "react";

const SmallAddButton = ({onClick, btnType, className, children, size}) => {
    return (
            <button className={`small-add-btn px-2 py-1 ${className ? className : ""}`} onClick={() => onClick()}>
                <i className="fas fa-plus-circle mr-2 small-add-btn-icon"></i>
                {children}
                <style>{`
    
                .small-add-btn {
                    border: ${btnType ==="secondary" ? `none` : `1px solid #572148`};
                    background: #fff;
                    color: #572148;
                    border-radius: 6px;
                    transition: all 0.2s;
                    font-size: ${size ? size : "16px"};
                }
    
                .small-add-btn:hover {
                    background: #ececec;
                }

                .small-add-btn-icon {
                    font-size: ${size ? size : "16px"} ;
                }
    
                .small-add-btn:active {
                    background: #e3e2e2;
                }
    
                `}</style>
            </button>
    )
}

export default SmallAddButton;