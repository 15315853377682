import React, { useState, useEffect } from "react";
import ClearText from "../../assets/img/ClearText.svg";

const SearchBar = ({
	setSearchFunction,
	containerClass,
	search = "",
	onSearchClear,
	searchPlaceholder,
	height
}) => {
	const [searchText, setSeatchText] = useState(search);
	useEffect(() => {
		setSeatchText(search);
	}, [search]);

	return (
		<div
			className={`searchIconContainer ${containerClass ? containerClass : ""}`}
		>
			<input
				type="text"
				className="input general-search-bar-comp"
				value={searchText}
				placeholder={searchPlaceholder}
				onChange={(e) => {
					setSearchFunction(e.target.value);
					setSeatchText(e.target.value);
				}}
			/>
			<span className="iconRight">
				{searchText?.trim() ? (
					<img
						src={ClearText}
						alt="ClearText"
						className="mr-2"
						style={{
							color: "grey",
							cursor: "pointer",
							fontSize: "15px",
							// marginTop: "3px",
						}}
						onClick={() => {
							setSearchFunction("");
							setSeatchText("");
						}}
					/>
				) : (
					<i className="fa fa-search mr-2"></i>
				)}
			</span>
			<style>{`
				.general-search-bar-comp {
					height: ${height ? height : "auto"};
				}
			`}</style>
		</div>
	);
};

export default SearchBar;
