import React, {useState, useEffect, useCallback} from 'react';
import { debounce } from "lodash";
import { CustomModal } from "../../../revampComponents/Atom/Modals";
import ToolTip from "../../../revampComponents/Atom/ToolTip";
import TimezoneSelect from '../../../revampComponents/Atom/Input/TimezoneSelect';
import {
	DateTimePicker,
	RainbowThemeContainer,
} from "react-rainbow-components";
import moment from "moment";
import { convertToDateObj } from '../../../Utils/helpers';
import IconDateTime from "../../../revampComponents/Atom/Icons/IconDateTime.svg";
import Global from '../../../revampComponents/Atom/Global';
import Dropdown from '../../../revampComponents/Atom/Dropdown';
import { KonfhubButton } from '../../../revampComponents/Atom/Buttons';
import { baseUrl } from '../../../config/config';
import { ValidateUrlRealtime } from '../../../revampPages/EventInfo/EventDetails/BasicDetails/Validations';
import { errorToaster } from '../../../reusable/common';
import { ValidateURL } from '../../../revampCRUD/eventDetails.crud';

const CloneEventModal = ({
    openCloneModal,
    setOpenCloneModal,
    isApiLoading,
    formik
}) => {
    const [load, setLoad] = useState(false);
    const [APICalled, setAPICalled] = useState(false);
    const [isValueChanged, setIsValueChanged] = useState(false);
    const [UrlSuccessResponse, setUrlSuccessResponse] = useState("");
    const [UrlFailerResponse, setUrlFailerResponse] = useState("");

    const ValidateURLFunction = async url => {
		setAPICalled(true); // for render conditions at line : 169.
		setIsValueChanged(true); // same reason as above.
		try {
			const response = await ValidateURL(url);
			if (response.status == 200) {
				/* Erasing the FailedResponse first //-> because it glitched for a second before updating the success Info Icon*/
				setUrlFailerResponse("");
				setUrlSuccessResponse(response.data);
			} else {
				/* same reason as above */
				setUrlSuccessResponse("");
				setUrlFailerResponse(JSON.stringify(response));
			}
		} catch (err) {
			setUrlSuccessResponse("");
			errorToaster("Something went wrong while validating event URL");
			// setUrlFailerResponse();
		}
		setLoad(false);
	};
    const handlerURL = useCallback(debounce(ValidateURLFunction, 500), []);
    
	useEffect(() => {
		const newUrl = formik.values.event_url;
		//code to remove hyphens from string to check if string contains special characters
		const urlWithoutHyphen = `${newUrl.replace(/-/g, "")}`;
		const containsSpecialCharacter = /[^a-zA-Z0-9]/.test(urlWithoutHyphen);
		if (openCloneModal && newUrl && !containsSpecialCharacter) {
			setLoad(true);
			handlerURL(formik.values.event_url);
		}

		setTimeout(() => {
			if (newUrl.length == 0) setIsValueChanged(false);
		}, 500);
	}, [formik?.values?.event_url]);


    const theme = {
		rainbow: {
			palette: {
				// brand: "#3E4676"
				brand: "#572148",
			},
		},
    };

    const eventPlan = (value) => {
        let planName;
        if(value === 1) {
            planName= "Free"
        } else if(value === 2) {
            planName= "lite"
        } else if(value === 3) {
            planName= "Silver"
        } else if(value === 4) {
            planName= "Gold"
        } else{
            planName= "custom"
        }

        return planName;
    }

    ValidateUrlRealtime(
		formik,
		setAPICalled,
		setIsValueChanged,
		setUrlFailerResponse,
		setUrlSuccessResponse,
		setLoad,
	);

    
   
const handleEventDetailsChange = (e) => {
    const checked = e.target.checked;
    formik.setFieldValue("clone_event_details", checked);
    formik.setFieldValue("clone_event_posters", checked);
    formik.setFieldValue("clone_organizer_details", checked);
};

const handleEventPostersChange = (e) => {
    const checked = e.target.checked;
    formik.setFieldValue("clone_event_posters", checked);

    // Check or uncheck clone_event_details based on the child checkboxes
    const organizerChecked = formik.values.clone_organizer_details;
    formik.setFieldValue("clone_event_details", checked || organizerChecked);
};

const handleOrganizerDetailsChange = (e) => {
    const checked = e.target.checked;
    formik.setFieldValue("clone_organizer_details", checked);

    // Check or uncheck clone_event_details based on the child checkboxes
    const postersChecked = formik.values.clone_event_posters;
    formik.setFieldValue("clone_event_details", checked || postersChecked);
};

// Parent-Child Relationship: clone_tickets
const handleEventTicketsChange = (e) => {
    const checked = e.target.checked;
    formik.setFieldValue("clone_tickets", checked);
    formik.setFieldValue("clone_coupons", checked);
    formik.setFieldValue("clone_event_email_contents", checked);
    formik.setFieldValue("clone_event_forms", checked);
};

const handleCouponsChange = (e) => {
    const checked = e.target.checked;
    formik.setFieldValue("clone_coupons", checked);

    // Check or uncheck clone_tickets based on the child checkboxes
    const emailChecked = formik.values.clone_event_email_contents;
    const formsChecked = formik.values.clone_event_forms;
    formik.setFieldValue("clone_tickets", checked || emailChecked || formsChecked);
};

const handleEmailContentsChange = (e) => {
    const checked = e.target.checked;
    formik.setFieldValue("clone_event_email_contents", checked);

    // Check or uncheck clone_tickets based on the child checkboxes
    const couponsChecked = formik.values.clone_coupons;
    const formsChecked = formik.values.clone_event_forms;
    formik.setFieldValue("clone_tickets", checked || couponsChecked || formsChecked);
};

const handleEventFormsChange = (e) => {
    const checked = e.target.checked;
    formik.setFieldValue("clone_event_forms", checked);

    // Check or uncheck clone_tickets based on the child checkboxes
    const couponsChecked = formik.values.clone_coupons;
    const emailChecked = formik.values.clone_event_email_contents;
    formik.setFieldValue("clone_tickets", checked || couponsChecked || emailChecked);
};

    

    return (
        <>
            <CustomModal
                openModal={openCloneModal}
                setOpenModal={setOpenCloneModal}
                saveBtnDisabled={isApiLoading}
                isLoading={isApiLoading}
                width={"65%"}
                content={
                    <div className="pt-3 scroll-div">
                        <p className="formTitle fw-bold mb-4 ">
                            Clone Event
                        </p>
                        <div className="row">
                            <div className="col-md-12">
                                <label className="label">
                                    Event Name <span className="text-danger"> *</span>
                                    <ToolTip data="Provide a concise and attractive event name; Example: Cloud Community Days 2020 Avoid abbreviations in the title like CCD 2020." />
                                </label>
                                <input 
                                    type="text"
                                    className="input"
                                    id="event_name"
                                    name="event_name"
                                    value={formik.values.event_name}
                                    onChange={(e) => formik.setFieldValue("event_name", e.target.value)}
                                />
                                {formik.errors.event_name && formik.touched.event_name && (
                                    <p className="custom-error-input-class text-danger mt-2">
                                        {formik.errors.event_name}
                                    </p>
                                )}
                            </div>
                            <div className="col-md-12 mt-3">
                            <label className="label" htmlFor="event_url">
							Event URL
							<span className="text text-danger"> *</span>
							<ToolTip
								data="Provide a Unique URL that you can easily remember and share to others (e.g., in social media). You can use alphanumeric characters and hyphen - but no whitespace and other special characters. Example: https://konfhub.com/jcloud-day 
                                        If the URL is available, it will be provided to you as event URL - otherwise, you will have to select another one. "
							/>
						</label>

						<div className="pl-1">
							<div className="custom-mixUrlField w-100">
								<p className="m-0">
									<a
										style={{
											paddingBlock: "17px",
										}}
										className="custom-domain-holder"
									>
										{baseUrl}/
									</a>
								</p>
								<p className="custom-icon-in-input w-100 m-0">
									<input
										type="text"
										className={
											formik.errors.event_url && formik.touched.event_url
												? `input border-danger w-100`
												: `input w-100`
										}
										id="event_url"
										name="event_url"
										value={formik.values.event_url}
										onChange={(e) =>
											formik.setFieldValue(
												"event_url",
												e.target.value.toLowerCase(),
											)
										}
										onBlur={formik.handleBlur}
										autoComplete="off"
									/>
									{/* Copy to Clipboard - a small icon for copying the entire url is within the input field */}
									{load && (
										<div
											className="col-2 custom-input-icon mr-4"
											style={{
												color: "#572148",
												zIndex: "auto",
												marginTop: "4px",
											}}
										>
											<i className="fa fa-circle-notch fa-spin"></i>
										</div>
									)}
									{!load && // if load stopped i.e user stopped typing.
									APICalled &&
									UrlSuccessResponse &&
									isValueChanged &&
									!formik.errors.event_url ? (
										<div
											className="col-2 custom-input-icon mr-4 pt-1"
											style={{
												color: "lightGreen",
												zIndex: "auto",
											}}
										>
											<i>
												<ToolTip icon="fas fa-check" data="URL available" />
											</i>
										</div>
									) : null}
								</p>
							</div>
                        </div>
						{!load &&
						APICalled &&
						UrlFailerResponse &&
						!formik.errors.event_url ? (
							<p className="custom-error-input-class text-danger pt-1">
								{
									(formik.errors["unavailable api"] =
										"Requested URL is already taken")
								}
							</p>
						) : formik.errors["event_url"] && formik.touched.event_url ? (
							<p className="custom-error-input-class text-danger pt-1">
								{formik.errors.event_url}
							</p>
                        ) : null}
                        </div>
                            <div className="col-md-6 mt-3">
                            <label className="label" htmlFor="start_date">
                            Start Date & Time
                            <span className="text text-danger"> *</span>
                            <span className="timezoneContainer">
                                <Global timeZone={formik.values.time_zone} />
                            </span>
                        </label>
                        {/* <div className="custom-dateTimeInput w-100"> */}
                        <RainbowThemeContainer
                        theme={theme}
                        className="rainbow-align-content_center rainbow-m-vertical_large rainbow-p-horizontal_small rainbow-m_auto">
                        <DateTimePicker
                            locale="en-IN"
                            id="start_date"
                            name="start_date"
                            formatStyle="medium"
                            value={convertToDateObj(formik.values.start_date)}
                            className={
                                formik.errors.start_date && formik.touched.start_date
                                    ? "calendarCus border-danger box-shawdow-none width85"
                                    : "calendarCus"
                            }
                            minDate={new Date()} // Set minDate to the current date
                            icon={<img src={IconDateTime} />}
                            hideLabel={true}
                            okLabel="Save"
                            onChange={e => {
                                let tempDate = moment(e).format("YYYY-MM-DD HH:mm:ss");
                                const newDate = tempDate.replace(/ /g, "T");
                                formik.setFieldValue("start_date", newDate);
                                formik.setFieldValue(
                                    "start_time",
                                    newDate.slice(newDate.length - 8, newDate.length)
                                );
                            }}
                            onBlur={() => formik.setFieldTouched("start_date", true)}
                        />
                    </RainbowThemeContainer>
                        {formik.errors.start_date && formik.touched.start_date && (
                            <p className="custom-error-input-class text-danger mt-2">
                                {formik.errors.start_date}
                            </p>
                        )}
                            </div>
                            <div className="col-md-6 mt-3">
                            <label className="label" htmlFor="end_date">
                            End Date & Time
                            <span className="text text-danger"> *</span>
                            <span className="timezoneContainer">
                                <Global timeZone={formik.values.time_zone} />
                            </span>
                        </label>
    
                        {/* <div className="custom-dateTimeInput w-100"> */}
                        <RainbowThemeContainer
                        theme={theme}
                        className="rainbow-align-content_center rainbow-m-vertical_large rainbow-p-horizontal_small rainbow-m_auto">
                        <DateTimePicker
                            locale="en-IN"
                            id="end_date"
                            name="end_date"
                            formatStyle="medium"
                            className="calendarCus"
                            value={convertToDateObj(formik.values.end_date)}
                            icon={<img src={IconDateTime} />}
                            minDate={new Date()}
                            onChange={e => {
                                try {
                                    const tempDate = moment(e).format(
                                        "YYYY-MM-DD HH:mm:ss"
                                    );
                                    const newDate = tempDate.replace(/ /g, "T");
                                    formik.setFieldValue("end_date", newDate);
                                    formik.setFieldValue(
                                        "end_time",
                                        newDate.slice(newDate.length - 8, newDate.length)
                                    );
                                } catch (err) {
                                    console.log(err);
                                }
                            }}
                            onBlur={() => formik.setFieldTouched("end_date", true)}
                        />
                    </RainbowThemeContainer>
                    {formik.errors.end_date && formik.touched.end_date && (
                        <p className="custom-error-input-class text-danger mt-2">{formik.errors.end_date}</p>
                    )}
    
                            </div>
                            <div className="col-lg-6 col-xs-12 mt-3">
								<label className="label" htmlFor="time_zone">
									Time Zone
									<span className="text text-danger"> *</span>
									<ToolTip data="The time zone applies to the entire event including tickets and coupons. " />
								</label>
								<TimezoneSelect
                                    value={formik.values.time_zone}
									onChange={e => {
										formik.setFieldValue("time_zone", e.value);
									}}
								/>
                            </div>
                        </div>
                        <div className="mt-4">
                            <p className="label">
                                Select the Modules to be Cloned
                            </p>
                            <div className="d-flex">
                                <input 
                                    type="checkbox"
                                    id="clone_event_details"
                                    name="clone_event_details"
                                    checked={formik.values.clone_event_details}
                                    onChange={handleEventDetailsChange}
                                />
                                <label className="label">
                                    Event Details
                                </label>
                            </div>
                            <div className="d-flex">
                                <input 
                                    type="checkbox"
                                    id="clone_event_posters"
                                    name="clone_event_posters"
                                    checked={formik.values.clone_event_posters}
                                    onChange={handleEventPostersChange}
                                />
                                <label className="label">
                                    Event Poster
                                </label>

                                <input 
                                    type="checkbox"
                                    id="clone_organizer_details"
                                    name="clone_organizer_details"
                                    checked={formik.values.clone_organizer_details}
                                    className="ml-4"
                                    onChange={handleOrganizerDetailsChange}
                                />
                                <label className="label">
                                    Organizer Details
                                </label>
                            </div>
                            <span className="vertical-border mt-2 mb-2"></span>
                            <div className="d-flex">
                            <input type="checkbox"
                                id="clone_tickets"
                                name="clone_tickets"
                                checked={formik.values.clone_tickets}
                                onChange={handleEventTicketsChange}
                            />
                            <label className="label">
                                Event Tickets
                            </label>
                            </div>
                            <div className="d-flex">
                            <input 
                                type="checkbox" 
                                id="clone_coupons"
                                name="clone_coupons"
                                checked={formik.values.clone_coupons}
                                onChange={handleCouponsChange}
                            />
                            <label className="label">
                               Coupons
                            </label>
                            <input 
                                type="checkbox" 
                                id="clone_event_email_contents"
                                name="clone_event_email_contents"
                                className="ml-4"
                                checked={formik.values.clone_event_email_contents}
                                onChange={handleEmailContentsChange}
                            />
                            <label className="label">
                                Email Content
                            </label>
                            <input 
                                type="checkbox" 
                                id="clone_event_forms"
                                name="clone_event_forms"
                                className="ml-4"
                                checked={formik.values.clone_event_forms}
                                onChange={handleEventFormsChange}
                            />
                            <label className="label">
                                Attendee Forms
                            </label>
                            </div>
                            <span className="vertical-border mt-2 mb-2"></span>
                            <div className="d-flex">
                            <input 
                                type="checkbox" 
                                id="clone_team_details"
                                name="clone_team_details"
                                checked={formik.values.clone_team_details}
                                onChange={e => {
                                    if (e.target.checked)
                                    formik.setFieldValue("clone_team_details", true);
                                    else formik.setFieldValue("clone_team_details", false);
                                }}
                            />
                            <label className="label">
                               Team 
                            </label>
                            </div>
                            <span className="vertical-border mt-2 mb-2"></span>
                            <div className="d-flex">
                            <input 
                                type="checkbox" 
                                id="clone_stripe_or_bank_account"
                                name="clone_stripe_or_bank_account"
                                checked={formik.values.clone_stripe_or_bank_account}
                                onChange={e => {
                                    if (e.target.checked)
                                    formik.setFieldValue("clone_stripe_or_bank_account", true);
                                    else formik.setFieldValue("clone_stripe_or_bank_account", false);
                                }}
                            />
                            <label className="label">
                                Bank Details
                            </label>
                            </div>
                        </div>
                        <div className="note-border text-center mt-4">
                                <p className="mb-0 p-3">
                                    <b>Note: </b> Event Type, Event Mode, and Currency are also cloned by default
                                </p>
                        </div>
                        <div className="mt-4 mx-3 mb-3 d-flex justify-content-end">
                        <KonfhubButton
							btnType="secondary"
							className="mr-2"
                            onClick={() => {
								setOpenCloneModal(false)
                            }}>
							   Cancel
						</KonfhubButton>
						<KonfhubButton
							className="mr-2"
							disabled={isApiLoading || !formik.dirty}
                            onClick={() =>
                                formik.handleSubmit()
							}>
								Clone Event
						</KonfhubButton>
                        </div>
                </div>
                }
            />
            <style>{`
				.ReactModal__Content--after-open {
					overflow-y: auto !important;
                    max-height: 85vh;
                }
                .vertical-border{
                    width: 100%;
                    border: 1px solid #C3CDDF59;
                    display: inline-block;
                }
                .custom-domain-holder {
                    margin-inline: -3px !important;
                    background-color: whitesmoke;
                    color: #7a7a7a;
                    align-items: center;
                    border: 1px solid #cdd4db;
                    border-radius: 4px;
                    font-weight: 600 !important;
                    display: flex;
                    font-size: 15px;
                    height: 2.25em;
                    line-height: 1.5;
                    padding-bottom: calc(0.375em - 0.5px);
                    padding-left: calc(0.625em - 0.5px);
                    padding-right: calc(0.625em - 0.5px);
                    padding-top: calc(0.375em - 0.5px);
                    position: relative;
                    vertical-align: top;
                    width: max-content;
                    cursor: default;
                    text-decoration: none;
                }
                .custom-domain-holder:hover {
                    color: #7a7a7a;
                    text-decoration: none;
                }
                .custom-mixUrlField {
                    display: inline-flex;
                }
                .custom-error-input-class {
					display: block;
					font-size: 14px;
					margin-top: 0.25rem;
					color: #ea4e2d;
				}
				.custom-icon-in-input {
					box-sizing: border-box !important;
					clear: both !important;
					font-size: 0.9rem !important;
					position: relative !important;
					text-align: left !important ;
				}
				.custom-input-icon {
					color: #dbdbdb;
					// height: 2.25em;
					cursor: pointer;
					position: absolute;
					display: flex;
					justify-content: center;
					// align-items: center;
					top: 7px;
					right: 0;
					width: 2.25em;
					z-index: 4;
                }
                .note-border{
                    border: 1px solid #E2E2E2;
                    background: #F8F8F8;
                    font-family: Hind;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 22.41px;
                    color: #6C757D;
                }
			`}</style>
        </>
    )
}

export default CloneEventModal
