import React, { useEffect, useState } from "react";

const LegendColor = ({ legendColors }) => {
	return (
		<div className="legendContainer">
			<div className="legendInside px-3 py-2 d-flex justify-content-center align-items-center">
				<ul className="legend mb-0">
					{legendColors.map((color, index) => {
						return (
							<li key={index}>
								<span className={color.replace(/\s/g,'')}></span> {color}
							</li>
						);
					})}
				</ul>
			</div>

			<style jsx>{`
				.legendContainer {
					position: -webkit-sticky;
					position: sticky;
					bottom: 10px;
					display: flex;
					z-index: 99;
					justify-content: center;
				}

				.legendInside {
					background: rgba(87, 33, 72, 0.65);
					box-shadow: 0px 4px 15px 0px rgb(0 0 0 / 15%);
					backdrop-filter: blur(50px);
					border-radius: 40px;
				}
				/* basic positioning */
				.legend {
					list-style: none;
				}
				.legend li {
					float: left;
					margin-right: 10px;
					color: white;
				}
				.legend span {
					border-radius: 58%;
					border: 3px solid white;
					float: left;
					width: 15px;
					height: 15px;
					margin: 2px 5px 2px 2px;
					transform: translateY(1px);
				}
				/* your colors */
				.legend .PartialRefund {
					background-color: #effc6c;
				}
				.legend .CompleteRefund {
					background-color: #B2BDE5;
				}
				.legend .Masked {
					background-color: #f77757;
				}
				.legend .TicketChanged {
					background-color: #E8EAFF;
				}
				.legend .notawesome {
					background-color: #000000;
				}
			`}</style>
		</div>
	);
};

export default LegendColor;
