import React from "react";
import { websiteUrl } from "../../config/config";
import ProgressBar from "@ramonak/react-progress-bar";
import TippyIcon from "../../revampComponents/Atom/TippyIcon";

const EventLimits = ({ eventDetails, participantCount, isInternational }) => {
	if (!eventDetails?.subscription_payment_id && isInternational) return null;
	if (!isInternational && !eventDetails?.eventPlanName) return null;
	return (
		<TippyIcon content="Event Registration Limit">
			<div className="d-flex">
				<div className="event-limit-border d-flex justify-content-center align-items-center">
					<i
						className="fas fa-users"
						aria-hidden="true"
						style={{ color: "#FFF", fontSize: "16px" }}
					></i>
				</div>
				<div className="ml-2 event-limit-container">
					<span className="d-block ml-1" style={{ color: "white" }}>
						{participantCount}/{eventDetails?.max_regs_count}
					</span>
					<ProgressBar
						className={`progress-overview-section text-danger`}
						completed={participantCount}
						maxCompleted={eventDetails?.max_regs_count}
						bgColor="#34A853"
						labelColor="white"
						height="5px"
						customLabel={" "}
						// labelAlignment={e.total == null ? "center" : "outside"}
						labelSize={5}
					/>
				</div>

				<style>{`
					.event-limit-border {
						width: 36px;
						height: 36px;
						border: 1px solid #FFF;
						border-radius: 100%;
					}		

					.event-limit-container {
						min-width: 70px;
					}
        	    `}</style>
			</div>
		</TippyIcon>
	);
};

export default EventLimits;
