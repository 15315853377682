import React, { useContext } from "react";
import {useHistory} from "react-router-dom"
import KonfhubButton from "../../Atom/Buttons/KonfhubButton";
import lockedIcon from "../../../assets/img/Pricing-plan/🦆 icon _lock_.svg"
import AuthContext from "../../../contexts/AuthContext";
import { isFeatureRestricted } from "../../../Utils/helpers";

const LockableButton = ({
    children,
    onClick,
    isRestricted,
    featureName = "",
    className = "",
    size = "",
    disabled = false,
    btnType = "primary",
    isLoading = false,
}) => {

    const history = useHistory()
    const { eventInfo } = useContext(AuthContext)
    const isLocked = isRestricted ? isRestricted : isFeatureRestricted(eventInfo.event_plan, featureName)

    const onLitePlanClick = () => {
        history.push(`${history.location.pathname}?plan=${featureName}`)
    }

    return (
        <KonfhubButton
            className={className}
            isLoading={isLoading}
            btnType={btnType}
            disabled={disabled}
            onClick={isLocked ? onLitePlanClick : onClick}
            size={size}
        >
            {children}   
            {isLocked && <img src={lockedIcon} alt="locked" className="ml-2 mb-1" />}         
        </KonfhubButton>
    );
};

export default LockableButton;
