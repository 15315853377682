import React from "react";
import TippyIcon from "../../TippyIcon";
import download from "../../../../assets/img/QuickActions/download.png";

const DownloadLeads = ({ onClick, className, disabled, style, content }) => (
	<TippyIcon content={content ? content : `Delete`} placement="bottom">
		<img
			src={download}
			style={style}
			onClick={onClick}
			disabled={disabled ? disabled : false}
			className={className}
		/>
	</TippyIcon>
);

export default DownloadLeads;
