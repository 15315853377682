import React, { useEffect, useState } from "react";
import closeIcon from "./../../../assets/img/common/x.svg"

const FilterBadge = ({ onClick, children }) => {

	return (
        <div className="filterBadge-container d-inline-block px-2 mb-2 mr-2 py-1">
            {children}
            <button className="ml-2 filterBadge-btn" onClick={onClick}><img src={closeIcon} title="Remove filter"/></button>
		<style jsx>{`
            .filterBadge-container {
                font-weight: 500;
                border: 1.4px solid #1E1E1E;
                border-radius: 50px;
                font-size: 14px;
            }
            .filterBadge-btn {
                border: none;
                background: transparent;

            }
        `}</style>
        </div>
	);
};

export default FilterBadge;
