import React, { useEffect, useState } from "react";
import Container from "../../components/Container";
import DashboardLayout from "../../containers/DashboardLayout";
import TippyIcon from "../../revampComponents/Atom/TippyIcon";
import listViewIcon from "../../assets/listView.svg";
import tableViewIcon from "../../assets/tableView.svg";

import Redirect from "../../assets/pages/Section/ManageEvents/Redirect.svg";
import Share from "../../assets/pages/Section/ManageEvents/Share.svg";
import Setting from "../../assets/pages/Section/ManageEvents/Setting.svg";
import ActionMenu from "../../assets/pages/Section/ManageEvents/ActionMenu.svg";
import Mail from "../../assets/pages/Section/ManageEvents/Mail.svg";
import Facebook from "../../assets/pages/Section/ManageEvents/Facebook.svg";
import Whatsapp from "../../assets/pages/Section/ManageEvents/Whatsapp.svg";
import Twitter from "../../assets/pages/Section/ManageEvents/Twitter.svg";
import LinkedIn from "../../assets/pages/Section/ManageEvents/LinkedIn.svg";
import Copy from "../../assets/pages/Section/ManageEvents/Copy.svg";
import Team from "../../assets/pages/Section/ManageEvents/Team.svg";
import Clone from "../../assets/pages/Section/ManageEvents/Clone.svg";
import CreateEvent from "../../assets/pages/Section/ManageEvents/CreateEvent.svg";
import {
	TwitterShareButton,
	FacebookShareButton,
	WhatsappShareButton,
	LinkedinShareButton,
	EmailShareButton,
} from "react-share";

import { getOrganiserEventsDetails } from "../../revampCRUD/app.crud";
import { baseUrl, websiteUrl } from "../../config/config";
import { errorToaster, successToaster } from "../../reusable/common";
import { cloneEventApiCall } from "../../revampCRUD/commonAPIs.crud";
import { CustomModal } from "../../revampComponents/Atom/Modals";
import { getEventDetails } from "../../revampCRUD/eventDetails.crud";
import EventCardComponent from "./EventCardComponent";
import BackendTableWizard from "../../revampComponents/Molecule/BackendTableWizard";
import { debounce } from "lodash";
import moment from "moment";
import { KonfhubButton } from "../../revampComponents/Atom/Buttons";
import Skeleton from "react-loading-skeleton";
import { useHistory } from "react-router-dom";
import { getISTDateNTime } from "../../Utils/helpers";

const ManageEvents = (props) => {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
	const [isApiLoading, setIsApiLoading] = useState(false);
    const [eventDetails, setEventDetails] = useState(null);
    const [cloneTicketsCoupons, setCloneTicketsCoupons] = useState(true);
	const [cloneBankDetails, setCloneBankDetails] = useState(true);
	const [openCloneModal, setOpenCloneModal] = useState(false);

    const [viewType, setViewType] = useState("tileView");
    const [activeTab, setActiveTab] = useState("active");
    const [activeEvents, setActiveEvents] = useState([]);
    const [_activeEvents, set_ActiveEvents] = useState([]);
    const [expiredEvents, setExpiredEvents] = useState([]);
    const [_expiredEvents, set_ExpiredEvents] = useState([]);
    const [search, setSearch] = useState("");
    const [visibleItemsActive, setVisibleItemsActive] = useState(12);
    const [visibleItemsExpired, setVisibleItemsExpired] = useState(12);

    const [openSharePopup, setOpenSharePopup] = useState("");
    const [openActionPopup, setOpenActionPopup] = useState("");
    const [isCopied, setIsCopied] = useState(false);

    const loadMoreItemsActive = () => {
        setVisibleItemsActive((prevVisibleItemsActive) => prevVisibleItemsActive + 12);
      };

    const loadMoreItemsExpired = () => {
        setVisibleItemsExpired((prevVisibleItemsExpired) => prevVisibleItemsExpired + 12);
    };
    
    const callGetListOfEventsApi = async() => {
        setLoading(true);
        try {
            const response = await getOrganiserEventsDetails();
            set_ActiveEvents(response?.data?.filter(each=>each?.event_status === 1 || each?.event_status === 2));
            setActiveEvents(response?.data?.filter(each=>each?.event_status === 1 || each?.event_status === 2));
            setExpiredEvents(response?.data?.filter(each=>each?.event_status === 3));
            set_ExpiredEvents(response?.data?.filter(each=>each?.event_status === 3));
        } catch (error) {
            errorToaster(error.message);
            console.log(error.message);
        } finally {
            setLoading(false);
        }
    }

    const callGetEventDetails = async(data) => {
		setIsApiLoading(true);
        try {
			const getResponse = await getEventDetails(data?.event_id);
			if (getResponse.status === 200) {
                setEventDetails(getResponse.data);
			} 
		} catch (err) {
			errorToaster("Something went wrong");
			console.log("error", err);
		} finally {
		    setIsApiLoading(false);
        }
    }

    const cloneEventHandler = async () => {
		setIsApiLoading(true);

		const date = new Date();
		date.setMonth(date.getMonth() + 1);
		const endDate = new Date();
		endDate.setMonth(endDate.getMonth() + 1);
		endDate.setDate(endDate.getDate() + 1);
		const payload = {
			event_name: `Copy of ${eventDetails?.name}`,
			event_url: `copy-${eventDetails?.event_url}`,
			start_date: `${date.toISOString().split("T")[0]}`,
			end_date: `${endDate.toISOString().split("T")[0]}`,
			clone_ticket_coupons: cloneTicketsCoupons,
		};

		if (eventDetails?.organiser_role_id === 1)
			payload.clone_stripe_or_bank_account = cloneBankDetails;

		try {
			const res = await cloneEventApiCall(eventDetails?.event_id, payload);
			if (res.status === 201) {
				if (cloneTicketsCoupons)
					successToaster(
						"Event details successfully cloned. Please edit event info, ticket, coupon, dates and other details as required.",
					);
				else
					successToaster(
						"Event details successfully cloned. Please add tickets & coupons and edit the event details as needed",
					);
				setIsApiLoading(false);
				setOpenCloneModal(false);
				callGetListOfEventsApi();
                window.open(`${websiteUrl}/e/${res?.data?.event_id}/eventInfo/eventdetails/basic`, "_blank");
			}
		} catch (err) {
			setOpenCloneModal(false);
			errorToaster("The event could not be cloned, please try again later.");
			setIsApiLoading(false);
		}
	};

    const setSearchFunction = debounce(() => {
        const searchQuery = search.trim().toLowerCase();
    
        const filterEvents = (events) => {
            return events.filter((each) => {
                if (searchQuery !== "") {
                    return each?.event_name?.toLowerCase().includes(searchQuery);
                }
                return true;
            });
        };
    
        const _tempList = activeTab === "active" 
            ? filterEvents(_activeEvents) 
            : filterEvents(_expiredEvents);
        
        if (activeTab === "active") {
            setActiveEvents(_tempList);
        } else {
            setExpiredEvents(_tempList);
        }
    }, 500);

    const formatDate = (data, isReturnEndDate = false) => {
        let ISTStartDateAndStartTime = getISTDateNTime(
            data.start_date + " " + data.start_time,
            data.time_zone
        );

        let ISTEndDateAndEndTime = getISTDateNTime(
            data.end_date + " " + data.end_time,
            data.time_zone
        );

        let start_date = ISTStartDateAndStartTime.split(" ")[0];

        let end_date = ISTEndDateAndEndTime.split(" ")[0];

        let dateString = ""
        if(isReturnEndDate){
            dateString = end_date;
        } else {
            dateString = start_date;
        }

        // Parse the date string
        const date = moment(dateString, 'YYYY-MM-DD');
      
        // Format the date as desired
        const day = date.date();
        const daySuffix = (day) => {
          if (day > 3 && day < 21) return 'th'; // for 11th to 20th
          switch (day % 10) {
            case 1: return 'st';
            case 2: return 'nd';
            case 3: return 'rd';
            default: return 'th';
          }
        };
        
        const formattedDate = `${day}${daySuffix(day)} ${date.format('MMM')}, ${date.format('YY')}`;
        
        return formattedDate;
    };

    const onRowClicked = selector => {
		window.open(`${websiteUrl}/e/${selector?.event_id}`, "_blank");
	};

    const columns = [
        {
            name: "Event Name",
            selector: "event_name",
            sortable: true,
			sortField: "event_name",
            cell: selector => (
                <div onClick={()=>onRowClicked(selector)}>
                    {selector.event_name}
                </div>
            )
        },
        {
            name: "Starts On",
            selector: "start_date",
            sortable: true,
			sortField: "start_date",
            cell: selector => (
                <div onClick={()=>onRowClicked(selector)}>
                    {`${formatDate(selector)}`}
                </div>
            )
        },
        {
            name: "Ends On",
            selector: "end_date",
            sortable: true,
			sortField: "end_date",
            cell: selector => (
                <div onClick={()=>onRowClicked(selector)}>
                    {`${formatDate(selector, true)}`}
                </div>
            )
        },
        {
            name: "Free/Paid",
            selector: "is_free",
            cell: selector => (
                <div onClick={()=>onRowClicked(selector)}>
                    {selector.is_free ? "Free" : "Paid"}
                </div>
            )
        },
        {
			name: <p className="sortIconSpace mb-0">Quick Actions</p>,
			sortable: false,
			center: true,
			cell: (selector) =>
				(
					<div
                        className="manage_events_quick_actions"
                        onClick={(e)=>e.stopPropagation()}
					>
                        <span className="">
                            <TippyIcon
                                content={
                                    "Go to Event Page"
                                }
                            >
                                <img role="button" src={Redirect} alt="Redirect_link" onClick={()=>{
                                    window.open(`${baseUrl}/${selector?.event_url}`, "_blank");
                                }} />
                            </TippyIcon>
                        </span>

                        <span>
                            <TippyIcon
                                content={
                                    "Share"
                                }
                            >
                                <img role="button" src={Share} alt="Share" onClick={(e)=>{
                                        e.stopPropagation();
                                        setOpenActionPopup("");
                                        setOpenSharePopup(()=>selector?.event_id);
                                    }} />
                            </TippyIcon>
                            <span>
                                {
                                    openSharePopup === selector?.event_id &&
                                    <div className="event_card_share_popup_table">
                                        <div className="text-left mb-2 share_via">Share Via</div>
                                        <div className="d-flex justify-content-between">
                                            <EmailShareButton
                                                subject={`Event - Konfhub Technologies`}
                                                body={`Hey! Register for our latest ${selector?.event_name}\n\n`}
                                                url={`${baseUrl}/${selector?.event_url}`}
                                            >
                                                <img role="button" src={Mail} alt="Mail" />
                                            </EmailShareButton>
                                            <WhatsappShareButton
                                                title={`Hey! Register for our latest ${selector?.event_name}\n\n`}
                                                url={`${baseUrl}/${selector?.event_url}`}
                                            >
                                                <img role="button" src={Whatsapp} alt="Whatsapp" />
                                            </WhatsappShareButton>
                                            <FacebookShareButton
                                                quote={`Hey! Register for our latest ${selector?.event_name}\n\n`}
                                                url={`${baseUrl}/${selector?.event_url}`}
                                            >
                                                <img role="button" src={Facebook} alt="Facebook" />
                                            </FacebookShareButton>
                                            <TwitterShareButton
                                                title={`Hey! Register for our latest ${selector?.event_name}\n\n`}
                                                url={`${baseUrl}/${selector?.event_url}`}
                                            >
                                                <img role="button" src={Twitter} alt="Twitter" />
                                            </TwitterShareButton>
                                            <LinkedinShareButton
                                                summary={`Hey! Register for our latest ${selector?.event_name}\n\n`}
                                                url={`${baseUrl}/${selector?.event_url}`}
                                            >
                                                <img role="button" src={LinkedIn} alt="LinkedIn" />
                                            </LinkedinShareButton>
                                            <TippyIcon
                                                content={
                                                    isCopied ? "" : "Copy"
                                                }
                                            >
                                                <img 
                                                    role="button" 
                                                    src={Copy} 
                                                    alt="Copy" 
                                                    onClick={()=>{
                                                        navigator.clipboard.writeText(`Hey! Register for our latest ${selector?.event_name}\n\n ${baseUrl}/${selector?.event_url}`);
                                                        setIsCopied(true);
                                                        setTimeout(() => setIsCopied(false), 1000);
                                                    }}
                                                />
                                            </TippyIcon>
                                            
                                        </div>
                                    </div>
                                }
                            </span>
                        </span>
                        
                        <TippyIcon
                            content={
                                "Go to Event Dashboard"
                            }
                        >
                            <img role="button" src={Setting} alt="Setting" onClick={()=>{
                                    history.push(`/e/${selector?.event_id}`)
                                }} />
                        </TippyIcon>


                        <span>
                            <TippyIcon
                                content={
                                    "Menu Items"
                                }
                            >
                                <img role="button" src={ActionMenu} alt="ActionMenu" onClick={(e)=>{
                                    e.stopPropagation();
                                    setOpenSharePopup("");
                                    setOpenActionPopup(()=>selector?.event_id);
                                }} />
                            </TippyIcon>
                            <span>
                                {
                                    openActionPopup === selector?.event_id &&
                                    <div className="event_card_action_popup_table d-flex flex-column">
                                        {/* <div role="button" className="d-flex justify-content-between align-items-center" onClick={()=>{
                                            if(selector?.event_id){
                                                callGetEventDetails(selector);
                                                setOpenCloneModal(true);
                                            }
                                        }}>
                                            <img src={Clone} alt="Mail" />
                                            <div className="action_texts">
                                                Clone
                                            </div>
                                        </div> */}
                                        <div role="button" className="d-flex justify-content-between align-items-center" onClick={()=>{
                                            history.push(`/e/${selector?.event_id}/team`);
                                        }}>
                                            <img src={Team} alt="Mail" />
                                            <div className="action_texts"> 
                                                Team
                                            </div>
                                        </div>
                                    </div>
                                }
                            </span>
                        </span>
					</div>
				),
			ignoreRowClick: true,
		},
    ];
    
    useEffect(()=> {
        callGetListOfEventsApi();
    }, [])

    useEffect(() => {
        if(!loading){
            setSearchFunction();
        }
    }, [search, activeTab]);

    return (
        <>
            <DashboardLayout props={props}>
                <Container className="manage_events_container">
                    <div className="manage_event_root_container" onClick={()=>{
                        setOpenActionPopup("");
                        setOpenSharePopup("");
                    }}>
                        <div className="row d-flex align-items-center" style={{height: 50}}>
                            <div className="col-12 col-sm-4 col-md-4 col-lg-6 d-flex justify-content-start align-items-center h-100">
                                <div className="searchIconContainer h-100">
                                    <input
                                        type="text"
                                        className="input"
                                        placeholder="Search..."
                                        onChange={(e) => {
                                            setSearch(e.target.value);
                                        }}
                                        value={search}
                                    />
                                    <span className="iconRight">
                                        <i className="fa fa-search" />
                                    </span>
                                </div>
                            </div>

                            <div className="col-12 col-sm-8 col-md-8 col-lg-6 d-flex justify-content-end align-items-center h-100">
                                <TippyIcon
                                    content={
                                        viewType === "tableView" ? "Tile view" : "Table view"
                                    }
                                >
                                    {viewType === "tableView" ? (
                                        <img
                                            src={listViewIcon}
                                            onClick={() => {
                                                setViewType("tileView");
                                            }}
                                            className="pointer-cursor mr-3 viewType"
                                        />
                                    ) : (
                                        <img
                                            src={tableViewIcon}
                                            onClick={() => {
                                                setViewType("tableView");
                                            }}
                                            className="pointer-cursor mr-3 viewType"
                                        />
                                    )}
                                </TippyIcon>
                                <div className="custom_tabs d-flex justify-content-between align-items-center">
                                    <div className={`custom_tab d-flex align-items-center justify-content-center ${activeTab === "active" ? "active" : ""}`} onClick={()=>setActiveTab("active")}>
                                        Active
                                    </div>
                                    <div className={`custom_tab d-flex align-items-center justify-content-center ${activeTab === "past" ? "active" : ""}`} onClick={()=>setActiveTab("past")}>
                                        Past
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-4 mt-sm-0" />
                        {
                            ((activeEvents.length === 0 && expiredEvents.length > 0 && activeTab === "active") || (expiredEvents.length === 0 && activeEvents.length > 0 && activeTab === "past") || (activeEvents.length === 0 && expiredEvents.length === 0)) && !loading &&
                            <div className="manage_events_no_events_box d-flex flex-column justify-content-center align-items-center">
                                <img src={CreateEvent} alt="Create Event" />
                                <div className="manage_events_no_events_text mt-3">
                                    {`You currently have no ${activeTab === "active" ? "active" : "past"} events!`}
                                </div>
                                <KonfhubButton className={"mt-3"} onClick={()=>{
                                    history.push(`/create/event`);
                                }}>
                                     Create Event
                                </KonfhubButton>
                            </div>
                        }
                        {
                            viewType === "tileView" ? 
                            <div className="row">
                                {
                                   !loading && activeTab === "active" && activeEvents.length > 0 ?
                                    activeEvents?.slice(0, visibleItemsActive)?.map((each)=>(
                                        <EventCardComponent
                                            key={each?.event_id} 
                                            data={each}
                                            callGetEventDetails={callGetEventDetails}
                                            setOpenCloneModal={setOpenCloneModal}
                                            history={history}
                                            formatDate={formatDate}
                                        />  
                                    ))
                                    :
                                    !loading && activeTab === "past" && expiredEvents.length > 0 ?
                                    expiredEvents?.slice(0, visibleItemsExpired)?.map((each)=>(
                                        <EventCardComponent
                                            key={each?.event_id} 
                                            data={each}
                                            callGetEventDetails={callGetEventDetails}
                                            setOpenCloneModal={setOpenCloneModal}
                                            history={history}
                                            formatDate={formatDate}
                                        />  
                                    ))
                                    :
                                    loading ? 
                                    ["", "", "", "", "", "", "", ""].map(each=>(
                                        <div className="col-12 col-sm-6 col-md-6 col-lg-3 mt-5" style={{padding: "0px 18px"}}>
                                            <Skeleton width={"100%"} height={"140px"} />
                                            <Skeleton style={{marginTop: "10px"}} width={"82%"} height={"12px"} />
                                            <Skeleton width={"62%"} height={"9px"} />
                                            <Skeleton width={"40%"} height={"8px"} />
                                            <Skeleton width={"32%"} height={"8px"} />
                                            <Skeleton width={"100%"} height={"1px"} />
                                            <Skeleton count={2} inline={true} width={"10%"} height={"10px"} />
                                        </div>
                                    ))
                                     : ""
                                }
                            </div>
                            :
                            !loading && activeTab === "active" && activeEvents.length > 0 ?
                            <div className="manage_events_backendtable_margintop">
                                <BackendTableWizard
                                    paginationServer={false}
                                    rowData={activeEvents}
                                    columns={columns}
                                    noDataComponent={
                                        <p className="mt-5 font-weight-normal mb-4">No Active Events...</p>
                                    }
									onRowClicked={onRowClicked}
                                    isFilter={false} // search
                                    onProgress={loading}
                                    headerMargin={"2"}
                                />
                            </div> :
                            !loading && activeTab === "past" && expiredEvents.length > 0 ?
                            <div className="manage_events_backendtable_margintop">
                                <BackendTableWizard
                                    paginationServer={false}
                                    rowData={expiredEvents}
                                    columns={columns}
                                    noDataComponent={
                                        <p className="mt-5 font-weight-normal mb-4">No Past Events...</p>
                                    }
									onRowClicked={onRowClicked}
                                    isFilter={false} // search
                                    onProgress={loading}
                                    headerMargin={"2"}
                                />
                            </div> :
                            ""
                        }
                        {
                            viewType === "tileView" && (activeTab === "active") && (visibleItemsActive < activeEvents.length) && (
                                <div className="text-center my-5">
                                    <KonfhubButton btnType={"secondary"} onClick={loadMoreItemsActive}>
                                        Load More
                                    </KonfhubButton>
                                </div>
                              )
                        }
                        {
                            viewType === "tileView" && (activeTab === "past") && (visibleItemsExpired < expiredEvents.length) && (
                                <div className="text-center my-5">
                                    <KonfhubButton btnType={"secondary"} onClick={loadMoreItemsExpired}>
                                        Load More
                                    </KonfhubButton>
                                </div>
                              )
                        }
                        <div style={{height: 40}} />
                        {
                            openCloneModal &&
                            <CustomModal
                                openModal={openCloneModal}
                                setOpenModal={setOpenCloneModal}
                                saveBtnContent="Clone Event"
                                cancelBtnContent="Cancel"
                                saveBtnDisabled={isApiLoading}
                                isLoading={isApiLoading}
                                content={
                                    <div className="text-center pt-3">
                                        <p className="formTitle fw-bold mb-4 text-center px-3">
                                            Clone Event
                                        </p>
                                        <p className="pb-1">
                                            Do you also want to clone the Tickets
                                            {props.user.attributes.sub === eventDetails?.created_by
                                                ? ", Coupons & Bank Details "
                                                : " & Coupons"}
                                            <br />
                                            from the original event?
                                        </p>
                                        {
                                            isApiLoading ? 
                                            <>
                                                <div style={{height: '43px'}} className="w-100 d-flex align-items-center justify-content-center">
                                                    <span className={"spinner-border spinner-border-sm"} role="status" aria-hidden="true" />
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className="text-center">
                                                    <div
                                                        className="d-inline-block text-start"
                                                        style={{ textAlign: "start" }}
                                                    >
                                                        <input
                                                            name="cloneTicketsCoupons"
                                                            type="checkbox"
                                                            className="is-checkradio"
                                                            checked={cloneTicketsCoupons}
                                                            onChange={() => setCloneTicketsCoupons(!cloneTicketsCoupons)}
                                                        />
                                                        <label htmlFor="cloneDate" className="">
                                                            Clone Tickets & Coupons
                                                        </label>
                                                        {!(eventDetails.organiser_role_id !== 1) && (
                                                            <>
                                                                <br />
                                                                <input
                                                                    name="cloneBankDetails"
                                                                    type="checkbox"
                                                                    className="is-checkradio"
                                                                    checked={cloneBankDetails}
                                                                    onChange={() => setCloneBankDetails(!cloneBankDetails)}
                                                                />
                                                                <label htmlFor="cloneDate" className="">
                                                                    Clone Bank Details
                                                                </label>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                                <p className="clone-info px-md-4 py-md-2 px-2 py-2 mt-3">
                                                    <strong>Note: </strong>Make sure to change the title, event start
                                                    & end dates, tickets, and other details.
                                                </p>
                                            </>
                                        }
                                        
                                    </div>
                                }
                                onCancel={() => setOpenCloneModal(false)}
                                onOkay={() => cloneEventHandler()}
                            />
                        }
                        
                    </div>
                </Container>
            </DashboardLayout>
            <style jsx>
                {
                    `
                        body {
                            background: #fff !important;
                        }
                        .manage_events_container {
                            margin: 0 !important;
                            border-radius: 0px;
                            height: 100vh !important;
                            margin-top: 25px !important;
                        }
                        .manage_event_root_container {
                            padding-left: 140px;
                            padding-right: 140px;
                            padding-top: 37px;
                            height: 100% !important;
                        }
                        .manage_events_tabs {
                            background: #f8f8f8;
                            border: 1px solid #E2E2E2;
                            border-radius: 5px;
                            max-width: 100%;
                            float: right;
                            padding: 7px 0px !important;
                        }
                        .manage_events_tabs .active {
                            background: #572148 !important;
                            border-radius: 5px !important;
                            color: #ffffff !important;
                            border: 0px !important;
                            padding: 8px 60px !important;
                        } 
                        .manage_events_tabs .active:after {
                            background: #572148 !important;
                            border-radius: 5px !important;
                            color: #ffffff !important;
                            border: 0px !important;
                            padding: 8px 60px !important;
                        } 
                        .manage_events_container .searchIconContainer {
                            width: 330px;
                        }
                        .manage_events_container .searchIconContainer .input {
                            height: 36px;
                        }
                        .manage_events_container .searchIconContainer .iconRight {
                            top: 0px;
                        }
                        .custom_tabs {
                            width: 208px;
                            padding: 7px 10px 7px 10px;
                            border-radius: 5px;
                            border: 1px solid #E2E2E2;
                            background: #F8F8F8;
                        }
                        .custom_tab {
                            height: 30px;
                            width: 89px;
                            background: #FFFFFF;
                            color: #572148;
                            font-size: 14px;
                            font-weight: 700;
                            line-height: 21px;
                            border-radius: 2px;
                            cursor: pointer;
                        }
                        .custom_tab.active {
                            background: #572148;
                            color: #fff;
                        }
                        .manage_event_root_container .viewType {
                            height: 29.16px !important;
                        }
                        .manage_events_no_events_box {
                            width: 100%;
                            height: 322px;
                            border-radius: 4px;
                            border: 1px dashed #C3CDDF;
                            margin-top: 3rem;
                        }
                        .manage_events_no_events_text {
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 16.94px;
                            color: #6D7780;
                        }
                        .clone-info {
                            border-radius: 5px;
                            border: 1px solid #E2E2E2;
                            background: #F8F8F8;
                            color: #6C757D;
                            font-size: 14px;
                            font-weight: 400;
                        }
                        .event_card_share_popup_table {
                            padding: 6px 7px;
                            bottom: 40px;
                            left: 35px;
                            position: absolute;
                            background: #fff;
                            width: 190px;
                            border-radius: 2px;
                            box-shadow: 0px 2.13px 13.29px 0px #0000001A, 0px -0.53px 13.29px 0px #0000001A;
                        }
                        .event_card_share_popup_table .share_via {
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 19.21px;
                            color: #6C757D;
                        }
                        .event_card_action_popup_table {
                            width: 78px;
                            // height: 62px;
                            height: 41px;
                            border-radius: 2px;
                            background: #fff;
                            padding: 10px;
                            position: absolute;
                            bottom: 40px;
                            right: 32px;
                            box-shadow: 0px 2.13px 13.29px 0px #0000001A, 0px -0.53px 13.29px 0px #0000001A;
                        }
                        .event_card_action_popup_table .action_texts {
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 24px;
                            color: #6D7780;
                        }
                        .manage_events_quick_actions {
                            width: 130px;
                            display: flex;
                            justify-content: space-between;
                            margin-left: 15px;
                        }
                        @media (max-width: 1026px){
                            .manage_event_root_container {
                                padding-left: 50px;
                                padding-right: 50px;
                            }
                            .event_card_share_popup_table {
                                position: absolute;
                                left: -50px;
                            } 
                        }
                        @media (min-width: 992px) and (max-width: 1026px){
                            .event_card_wrapper { 
                                width: 230px !important;
                            }
                            .event_card_background_image { 
                                width: 206px !important;
                            }
                        }
                        @media (max-width: 769px){
                            .manage_event_root_container {
                                padding-left: 50px;
                                padding-right: 50px;
                            }
                        }
                        @media (max-width: 426px){
                            .manage_event_root_container {
                                padding-left: 0px;
                                padding-right: 0px;
                            }
                            .manage_events_no_events_box {
                                margin-top: 5rem;
                            }
                            .manage_events_backendtable_margintop {
                                margin-top: 45px;
                            }
                            .custom_tab {
                                padding: 8px 48px;
                            }
                            .manage_events_quick_actions {
                                width: 250px;
                            }
                            .event_card_share_popup_table {
                                position: absolute;
                                left: -50px;
                            } 
                        }
                        
                    `
                }
            </style>
        </>
    )
}

export default ManageEvents