import React from "react";

const Overlay = ({ zIndex, onClick, className }) => {
    
    return (
        <div
            className={`checkout-overlay ${className ? className : ""}`}
            onClick={onClick ? onClick : null}
        >
            <style>
                {`
                    .checkout-overlay {
                      color: white;
                      position: fixed; /* Sit on top of the page content */
                      width: 100%; /* Full width (cover the whole page) */
                      height: 100%; /* Full height (cover the whole page) */
                      top: 0;
                      left: 0;
                      right: 0;
                      bottom: 0;
                      background-color: rgb(
                        0 0 0 / 53%
                      ); /* Black background with opacity */
                      z-index: ${
                          zIndex ? zIndex : "100"
                      }; /* Specify a stack order in case you're using a different order for other elements */
                    }
                    @media (min-width: 991px) {
                        .checkout-overlay {
                          display: none;
                        }
                      }
                      
            `}
            </style>
        </div>
    );
};

export default Overlay;
