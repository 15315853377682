import axios from "./axios";

const API_URL = axios.defaults.baseURL;
const BASE_URL = "/event/";

export const addTapAccount = (eventId, payload) => {
	return axios.post(`${API_URL + BASE_URL + eventId}/tap/connect`, payload);
};

export const getTapAccount = (eventId) => {
	return axios.get(`${API_URL + BASE_URL + eventId}/tap/account`);
};

export const disconnectTapAccount = (eventId) => {
	return axios.delete(`${API_URL + BASE_URL + eventId}/tap/disconnect`);
};

export const validateSecretKey = (eventId, tap_secret_key) => {
	return axios.get(
		`${
			API_URL + BASE_URL + eventId
		}/tap/validate?tap_secret_key=${tap_secret_key}`,
	);
};
