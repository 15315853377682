import React from 'react'
import { KonfhubButton } from './Buttons'
import sparkles from "../../assets/img/Pricing-plan/sparkles.svg"
import lockPurple from "../../assets/img/Pricing-plan/lock-icon-purple.svg"
import { useHistory } from "react-router-dom"

export default function Banner({ children }) {

    const history = useHistory();

    return (
        <div className='px-sm-3 px-lg-5 px-0 pt-4 pb-4 d-flex justify-content-center upgrade-banner-container'>  
            <div className="upgrade-banner d-flex flex-wrap px-3 py-3 justify-content-center my-auto align-items-center">
                <div className='upgrade-banner-lock d-flex justify-content-center align-items-center mr-2'>
                    <img src={lockPurple} alt='lock icon' style={{width: "14px", height: "14px"}} />
                </div>
                <h4 className="upgrade-banner-div mr-3 text-center">
                    {children}
                </h4>
                <KonfhubButton 
                    onClick={() => {
                        window.location = "mailto:reachus@konfhub.com"
                        // history.push(`${history.location.pathname}?plan=Lite`)
                    }}
                >   
                    Contact to Upgrade
                    <img src={sparkles} className='ml-1' alt='sparkles'/>
                </KonfhubButton>
            </div>

            <style>{`
                .upgrade-banner {
                    min-height: 25px;
                    background: #FFF;
                    border: 1px solid #572148;
                    box-shadow: 0px 0px 15.3px 0px #57214824;
                    // width: fit-content;
                    width: 100%;
                    // font-size: 16px;
                    color: #572148;
                }	
                .upgrade-banner-container {
                    position: sticky;
                    top: 0px;
                    background: transparent;
                    z-index: 98;
                }
                .upgrade-banner-div{
                    font-size: 16px;
                    margin-top: 10px;        
                }
                .upgrade-banner-lock {
                    background: #F1DDEC;
                    border-radius: 100%;
                    width: 30px;
                    height: 30px;
                }
                @media (max-width: 600px) {
                    .upgrade-banner-div{
                        font-size: 12.5px !important;
                    }
                }
			
            `}</style>
        </div>
    )
}

