import {api} from "./axios.config";
import axios from "./axios";
import React, {useContext} from "react"
// import AuthContext from "./../contexts/AuthContext"

const API_URL = api.apiUrl;
const COMMUNITY_URL = "/community";

export const createCommunity = payload => {
	return axios.post(API_URL + COMMUNITY_URL, payload);
};

export const getCommunityDetails = () => {
	return axios.get(API_URL + COMMUNITY_URL + '/private/details');
};

export const updateCommunityDetails = (communityUrl = "", payload) => {
    return axios.put(API_URL + COMMUNITY_URL + `/${communityUrl}`, payload);
}

export const getCommunityEvents = (eventStatus = "ongoing", searchTerm = "") => {
    let GET_COMMUNITY_EVENTS = `/private/events?event_status=${eventStatus}${searchTerm ? `&search_term=${searchTerm}` : ''}`;
    return axios.get(API_URL + COMMUNITY_URL + GET_COMMUNITY_EVENTS);
}

export const updateEventStatus = (communityUrl = "", communityDetails = {}, eventId = "", isAddedToCommunity = true) => {
    return axios.put(API_URL + COMMUNITY_URL + `/${communityUrl}`, {
		...communityDetails,
        event_id: eventId,
        is_added_to_community: isAddedToCommunity
    });
}

export const validateURL = (url = "") => {
	return axios.get(API_URL + COMMUNITY_URL + `/url/validate?community_url=${url}`);
};
