import { useState, useCallback, useRef, useEffect } from 'react'

export function useForceUpdate() {
    const [, setTick] = useState(0);
    const update = useCallback(() => {
        setTick(tick => tick + 1);
    }, [])
    return update;
}

const getIsMobile = (width) => window.innerWidth <= width;

export default function useIsMobile(width = 768) {
    const [isMobile, setIsMobile] = useState(getIsMobile(width));

    useEffect(() => {
        const onResize = () => {
            setIsMobile(getIsMobile());
        }

        window.addEventListener("resize", onResize);

        return () => {
            window.removeEventListener("resize", onResize);
        }
    }, []);

    return isMobile;
}
