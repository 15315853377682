import React from "react";

import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import infoIcon from "./Icons/info3.svg";
import ReactHtmlParser from "react-html-parser";

const ToolTip = ({ data, icon, image, text, disabled }) => {
	return (
		<span className="customTip mr-1">
			<Tippy
				animation={true}
				theme="custom"
				content={ReactHtmlParser(data)}
				placement="bottom"
				disabled={disabled ? disabled : false}
				arrow={true}
				className="custom-tippy">
				{/* Tooltip icon */}
				<i className="ml-1">
					{text ? (
						text
					) : image ? (
						<img src={image} />
					) : icon ? (
						<i className={icon}></i>
					) : (
						<>
							<img className="infocus" src={infoIcon}></img>
						</>
					)}
				</i>
			</Tippy>
			<style jsx global>{`
				.infocus {
					max-width: 15px;
					max-height: 18px;
				}

				.tippy-tooltip {
					font-size: 30px;
					padding: 0.3rem 0.6rem;
				}
			`}</style>
		</span>
	);
};

export default ToolTip;
