import React, { useContext, useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment-timezone";

import { baseUrl } from "../../config/config";
import AuthContext from "../../contexts/AuthContext";
import {
	LitePlanFeatures,
	errorToaster,
	silverPlanFeatures,
	successToaster
} from "../../reusable/common";

import KonfhubButton from "../../revampComponents/Atom/Buttons/KonfhubButton";
import TippyIcon from "../../revampComponents/Atom/TippyIcon";
import { cloneEventApiCall } from "../../revampCRUD/commonAPIs.crud";
import {
	LiveStatusIcon,
	PastStatusIcon,
	LinkPreviewIcon,
	CloneEventIcon,
	UpcomingStatusIcon
} from "./../../assets/img/Header/index";
import ProfileDropDown from "./ProfileDropDown";
import SwitchEventDropDown from "./SwitchEventDropDown";
import Banner from "./Banner";
import SwitchApplications from "./SwitchApplications";
import MyCommunity from "./MyCommunity/MyCommunity";
import KonfhubWhiteLogo from "../../assets/pages/Section/ManageEvents/KonfhubWhiteLogo.svg";
import PricingPlanModal from "./PricingPlanModal";
import EventLimits from "./EventLimits";
import { getStripeAccount } from "../../revampCRUD/stripe.crud";
import { getTapAccount } from "../../revampCRUD/tap.crud";
import CloneEventModal from "./CloneEvent/CloneEventModal";
import { useFormik } from "formik";
import ValidateCloneEvent from "./CloneEvent/Validation";
import { getUTCDateNTime } from "../../Utils/helpers";

const Header = props => {
	const { eventInfo } = props;
	const communityRef = useRef();
	const [openCloneModal, setOpenCloneModal] = useState(false);
	const [isApiLoading, setIsApiLoading] = useState(false);
	const [communityButtonLoading, setCommunityButtonLoading] = useState(false);
	const [cloneTicketsCoupons, setCloneTicketsCoupons] = useState(true);
	const [cloneBankDetails, setCloneBankDetails] = useState(true);
	const [hasStripeOrTap, setHasStripeOrTap] = useState(false); //only for international events
	const [isLoadStripeOrTap, setIsLoadStripeOrTap] = useState(false); //only for international events
	const [openPlanModal, setOpenPlanModal] = useState(false);

	const defaultInitialData = {
		event_name: `copy-${eventInfo?.name}`,
		event_url: `copy-${eventInfo?.event_url}`,
		start_date: null,
		start_time: null,
		end_date: null,
		end_time: null,
		time_zone: eventInfo?.time_zone,
		event_plan: eventInfo?.event_plan,
		clone_event_details: true,
		clone_event_posters: true,
		clone_organizer_details: true,
		clone_tickets: true,
		clone_coupons: true,
		clone_event_email_contents: true,
		clone_event_forms: true,
		clone_team_details: true,
		clone_stripe_or_bank_account: true
	};

	const [initialData, setInitialData] = useState(defaultInitialData);

	const setSidebarIsOpen = useDispatch();
	const history = useHistory();
	const { eventId } = useParams();
	const { organiserDetails, refreshOrganiserDetails, user } = useContext(AuthContext);
	const eventDetails = props?.prefeventinfo;
	const reloadApi = props?.getAllDetails;
	const isInternational = props?.eventInfo?.currency_name !== "INR";
	const isStripeNotConnected = props?.eventInfo?.stripe_user_id === null;
	const isNotCreator = props?.eventInfo?.organiser_role_id !== 1;
	const isPaidEvent = props?.eventInfo?.is_free ? false : true;
	const isBankDetailsAdded = props?.eventInfo?.has_bank_details ? true : false;
	const isKYCVerified = props?.eventInfo?.organiser_kyc_verified ? true : false;
	const isSettlementsDisabled = props?.eventInfo?.disable_settlements ? true : false;

	const validate = async values => {
		let errors = {};
		errors = await ValidateCloneEvent(values);
		return errors;
	};

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: initialData,
		validateOnMount: true,
		validate: validate,
		onSubmit: async values => {
			setIsApiLoading(true);

			const payload = {
				clone_configurations: {
					clone_stripe_or_bank_account: values.clone_stripe_or_bank_account,
					clone_tickets: values.clone_tickets,
					clone_coupons: values.clone_coupons,
					clone_event_email_contents: values.clone_event_email_contents,
					clone_team_details: values.clone_team_details,
					clone_event_posters: values.clone_event_posters,
					clone_organizer_details: values.clone_organizer_details,
					clone_event_forms: values.clone_event_forms,
					clone_event_details: values.clone_event_details
				},
				event_name: values.event_name,
				event_url: values.event_url,
				start_date: values.start_date,
				end_date: values.end_date,
				start_time: values.start_time,
				end_time: values.end_time,
				time_zone: values.time_zone
			};
			// Convert to UTC
			payload.start_date = await getUTCDateNTime(payload.start_date, payload.time_zone);
			payload.end_date = await getUTCDateNTime(payload.end_date, payload.time_zone);

			payload.start_time = payload.start_date.slice(11, payload.start_date.length);
			payload.end_time = payload.end_date.slice(11, payload.end_date.length);
			payload.start_time = payload.start_time.replaceAll("-", ":");
			payload.end_time = payload.end_time.replaceAll("-", ":");
			payload.start_date = payload.start_date.slice(0, payload.start_date.length - 9);
			payload.end_date = payload.end_date.slice(0, payload.end_date.length - 9);

			try {
				const res = await cloneEventApiCall(eventDetails?.event_id, payload);
				if (res.status == 201) {
					if (cloneTicketsCoupons) successToaster(`Event details successfully cloned.`);
					else successToaster(`Event details successfully cloned. `);
					setIsApiLoading(false);
					setOpenCloneModal(false);
					refreshOrganiserDetails();
					history.push("/e/" + res.data.event_id + "/eventInfo/eventdetails/basic");
				}
			} catch (err) {
				const errorMessage =
					err?.response?.data?.error ||
					"The event could not be cloned, please try again later.";
				errorToaster(errorMessage);
				setIsApiLoading(false);
			}
		}
	});

	const handleOpenCloneModal = () => {
		setInitialData(defaultInitialData); // Reset initial data
		formik.resetForm({ values: defaultInitialData }); // Reset formik values
		setOpenCloneModal(true);
	};

	const checkIfStripeOrTapConnected = async () => {
		setIsLoadStripeOrTap(true);
		try {
			await getStripeAccount(eventId);
			setHasStripeOrTap(true);
			setIsLoadStripeOrTap(false);
		} catch (err) {
			try {
				await getTapAccount(eventId);
				setHasStripeOrTap(true);
				setIsLoadStripeOrTap(false);
			} catch (err) {
				console.log(err);
				setIsLoadStripeOrTap(false);
			}
		}
	};

	useEffect(() => {
		if (isInternational && eventId) checkIfStripeOrTapConnected();
	}, [isInternational, eventId]);

	const callCommunityFunction = () => {
		communityRef?.current?.goToMyCommunity();
	};

	const handleCommunityStateChange = newChildState => {
		setCommunityButtonLoading(newChildState);
	};

	const planBtnContent = () => {
		if (isInternational && !eventInfo?.subscription_payment_id) return "All Plans";
		return `Plan: ${eventInfo?.eventPlanName}`;
	};

	return (
		<>
			{isInternational &&
				!eventInfo?.allowed_payment_methods?.length &&
				isPaidEvent &&
				eventId && (
					<Banner
						isNotCreator={isNotCreator}
						eventId={eventInfo?.event_id}
						stripeConnect={true}
					/>
				)}
			{eventId &&
				!isInternational &&
				isPaidEvent &&
				(!isBankDetailsAdded || !isKYCVerified || isSettlementsDisabled) && (
					<Banner
						isNotCreator={isNotCreator}
						eventId={eventInfo?.event_id}
						isBankDetailsAdded={isBankDetailsAdded}
						isKYCVerified={isKYCVerified}
						isSettlementsDisabled={isSettlementsDisabled}
					/>
				)}
			<div className="d-flex align-items-center header-bg w-100 pl-5"></div>
			<div className="row d-flex flex-column justify-content-center header w-100 ml-1">
				<div
					className={`p-2 d-flex justify-content-between ${!eventId ? "align-items-center" : ""} ${eventId ? "d-sm-block" : ""}`}
				>
					{!eventId && <img src={KonfhubWhiteLogo} alt="Konfhub Logo" />}
					{eventId && (
						<i
							className="fa fa-bars hamburger-menu mb-3 white-color"
							aria-hidden="true"
							onClick={() => setSidebarIsOpen({ type: "toggle" })}
						></i>
					)}

					<div className="header__top text-right d-flex justify-content-lg-end justify-content-sm-center justify-content-center align-items-start">
						<MyCommunity
							ref={communityRef}
							onChildStateChange={handleCommunityStateChange}
							history={history}
						/>
						{eventId && (
							<>
								<div>
									<KonfhubButton
										style={{ background: "#fff", border: "1px solid #fff" }}
										btnType="secondary"
										className="position-relative"
										isLoading={isLoadStripeOrTap}
										onClick={() => {
											if (eventInfo?.event_plan !== 1) {
												history.push(
													`${history.location.pathname}?plan=Lite`
												);
											}
										}}
										disabled={eventInfo?.event_plan === 1}
									>
										{planBtnContent()}
									</KonfhubButton>
								</div>
								<PricingPlanModal
									history={history}
									eventPlan={eventInfo?.event_plan}
									reloadApi={reloadApi}
									hasStripeOrTap={isInternational && hasStripeOrTap}
									isInternational={isInternational}
									eventId={eventDetails?.event_id}
									subscriptionPaymentId={eventInfo?.subscription_payment_id}
								/>
							</>
						)}

						<div className="create_new_event">
							<TippyIcon content="Create New Event" placement="bottom">
								<span>
									<KonfhubButton
										className="px-3 ml-3"
										btnType="secondaryWhite"
										onClick={() => history.push("/create/event")}
									>
										<span>
											<i className="fas fa-plus m-0 p-0"></i>
											&nbsp;&nbsp;&nbsp;Create Event
										</span>
									</KonfhubButton>
								</span>
							</TippyIcon>
						</div>
						{eventId && (
							<SwitchEventDropDown
								reloadApi={reloadApi}
								history={history}
								organiserDetails={organiserDetails}
								token={user.signInUserSession.accessToken.jwtToken}
							/>
						)}

						<SwitchApplications
							callCommunityFunction={callCommunityFunction}
							handleCommunityStateChange={handleCommunityStateChange}
							communityButtonLoading={communityButtonLoading}
							history={history}
							eventId={eventId}
							communityRef={communityRef}
						/>
						<ProfileDropDown
							profileImage={user.attributes["custom:profilePicture"]}
							email={user.attributes.email}
							name={user.attributes.name}
							attributes={user.attributes}
							history={history}
							eventId={eventId}
							callCommunityFunction={callCommunityFunction}
						/>
					</div>
				</div>
				{!eventId && (
					<div className="text-center">
						<div className="manage_event_heading">Manage Events</div>
					</div>
				)}
				<div className="d-flex flex-column flex-lg-row align-items-lg-end align-items-center">
					<div>
						{eventId && (
							<div className="d-flex justify-content-lg-start justify-content-sm-center justify-content-left ml-2 ml-lg-0 mt-lg-0 mt-md-0 mt-sm-2 mt-2">
								<div className="header__title d-flex flex-wrap">
									<h2 className="h1-font white-color">
										{eventDetails?.name}
										{/* <span className="ml-3 title-link-icon">
										<LinkPreviewIcon />
									</span>
									<span className="ml-3 title-view-icon">
										<CloneEventIcon />
									</span> */}
										<span className="live_mobile ml-2">
											{eventDetails?.event_status === 1 ? (
												<LiveStatusIcon className="event_status_icon" />
											) : eventDetails?.event_status === 2 ? (
												<UpcomingStatusIcon className="event_status_icon" />
											) : (
												<PastStatusIcon className="event_status_icon" />
											)}
										</span>
										<span className="ml-2 md-sm-3 title-icon">
											<TippyIcon
												content="Preview Event Page"
												placement="bottom"
											>
												<a
													href={`${baseUrl}/${eventDetails?.event_url}`}
													target="_blank"
													rel="noreferrer"
													// title="Preview Event Page"
												>
													<LinkPreviewIcon className="preview_link_icon" />
												</a>
											</TippyIcon>
											<TippyIcon content="Clone Event" placement="bottom">
												<button
													className="ml-2 clone-btn"
													// title="Clone Event"
													onClick={handleOpenCloneModal}
												>
													<CloneEventIcon className="clone_event_icon" />
												</button>
											</TippyIcon>
										</span>
									</h2>
								</div>
								{/* <div className="header__short-btns d-flex flex-wrap">
									<button>+</button>
									<button>-</button>
									<button>=</button>
								</div> */}
							</div>
						)}

						{eventId && (
							<div className="white-color d-flex justify-content-lg-between justify-content-center mt-lg-0 mt-md-0 mt-sm-0 mt-0 mb-lg-0 mb-md-3 mb-sm-2 mb-2">
								<div className="event_status_isVirtual_type_timezone">
									<span className="live">
										{eventDetails?.event_status == 1 ? (
											<LiveStatusIcon />
										) : eventDetails?.event_status == 2 ? (
											<UpcomingStatusIcon />
										) : (
											<PastStatusIcon />
										)}
									</span>{" "}
									<TippyIcon content="Event Status" placement="bottom">
										<span className="pointer-cursor">
											{eventDetails?.event_status == 1
												? "Live"
												: eventDetails?.event_status == 2
													? "Upcoming"
													: "Past"}
										</span>
									</TippyIcon>{" "}
									|{" "}
									<TippyIcon content="Online/Offline Event" placement="bottom">
										<span className="pointer-cursor">
											{eventDetails?.is_virtual ? "Online" : "In-Person"}
										</span>
									</TippyIcon>{" "}
									|{" "}
									<TippyIcon content="Event Type" placement="bottom">
										<span className="pointer-cursor">
											{eventDetails?.is_free ? "Free" : "Paid"}
										</span>
									</TippyIcon>{" "}
									|
									<TippyIcon content="Event Timezone" placement="bottom">
										<span className="pointer-cursor">
											&nbsp;
											{moment
												.tz(props?.eventInfo?.time_zone)
												.format("z")
												.includes("+")
												? `GMT${moment.tz(props?.eventInfo?.time_zone).format("z")}`
												: `${moment.tz(props?.eventInfo?.time_zone).format("z")}`}
										</span>
									</TippyIcon>
								</div>
								{/*<div className="quick-actions btn-group btn-group-sm row d-xl-flex d-lg-flex d-md-flex d-sm-none d-none">
									<TippyIcon content="Attendees" placement="bottom">
										<button className="btn px-3">
											<AttendeesIcon
												onClick={() =>
													history.push(`/e/${eventDetails?.event_id}/attendees`)
												}
											/>
										</button>
									</TippyIcon>
									<TippyIcon content="Create New Coupon" placement="bottom">
										<button className="btn px-3 btn-border">
											<NewCouponIcon
												onClick={() =>
													history.push(
														`/e/${eventDetails?.event_id}/eventInfo/coupons/add-coupon`,
													)
												}
											/>
										</button>
									</TippyIcon>
									<TippyIcon content="Create New Ticket" placement="bottom">
										<button className="btn px-3">
											<NewTicketIcon
												onClick={() =>
													history.push(
														`/e/${eventDetails?.event_id}/eventInfo/ticketsnforms/tickets/add-ticket`,
													)
												}
											/>
										</button>
									</TippyIcon>
								</div>*/}
							</div>
						)}

						{/* Show this div only for mobile */}
						{/* <div className="col-sm-4 col-7 mx-auto quick-actions btn-group btn-group-sm d-lg-none d-md-none d-sm-block mt-lg-0 mt-md-0 mt-sm-2 mt-2 mb-lg-0 mb-md-0 mb-sm-2 mb-2 quick-actions-mobile">
							<TippyIcon content="View Attendees" placement="bottom">
								<button className="btn px-3">
									<AttendeesIcon
										onClick={() =>
											history.push(`/e/${eventDetails?.event_id}/attendees`)
										}
									/>
								</button>
							</TippyIcon>
							<TippyIcon content="Create New Coupon" placement="bottom">
								<button className="btn px-3 btn-border">
									<NewCouponIcon
										onClick={() =>
											history.push(
												`/e/${localStorage.getItem(
													"eventId"
												)}/eventInfo/coupons/add-coupon`
											)
										}
									/>
								</button>
							</TippyIcon>
							<TippyIcon content="Create New Ticket" placement="bottom">
								<button className="btn px-3">
									<NewTicketIcon
										onClick={() =>
											history.push(
												`/e/${localStorage.getItem(
													"eventId"
												)}/eventInfo/ticketsnforms/tickets/add-ticket`
											)
										}
									/>
								</button>
							</TippyIcon>
						</div> */}
					</div>
					{props.eventInfo?.max_regs_count ? (
						<div className="ml-lg-auto ml-0">
							<EventLimits
								eventDetails={props?.eventInfo}
								participantCount={eventDetails.attendees_count}
								isInternational={isInternational}
							/>
						</div>
					) : null}
				</div>
			</div>

			{openCloneModal && (
				<CloneEventModal
					openCloneModal={openCloneModal}
					setOpenCloneModal={setOpenCloneModal}
					isApiLoading={isApiLoading}
					isNotCreator={isNotCreator}
					user={user}
					eventInfo={eventInfo}
					formik={formik}
				/>
			)}
			<style>{`
                .live svg {
                    margin-bottom: 2px;
                }
                .title-view-icon svg {
                    width: 30px;
                    height: 20px;
                }
				.clone-btn {
					border: none;
					background: transparent;
				}
				.quick-actions{
					background:white;
					margin-right:30px;
					margin-top:-15px;
					border-radius:3px;
				}
				.quick-actions .btn{
					box-shadow:none;
				}
				.btn-border{
					border-left: 1px solid #6D7780 !important;
					border-right: 1px solid #6D7780 !important;
					border-radius: 0px !important;
				}
				.clone-info {
					border-radius: 5px;
					border: 1px solid #E2E2E2;
					background: #F8F8F8;
					color: #6C757D;
					font-size: 14px;
					font-weight: 400;
				}
				.manage_event_heading {
					font-size: 30px;
					font-weight: 600;
					line-height: 48.03px;
					color: #fff;
				}
				.konfhub_white_logo_header {
					margin-bottom: -62px !important;
				}
				
				@media (max-width: 426px){
					.konfhub_white_logo_header {
						margin-bottom: -2px !important;
					}
				}
            `}</style>
		</>
	);
};

export default Header;
