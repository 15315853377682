import axios from "../axios";

const API_URL = axios.defaults.baseURL;
const BASE_URL = "/event/";
const UPDATE_URL = "/teams/settings";
const GET_URL = "/teams";
const DOWNLOAD_URL = "/teams/download";
const CANCEL_URL = "/teams/cancel";

export const getTeamRegistrations = (eventID, offset, limit, search_value = false) => {
    let queryParam = search_value ? `search_term=${search_value}&` : ''
    return axios.get(API_URL + BASE_URL + eventID + GET_URL + `?offset=${offset}&limit=${limit}&${queryParam}`);
};

export const downloadTeamRegistrations = (eventID, payload = {}) => {
    return axios.post(
        API_URL + BASE_URL + eventID + DOWNLOAD_URL, payload
    );
};

export const updateTeamSettings = (eventId, payload) => {
    return axios.put(API_URL + BASE_URL + eventId + UPDATE_URL, payload);
}

export const cancelTeamRegistration = (eventId, payload) => {
    return axios.delete(API_URL + BASE_URL + eventId + CANCEL_URL, {
        data: payload
    });
}
