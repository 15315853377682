import React, { useEffect, useState, useRef } from "react";
import LoadingSpinner from "../../revampComponents/Atom/Loading";
import TippyIcon from "../../revampComponents/Atom/TippyIcon";
import { getProfileDetails } from "../../revampCRUD/app.crud";
import { Link } from "react-router-dom";
import { clickOutside } from "../../reusable/common";
import { baseUrl, mediaUrl, websiteUrl } from "../../config/config";
import Profile from "../../assets/img/Profile Dropdown/User.svg"
import ManageProfile from "../../assets/img/Profile Dropdown/ManageProfile.svg"
import MyCommunity from "../../assets/img/Profile Dropdown/MyCommunity.svg"
import CreateNewEvent from "../../assets/img/Profile Dropdown/CreateNewEvent.svg"
import Logout from "../../assets/img/Profile Dropdown/Enter.svg"
import Quota from "../../assets/img/Profile Dropdown/Circled Menu.svg"
import DashboardProfileIcon from "../../assets/img/Profile Dropdown/DashboardProfileIcon.svg"

const ProfileDropDown = ({ name, email, eventId, profileImage, attributes, history, callCommunityFunction }) => {
    const [open, setOpen] = useState(false)
    const [isError, setIsError] = useState(false);
    const wrapperRef = useRef("menu");
    clickOutside(wrapperRef, () => {
        setOpen(false);
    });

    function handleError() {
        setIsError(true);
    }

    return (
        <div className="profile-container position-relative ml-3">
            {open ? <span className="arrow-up" /> : ""}
            <div ref={wrapperRef} className="profile-img-container d-inline-block">
                <div className="d-flex align-items-center h-100 w-100 position-relative">
                    {name || email || profileImage ? (
                        <>
                            {
                                isError ?
                                    <img src={`${mediaUrl}/default-profile.png`} />
                                    :
                                    <img src={profileImage} onError={handleError} />
                            }
                            {!open ? <TippyIcon content={email}>
                                <button onClick={() => setOpen(!open)} className="profile-overlay h-100 w-100 position-absolute"></button>
                            </TippyIcon> : <button onClick={() => setOpen(!open)} className="profile-overlay h-100 w-100 position-absolute"></button>}
                        </>
                    ) : <LoadingSpinner />}
                </div>
                {open && (
                    <div className="profile-dropDown-container px-3 pt-4 pb-3 position-absolute">
                        <div className="row px-1 py-2 text-left switchEvent-pad">
                            <div className=" pl-3 pr-2">
                                {
                                    isError ?
                                        <img className="profile-dropDown-img" src={`${mediaUrl}/default-profile.png`} />
                                        :
                                        <img className="profile-dropDown-img" src={profileImage} onError={handleError} />
                                }


                            </div>
                            <div className="details-container ml-1 mt-auto mb-auto">
                                <p className="h2-font profile-email mb-1">{name}</p>
                                <p className="profile-sub-text mb-0">{email}</p>
                            </div>
                        </div>
                        <div className="profile-divider d-flex mb-3 mt-2 mx-auto"></div>
                        <div className="profile-dropdown-options text-left">
                            <a href={`${baseUrl}/profile`}><span className="d-flex align-items-center"> <img src={ManageProfile} className="mr-2" alt="" /> Manage Profile</span></a>
                        </div>
                        <div className="profile-dropdown-options create_new_event mt-1 text-left">
                            <span onClick={() => history.push("/create/event")} className="d-flex align-items-center"><img src={CreateNewEvent} className="mr-2" alt="" /> Create New Event</span>
                        </div>
                        {
                            eventId &&
                            <div className="profile-dropdown-options text-left">
                                <span onClick={() => history.push("/")} className="d-flex align-items-center dashboard-icon"><img src={DashboardProfileIcon} className="mr-1" alt="DashboardProfileIcon" />{" Dashboard"}</span>
                            </div>
                        }
                        {/* <div className="profile-dropdown-options my_community mt-1 text-left">
                            <span 
                                onClick={()=> {
                                    setOpen(false);
                                    callCommunityFunction();
                                }} 
                                className="d-flex align-items-center"
                            > 
                                <img src={MyCommunity} className="mr-2" alt="" /> 
                                My Community
                            </span>
                        </div> */}
                        {
                            attributes['custom:khSubscriptionPlan'] &&
                            <div className="profile-dropdown-options mt-1 text-left">
                                <a href={`${baseUrl}/pitchground`} target="_blank" rel="noreferrer"><span className="d-flex align-items-center"> <img src={Quota} className="mr-2" alt="" width="16" height="16" /> Event Quota</span></a>
                            </div>
                        }
                        {/* <div className="profile-divider d-none d-md-flex d-lg-flex mt-2 mb-1 mt-0 mx-auto"></div> */}
                        <div className="profile-dropdown-options mt-1 text-left">
                            <Link className="d-flex align-items-center" style={{ color: "#572148" }} to="/logout"> <img src={Logout} className="mr-2" alt="" /> Logout</Link>
                        </div>
                    </div>
                )}
            </div>
            <style>{`
            .profile-container {
                            height: 35px;
                        }
                        .profile-img-container {
                            height: 35px;
                            width: 35px;
                            border-radius: 100%;
                            overflow: hidden;
                            // border: 1px solid #fff;
                            background: #fff;
                            box-shadow:1px 1px 10px  #0002;
                        }

                        .profile-overlay {
                            display:none;
                            top: 0;
                            background: transparent;
                            border: 1px solid transparent;
                            border-radius: 100%;
                        }

                        .profile-img-container:hover .profile-overlay {
                            display:block;
                            cursor: pointer;
                        }

                        .arrow-up {
                            width: 0;
                            height: 0;
                            margin-top: -50px !important;
                            border-left: 8px solid transparent;
                            border-right: 8px solid transparent;
                            position: absolute;
                            top: 87px;
                            right: 12px;
                            border-bottom: 16px solid white;
                        }
                        .profile-dropDown-container {
                            z-index: 1030 !important;
                            min-width: 260px;
                            max-width: 260px;
                            background: #fff !important;
                            right: 0px !important;
                            border-radius: 3px;
                            top: 48px;
                            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
                        }

                        .profile-dropDown-img {
                            height: 45px;
                            width: 45px;
                            border-radius: 100%;
                            max-width: unset;
                        }
                        .details-container *{
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }

                        .profile-sub-text {
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            // max-width: 120px;
                            line-height: 16px;
                            /* identical to box height, or 133% */
                            color: #6C757D;
                        }

                        .profile-email {
                            font-style: normal !important;
                            font-weight: 500 !important;
                            // max-width: 120px;
                            font-size: 16px;
                            line-height: 22px;
                            /* or 114% */
                            color: #212529;
                        }

                        .profile-divider {
                            width: 100%;
                            border-bottom: 0.5px solid #C3CDDF;
                        }
                        .profile-dropdown-options a, .profile-dropdown-options span, .profile-dropdown-options a > span{
                            color: #6D7780 !important;
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 23px;
                        }

                        .profile-dropdown-options {
                            display: block;
                            width: 100% !important;
                            padding: 8px 0px 8px 6px !important;
                            border-radius:2px !important;
                            font-size: 15px !important;
                            color: #6D7780 !important;
                            font-family: Nunito !important;
                            font-size: 16px !important;
                            font-weight: 400 !important;
                            line-height: 23px !important;
                            letter-spacing: 0em !important;
                            text-align: left !important;
                            text-decoration: none !important;
                        }

                        .profile-dropdown-options > *:hover {
                            background: #80747C33;
                            color: #2D2D2D;
                            cursor:pointer;
                        }

                        .switchEvent-pad {
                            padding-bottom: 0.8rem !important;
                        }

                        .dashboard-icon {
                            margin-left: -1px !important;
                        }
                        @media (min-width: 450px) {
                            .profile-dropdown-options.create_new_event {
                                display: none !important;
                            }

                            .profile-dropdown-options.my_community {
                                display: none !important;
                            }
                        }
            `}</style>
        </div>
    );
}

export default ProfileDropDown;
