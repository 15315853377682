import React from "react"
import TippyIcon from "./../../TippyIcon";

const EditQuickButton = ({onClick, status, className, disabled, style, content, size}) => (
    <TippyIcon content={content ? content : `Edit`} placement="bottom">
        <i
            style={{fontSize: `${size ? size : "16px"}`, color: "#572148", ...(style && {...style}) }}
	    	className={`${status === 'completed' ? 'fa fa-eye' : 'fas fa-pen'} pen-color ${className}`}
            disabled={disabled ? disabled : false}
	    	onClick={onClick}>
	    </i>
    </TippyIcon>
)

export default EditQuickButton;