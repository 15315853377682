import React, { Component, forwardRef } from 'react';
import AuthContext from "./../../../contexts/AuthContext";
import KonfhubButton from "./../../../revampComponents/Atom/Buttons/KonfhubButton";
import { getCommunityDetails } from "./../../../revampCRUD/myCommunity.crud";
import { baseUrl } from "./../../../config/config";
import { errorToaster } from "./../../../reusable/common";

const CreateCommunity = React.lazy(() => import('./CreateCommunity'));
const CommunityLinks = React.lazy(() => import('./CommunityLinks'));

class MyCommunity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            communityDetails: null,
            createCommunity: false,
            loading: false,
            communityLinksModal: false
        }
    }

    static contextType = AuthContext;

    componentDidMount() {
        this.fetchCommunityData();
    }

    fetchCommunityData = () => {
        this.setState({ ...this.state, communityDetails: this.context.communityDetails });
    }

    goToMyCommunity = () => {
        this.props.onChildStateChange(true);
        this.setState({ ...this.state, loading: true }, async () => {
            try {
                let communityDetails = null;
                try {
                    communityDetails = await getCommunityDetails();
                } catch(err) {
                    if(err.response.status === 404) communityDetails = null;
                    else throw new Error(err.message)
                }
                this.setState({ ...this.state, communityDetails: communityDetails?.data || null, loading: false }, this.decideAction);
                this.props.onChildStateChange(false);
            } catch(err) {
                this.setState({ ...this.state, loading: false }, this.decideAction);
                this.props.onChildStateChange(false);
                let error = err.message || 'Something went wrong!';
                errorToaster(error);
            }
        })
    }

    decideAction = () => {
        if(this.context.communityDetails) this.setState({ ...this.state, communityLinksModal: true });
        else this.setState({ ...this.state, createCommunity: true });
    }

    closeCreateCommunity = () => {
        this.setState({ ...this.state, createCommunity: false });
    }

    closeCommunityLinks = () => {
        this.setState({ ...this.state, communityLinksModal: false });
    }

    render() {
        return (
            <>
                {
                    this.state.createCommunity &&
                    <CreateCommunity
                        communityDetails={this.state.communityDetails}
                        createCommunity={this.state.createCommunity}
                        closeCreateCommunity={this.closeCreateCommunity}
                        user={this.context.user}
                    />
                }
                {
                    this.state.communityLinksModal &&
                    <CommunityLinks
                        history={this.props.history}
                        baseUrl={baseUrl}
                        communityDetails={this.state.communityDetails}
                        communityLinksModal={this.state.communityLinksModal}
                        closeCommunityLinks={this.closeCommunityLinks}
                    />
                }
            </>
        )
    }
}

export default MyCommunity;
