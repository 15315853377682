/** @format */

import axios from "axios";

let api;
if (window.location.hostname === "events.konfhub.com") {
	api = "https://api.konfhub.com";
} else {
	api = "https://dev-api.konfhub.com";
}
// https://github.com/axios/axios#config-defaults
axios.defaults.baseURL = api;

export const eventCaptureApi = () => {
	if (window.location.hostname === "events.konfhub.com") {
		return "https://api.konfhub.com/";
	} else {
		return "https://dev-api.konfhub.com";
	}
};



export default axios;
