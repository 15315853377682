import React from "react";

const KonfhubContainer = (props) => {
	let className = `container-fluid p-4 `;
	if (props.className) className += props.className;
	return (
		<div className={className}>
            {props.children}
        </div>
	);
};

export default KonfhubContainer;
