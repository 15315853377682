import axios from "./axios";

const API_URL = axios.defaults.baseURL;
const BASE_URL = "/event/";
const SESSIONS_URL = (eventId) => `${eventId}/sessions`
const TRACKS_URL = (eventId) => `${eventId}/tracks`

export const getSessions = (eventID, sessionsToReturn = "all") => {
    //sessionsToReturn Allowed values: all, assigned, unassigned
    let query = `?sessions_to_return=${sessionsToReturn}`
    return axios.get(
        API_URL + BASE_URL + SESSIONS_URL(eventID) + query
    );
};

export const getTracks = (eventID, trackDate) => {
    //sessionsToReturn Allowed values: all, assigned, unassigned
    let query = `?track_date=${trackDate}`
    return axios.get(
        API_URL + BASE_URL + TRACKS_URL(eventID) + query
    );
};

export const addSessions = (eventId, payload) => {
    return axios.post(
        API_URL + BASE_URL + SESSIONS_URL(eventId), payload
    );
};

export const updateSessions = (eventId, sessionId, payload) => {
    return axios.put(
        API_URL + BASE_URL + SESSIONS_URL(eventId) + `/${sessionId}`, payload
    );
};

export const addTracks = (eventId, payload) => {
    return axios.post(
        API_URL + BASE_URL + TRACKS_URL(eventId), payload
    );
};

export const updateTracks = (eventId, trackId, payload) => {
    return axios.put(
        API_URL + BASE_URL + TRACKS_URL(eventId) + `/${trackId}`, payload
    );
};
export const deleteTrack = (eventId, trackId) => {
    return axios.delete(
        API_URL + BASE_URL + TRACKS_URL(eventId) + `/${trackId}`
    );
};

export const reorderSessions = (eventId, payload) => {
    return axios.put(
        API_URL + BASE_URL + SESSIONS_URL(eventId) + '/reorder', payload
    );
};

export const deleteSession = (eventId, sessionId) => {
    return axios.delete(
        API_URL + BASE_URL + SESSIONS_URL(eventId) + `/${sessionId}`
    )

};
