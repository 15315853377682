import React, { useState, useEffect, useCallback, useRef } from "react";
import CustomModal from "./CustomModal";
import warning from "../../../assets/img/Models/warning.svg";
import { useHistory } from "react-router-dom";

const RouteModal = ({ shouldBlock, setOpenModal, onOkay, onCancel, text }) => {
	const history = useHistory();
	const [showPrompt, setShowPrompt] = useState(false);
	const [currentPath, setCurrentPath] = useState("");
	const unblockRef = useRef(null);

	useEffect(() => {
		if (shouldBlock && !showPrompt) {
			window.onbeforeunload = () => true;
		} else {
			window.onbeforeunload = undefined;
		}

		return () => {
			window.onbeforeunload = undefined;
		};
	}, [shouldBlock, showPrompt]);

	useEffect(() => {
		if (shouldBlock) {
			unblockRef.current = history.block(prompt => {
				if (!prompt.dontRedirect) {
					setCurrentPath(prompt.pathname);
					setShowPrompt(true);
					return false;
				}
				return true;
			});
		} else if (unblockRef.current) {
			unblockRef.current();
		}

		return () => {
			if (unblockRef.current) {
				unblockRef.current();
			}
		};
	}, [history, shouldBlock]);

	const handleOK = useCallback(() => {
		if (unblockRef.current) {
			unblockRef.current();
		}
		history.push(currentPath);
		if (onOkay) onOkay();
	}, [currentPath, history, onOkay]);

	const handleCancel = useCallback(() => {
		setShowPrompt(false);
		if (onCancel) onCancel();
	}, [onCancel]);

	return (
		<>
			{showPrompt && (
				<CustomModal
					openModal={showPrompt}
					setOpenModal={setShowPrompt}
					saveBtnContent="Yes"
					cancelBtnContent="No"
					content={
						<div className="mt-2 mx-4 text-center">
							<div>
								<img
									src={warning}
									className="mb-3"
									style={{
										width: "4rem"
									}}
								/>
							</div>
							<p className="formTitle fw-bold mb-2 text-center text-danger">
								Unsaved changes
							</p>
							<div className="text-center">
								<div className="text-center">
									You have unsaved changes, <br /> are you sure you want to
									leave?
								</div>
							</div>
						</div>
					}
					onCancel={handleCancel}
					onOkay={handleOK}
				/>
			)}
		</>
	);
};

export default RouteModal;
