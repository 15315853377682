// import moment from "moment";
import React from "react";
import moment from "moment-timezone";
import { addSessions, updateTracks } from "../revampCRUD/agenda.crud";
import GetCurrency from "../revampComponents/Atom/services/GetCurrency";

// this function gives you the event base URL
export const getBaseURL = () => {
	let newHostName = "";
	if (window.location.hostname == "events.konfhub.com") {
		newHostName = "https://konfhub.com/";
	} else {
		newHostName = "https://dev.konfhub.com/";
	}

	return newHostName;
};

// Returns date and time from IST to utc
export const getUTCDateNTime = (dateTime, timezone) => {
	const tempDateTime = moment.tz(dateTime, timezone).format();

	let tempData;
	tempData = new Date(tempDateTime).toISOString();
	tempData = tempData.toString();
	tempData = tempData.slice(0, tempData.length - 5);
	tempData = tempData.replaceAll("T", " ");

	return tempData;
};

// Returns date and time from utc to IST
export const getISTDateNTime = (dateTime, timezone, optional = false, format = false) => {
	let convertedDateTime;
	convertedDateTime = moment.utc(dateTime).tz(timezone);

	if (optional === "needed") {
		const addGmtOrNot = moment.tz(timezone).zoneAbbr().includes("+")
			? `GMT${moment.tz(timezone).zoneAbbr()}`
			: moment.tz(timezone).zoneAbbr();
		const date = moment(convertedDateTime).format("MMM DD, YYYY hh:mm A ") + addGmtOrNot;
		return date;
	} else if (optional == "timezoneDate") {
		const date =
			moment(convertedDateTime).format("YYYY-MM-DD HH:mm:ss ") +
			moment.tz(timezone).format("z");
		return date;
	} else if (optional == "timezoneNTime") {
		const date = moment(convertedDateTime).format("HH:mm A ") + moment.tz(timezone).format("z");
		return date;
	} else if (optional == "timezoneNDate") {
		const date =
			moment(convertedDateTime).format("DD MMM YY, ") + moment.tz(timezone).format("z");
		return date;
	} else if (optional == "Date") {
		const date = moment(convertedDateTime).format("DD MMM YY");
		return date;
	} else if (optional == "needed") return moment(convertedDateTime).format("YYYY-MM-DD HH:mm");
	else if (optional == "ticket")
		return moment(convertedDateTime).format("YYYY-MM-DD HH:mm").replace(/ /g, "T");
	else if (optional == "time") {
		const date = moment(convertedDateTime).format("hh:mm A ");
		return date;
	} else if (optional == "forTime") {
		return convertedDateTime;
	} else if (optional == "bulkUpload") {
		return moment(convertedDateTime).format("lll");
	} else if (format) {
		const date = moment(convertedDateTime).format(format);
		return date;
	} else return moment(convertedDateTime).format("YYYY-MM-DD HH:mm:ss");

	// return date;
};

export const getCurrentDate = eventId => {
	const tempCurrentDate = getISTDateNTime(new Date(), eventId);
	let timeOffset = moment.tz(eventId).format("Z");
	const offsetSign = timeOffset.slice(0, 1);
	timeOffset = timeOffset.slice(1);
	var timeParts = timeOffset.split(":");
	const timeOffsetValue = Number(timeParts[0]) * 60 + Number(timeParts[1]);
	const timeData = {
		from_timestamp: tempCurrentDate.slice(0, 10) + "T00:00:00",
		to_timestamp: tempCurrentDate.slice(0, 10) + "T23:59:59",
		time_offset: offsetSign.concat(timeOffsetValue)
	};
	return timeData;
};

//Initial value to return date format for safari and other browsers
export const convertToDateObj = value => {
	if (value) return moment(value).toDate();
	return null;
};

//Capital letter of every words
export const capitalizeFirst = str => {
	return str.charAt(0).toUpperCase() + str.slice(1);
};

// Replace placeholder variables - dropdown in editors
export const replacePlaceholder = async (tempDataParam, eventDetails) => {
	const ISTStartDateAndStartTime = getISTDateNTime(
		eventDetails.start_date + " " + eventDetails.start_time,
		eventDetails.time_zone
	);

	const ISTEndDateAndEndTime = getISTDateNTime(
		eventDetails.end_date + " " + eventDetails.end_time,
		eventDetails.time_zone
	);
	let tempData = tempDataParam;
	tempData = tempData.replaceAll("$event_name", eventDetails.name);
	tempData = tempData.replaceAll("$event_venue", eventDetails.venue);
	tempData = tempData.replaceAll("$event_city", eventDetails.city);
	tempData = tempData.replaceAll("$event_state", eventDetails.state);
	tempData = tempData.replaceAll("$event_country", eventDetails.country);

	tempData = tempData.replaceAll("${event_start_time}", ISTStartDateAndStartTime.split(" ")[1]);
	tempData = tempData.replaceAll("${event_end_time}", ISTEndDateAndEndTime.split(" ")[1]);
	tempData = tempData.replaceAll("${event_start_date}", ISTStartDateAndStartTime.split(" ")[0]);
	tempData = tempData.replaceAll("${event_end_date}", ISTEndDateAndEndTime.split(" ")[0]);

	tempData = tempData.replaceAll("$event_website", eventDetails.event_website);
	tempData = tempData.replaceAll("$event_live_link", eventDetails.event_live_link);
	tempData = tempData.replaceAll("$organiser_email", eventDetails.organiser_email);
	tempData = tempData.replaceAll("$event_url", `${getBaseURL()}${eventDetails.event_url}`);
	// extra space getting added - removing it
	tempData = tempData.replaceAll("<p>", `<p style="margin:0px 0px">`);
	tempData = tempData.replaceAll("<li>", `<li style="white-space:initial">`);
	return tempData;
};

export const getCurrentEventId = () => {
	const pathname = window.location.pathname;
	const eventId = pathname.split("/")[2];
	return eventId;
};

// App.js
export const sortEventDetails = eventDetails => {
	var sortedEventDetails = [];
	if (eventDetails !== undefined && eventDetails.length > 0) {
		eventDetails.forEach(event => {
			if (event.event_status) {
				sortedEventDetails.push(event);
			}
		});

		eventDetails.forEach(event => {
			if (!event.event_status) {
				sortedEventDetails.push(event);
			}
		});
	} else {
		return eventDetails;
	}

	return sortedEventDetails;
};

//Scroll up to the form which has error
export const scrollToError = (formik, errorId = false) => {
	const err = Object.keys(formik.errors);
	if (err.length) {
		let input;
		if (errorId) {
			input = document.getElementById(errorId);
		} else {
			input = document.querySelector(`input[name=${err[0]}]`);
		}

		input?.scrollIntoView({
			behavior: "smooth",
			block: "nearest",
			inline: "center"
			// inline: 'start',
		});
	}
};

/*************************************************** AGENDA **************************************/
//This function returns an array which contains all the dates in between the start and end dates you provide including them
export const getDaysArray = function (start, end) {
	for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
		arr.push(new Date(dt).toISOString().split("T")[0]);
	}
	return arr;
};

export const addZero = i => {
	if (i < 10) {
		i = "0" + i;
	}
	return i;
};

export const getTimesArray = (
	startTimeString,
	endTimeString,
	timDiff = 15,
	removeSuffix = false,
	date = "2023-02-22"
) => {
	const startTime = new Date(`${date} ${startTimeString}`);
	const endTime = new Date(`${date} ${endTimeString}`);
	const timeArray = [];
	while (startTime <= endTime) {
		const timeString = startTime.toLocaleTimeString([], {
			hour: "2-digit",
			minute: "2-digit"
		});
		timeArray.push(removeSuffix ? `${timeString.split(" ")[0]}:00` : timeString);
		startTime.setMinutes(startTime.getMinutes() + timDiff);
	}
	return timeArray;
};

export const convertToDropElement = (session, isFromTrack = false) => {
	if (isFromTrack) {
		const charCount = session.session_title.length;
		const height = parseInt(charCount / 27) + 1; //27 characters fit in one line
		return {
			i: `${session.session_id}`,
			w: 1,
			h: !_.isEmpty(session.session_speakers) ? 2 + height : 1 + height
		};
	} else if (!isFromTrack) {
		// let timeArray = getTimesArray(session.start_timestamp.split(" ")[1], session.end_timestamp.split(" ")[1]);
		// let height = timeArray.length - 1;
		// let sessionId = session.session_id
		// return {i: `${sessionId}`, h: height, w: 1}
		const sessionId = session.session_id;
		return { i: `${sessionId}`, h: 2, w: 1 };
	}
};

function markDuplicateObjects(...arrays) {
	const objectCount = {};

	// Loop through the objects in each array and count the number of times each object appears
	for (let i = 0; i < arrays.length - 1; i++) {
		const arr = arrays[i];
		const nextArr = arrays[i + 1];

		for (const obj of arr) {
			const objString = JSON.stringify(obj);

			if (nextArr.some(nextObj => JSON.stringify(nextObj) === objString)) {
				if (objString in objectCount) {
					objectCount[objString]++;
				} else {
					objectCount[objString] = 1;
				}
			}
		}
	}

	// Loop through the objects in each array and add a `duplicate` field to objects that appear more than once
	for (const arr of arrays) {
		for (const obj of arr) {
			const objString = JSON.stringify(obj);

			if (objString in objectCount && objectCount[objString] > 0) {
				obj.duplicate = objectCount[objString];
				objectCount[objString] = 0; // reset count to 0 to avoid marking duplicates again
			}
		}
	}

	for (let i = 0; i < arrays.length - 1; i++) {
		const arr = arrays[i];
		const nextArr = arrays[i + 1];
		for (const obj of arr) {
			obj.x = i;
		}
		for (const obj of nextArr) {
			obj.x = i + 1;
		}
	}

	// Return a new array containing all the objects with the `duplicate` field added where appropriate
	return arrays.flat();
}

function removeDuplicateObjects(arr) {
	const uniqueObjects = {};
	const result = [];

	// Loop through the array of objects
	for (const obj of arr) {
		// If an object with the same id already exists, skip it
		if (uniqueObjects[obj.session_id]) {
			continue;
		}

		// Add the object to the uniqueObjects map and the result array
		uniqueObjects[obj.session_id] = true;
		result.push(obj);
	}

	return result;
}

export const convertToGridLayout = tracks => {
	const grid_layout = [];
	const allTrackSessions = [];

	tracks.map(track => {
		const assignedSessions = track.track_sessions.filter(
			session => session.track_assigned === true
		);
		allTrackSessions.push(assignedSessions);
	});

	let allObj = markDuplicateObjects(...allTrackSessions);
	allObj = removeDuplicateObjects(allObj);

	const timeArr = getTimesArray(tracks[0].start_time, tracks[0].end_time, 15, true);

	allObj.forEach(session => {
		const sessionLayout = {};
		sessionLayout.x = session.x;
		sessionLayout.w = session.hasOwnProperty("duplicate") ? session.duplicate + 1 : 1;
		const startTimeIndex = timeArr.indexOf(session.start_timestamp.split(" ")[1]);
		const endTimeIndex = timeArr.indexOf(session.end_timestamp.split(" ")[1]);
		sessionLayout.y = startTimeIndex;
		sessionLayout.h = endTimeIndex - startTimeIndex;
		sessionLayout.i = session.session_id;
		sessionLayout.session = session;
		grid_layout.push(sessionLayout);
	});

	return grid_layout;
};

export function findMatchingsessionsById(arr1, arr2) {
	// Create a Map to store objects by ID from the second array
	const objMap = new Map();
	for (const obj of arr2) {
		objMap.set(obj.session_id, obj);
	}

	// Create an empty array to store the matching objects
	const matchingObjects = [];

	// Loop through the first array of objects
	for (const obj of arr1) {
		const id = obj.session_id;
		const matchingObj = objMap.get(id);

		// If a matching object was found, add it to the matchingObjects array
		if (matchingObj) {
			matchingObjects.push(matchingObj);
		}
	}

	// Return the array of matching objects
	return matchingObjects;
}

export function deleteObjectFields(obj, prop) {
	for (const p of prop) {
		delete obj[p];
	}
}

export const createBaseSessions = async (selectedDate, eventInfo) => {
	const sessions = ["Refreshment Break", "Lunch Time", "Fun Time"];

	// set initial value for add/edit session modal
	await sessions.forEach(async (sessionName, i) => {
		const payload = {
			session_title: sessionName,
			session_description: "",
			session_type: 4,
			start_timestamp: getISTDateNTime(
				`${selectedDate} ${eventInfo.start_time}`,
				eventInfo.time_zone
			),
			end_timestamp: getISTDateNTime(
				new Date(`${selectedDate} ${eventInfo.start_time}`).setHours(
					new Date().getHours() + 1
				),
				eventInfo.time_zone
			),
			session_order: i + 1,
			session_speakers: [],
			track_assigned: i === 0 ? true : false,
			session_colour: i == 1 ? "#E4EFFB" : i == 2 ? "#FFECD3" : "#DEF6E9",
			grid_layout: i === 0 ? { x: 0, y: 0, w: 1, h: 2 } : null
		};

		try {
			const res = await addSessions(eventInfo.event_id, payload);
		} catch (err) {
			console.log(err);
		}
	});
};

export const calculateHeight = session => {
	const difference =
		new Date(session.end_timestamp).getTime() - new Date(session.start_timestamp).getTime();
	const resultInMinutes = Math.round(difference / 60000);
	return (resultInMinutes / 15).toFixed(2);
};

export const calculateY = (session, tracksData, timeZone, selectedDate, noConvertToITC = false) => {
	if (selectedDate !== session.start_timestamp.split(" ")[0] && session?.grid_layout?.y)
		return session.grid_layout.y;
	const startTimeString = _.isEmpty(tracksData) ? 0 : tracksData[0].start_time;
	//getISTDateNTime(`${selectedDate} ${tracksData[0].start_time}`, timeZone, false, "HH:mm");
	let sessionStartTimeString = "";
	if (noConvertToITC)
		sessionStartTimeString = `${addZero(
			new Date(session.start_timestamp).getHours()
		)}:${addZero(new Date(session.start_timestamp).getMinutes())}`;
	else sessionStartTimeString = session.start_timestamp.split(" ")[1] + ":00";
	//getISTDateNTime(`${session.start_timestamp}`, timeZone, false, "HH:mm");

	const startTime = new Date(`${selectedDate} ${startTimeString}`).getTime();
	const sessionStartTime = new Date(`${selectedDate} ${sessionStartTimeString}`).getTime();

	//console.log(startTimeString, sessionStartTimeString, `${selectedDate} ${startTimeString}`, `${selectedDate} ${sessionStartTimeString}`)
	//console.log(new Date(`${selectedDate} ${startTimeString}`).getTime(), new Date(`${selectedDate} ${sessionStartTimeString}`).getTime())
	//console.log(parseFloat(((sessionStartTime - startTime) / 60000) / 15).toFixed(2))

	return parseFloat((sessionStartTime - startTime) / 60000 / 15).toFixed(2);
};

export const generateGridLayout = (
	session,
	tracksData,
	timeZone,
	selectedDate,
	noConvertToITC,
	startingTrack = 0
) => {
	const gridLayout = session.grid_layout || {};
	gridLayout.h = +parseFloat(calculateHeight(session)).toFixed(2);
	gridLayout.y = +parseFloat(
		calculateY(session, tracksData, timeZone, selectedDate, noConvertToITC)
	).toFixed(2);
	gridLayout.x = gridLayout.x || startingTrack;
	gridLayout.w = gridLayout.w || 1;
	gridLayout.i = session.session_id;
	return gridLayout;
};

export const checkIfOverLaps = (
	session,
	sessionsGridLayout,
	tracksData,
	timeZone,
	selectedDate,
	noConvertToITC,
	startingTrack
) => {
	if (session.session_id && session.session_id === sessionsGridLayout.session.session_id)
		return { session: sessionsGridLayout.session, overlapping: false };
	const gridLayout =
		session.grid_layout ||
		generateGridLayout(
			session,
			tracksData,
			timeZone,
			selectedDate,
			noConvertToITC,
			startingTrack
		);
	const rect1X = [gridLayout.x, gridLayout.x + gridLayout.w];
	const rect1Y = [gridLayout.y, gridLayout.y + gridLayout.h];
	const rect2X = [sessionsGridLayout.x, sessionsGridLayout.x + sessionsGridLayout.w];
	const rect2Y = [sessionsGridLayout.y, sessionsGridLayout.y + sessionsGridLayout.h];

	// Check if rectangle 1 overlaps rectangle 2 in the X direction
	const xOverlap = rect1X[0] < rect2X[1] && rect2X[0] < rect1X[1];

	// Check if rectangle 1 overlaps rectangle 2 in the Y direction
	const yOverlap = rect1Y[0] < rect2Y[1] && rect2Y[0] < rect1Y[1];
	//console.log(xOverlap, yOverlap)

	// Check if rectangle 1 overlaps rectangle 2
	if (yOverlap && xOverlap) {
		return { session: sessionsGridLayout.session, overlapping: true };
	}
	return { session: sessionsGridLayout.session, overlapping: false };
};

//converting custom from name
const customStripHtmlTags = htmlString => {
	const doc = new DOMParser().parseFromString(htmlString, "text/html");
	const body = doc.body;
	return body.childNodes.length > 0 ? body.childNodes[0].textContent : "";
};

export const fetchCustomFormName = (displayName, fieldName) => {
	const strippedHtml = customStripHtmlTags(displayName?.replace(/(?:\r\n|\r|\n)/g, "") || "");
	const capitalizedText = capitalizeFirst(strippedHtml);

	if (displayName && !fieldName) {
		return React.createElement("span", {
			dangerouslySetInnerHTML: {
				__html: capitalizedText.replace(/_/g, " ")
			}
		});
	}
	if (displayName && fieldName) {
		return React.createElement("label", {
			for: fieldName,
			dangerouslySetInnerHTML: {
				__html: capitalizedText.replace(/_/g, " ")
			}
		});
	}
};

export const fetchCustomFormNameWithDecoding = (displayName, fieldName) => {
	const decodedDisplayName = decodeFormNames(displayName);
	const strippedHtml = customStripHtmlTags(
		decodedDisplayName?.replace(/(?:\r\n|\r|\n)/g, "") || ""
	);
	const capitalizedText = capitalizeFirst(strippedHtml);

	if (decodedDisplayName && !fieldName) {
		return React.createElement("span", {
			dangerouslySetInnerHTML: {
				__html: capitalizedText.replace(/_/g, " ")
			}
		});
	}
	if (decodedDisplayName && fieldName) {
		return React.createElement("label", {
			for: fieldName,
			dangerouslySetInnerHTML: {
				__html: capitalizedText.replace(/_/g, " ")
			}
		});
	}
};

export const encodeFormNames = formName => {
	return formName
		.replaceAll(".", "<FullStop>")
		.replaceAll("[", "<OpenSquareBracket>")
		.replaceAll("]", "<CloseSquareBracket>");
};

export const decodeFormNames = formName => {
	return formName
		.replaceAll("<FullStop>", ".")
		.replaceAll("<OpenSquareBracket>", "[")
		.replaceAll("<CloseSquareBracket>", "]");
};

//converting predefined from name
const stripHtmlTags = htmlString => {
	const doc = new DOMParser().parseFromString(htmlString, "text/html");
	const body = doc.body;

	// Iterate through all anchor tags
	body.querySelectorAll("a").forEach(anchor => {
		// Set target attribute to '_blank' for all anchor tags
		anchor.setAttribute("target", "_blank");

		// Set the color of the anchor text to blue
		anchor.style.color = "blue";

		// Convert any child tags to plain text
		anchor.childNodes.forEach(child => {
			if (child.nodeType === Node.ELEMENT_NODE) {
				const textNode = doc.createTextNode(child.textContent);
				child.replaceWith(textNode);
			}
		});
	});

	return body.innerHTML;
};

export const fetchFormName = val => {
	return React.createElement("span", {
		dangerouslySetInnerHTML: {
			__html: stripHtmlTags(val?.replace(/(?:\r\n|\r|\n)/g, "") || "").replace(
				/<p[^>]*>/g,
				'<p style="margin: 0;">'
			)
		}
	});
};

export const addFifteenDaysToEventDateAndTime = (endDate, endTime) => {
	// Given date and time
	const givenDateTime = new Date(`${endDate}T${endTime}`);

	// Add 15 days to the given date
	givenDateTime.setDate(givenDateTime.getDate() + 15);
	return givenDateTime;
};

export function checkPotentialDomainTypo(email) {
	// Extract the domain after "@"
	const domain = email.split("@")[1]?.toLowerCase();

	// Define a list of common typos (modify this list as needed)
	const commonTypos = [
		"gmal.com",
		"gnail.com",
		"gamal.com",
		"gmal.com",
		"gamil.com",
		"gaml.com",
		"gmail.in",
		"gmail.co",
		"gmail.in",
		"gemail.com",
		"hotmal.com",
		"yaho.com"
	];
	// Check if the domain exists in the list
	if (domain) {
		return commonTypos.includes(domain) ? true : false;
	}

	// No match found or invalid email format
	return true;
}

export const CurrencyWithAmount = ({
	currencyName,
	number,
	removeNegativeFromNumber = false,
	className,
	style,
	fontSize
}) => {
	//return currency with an number having comas based on the region, with a space between them, up to 2 decimal places. Example: ₹ 16,315.26

	const currencyLocaleMap = {
		AED: "ar-AE", // United Arab Emirates
		USD: "en-US", // United States
		AMD: "hy-AM", // Armenia
		ARS: "es-AR", // Argentina
		AUD: "en-AU", // Australia
		AWG: "nl-AW", // Aruba
		BBD: "en-BB", // Barbados
		BDT: "bn-BD", // Bangladesh
		BMD: "en-BM", // Bermuda
		BND: "ms-BN", // Brunei
		BOB: "es-BO", // Bolivia
		BSD: "en-BS", // Bahamas
		BWP: "en-BW", // Botswana
		BZD: "en-BZ", // Belize
		CAD: "en-CA", // Canada
		CHF: "de-CH", // Switzerland
		CNY: "zh-CN", // China
		COP: "es-CO", // Colombia
		CRC: "es-CR", // Costa Rica
		CUP: "es-CU", // Cuba
		CZK: "cs-CZ", // Czech Republic
		DKK: "da-DK", // Denmark
		DOP: "es-DO", // Dominican Republic
		DZD: "ar-DZ", // Algeria
		EGP: "ar-EG", // Egypt
		ETB: "am-ET", // Ethiopia
		EUR: "en-EU", // European Union
		FJD: "en-FJ", // Fiji
		GBP: "en-GB", // United Kingdom
		GIP: "en-GI", // Gibraltar
		GHS: "en-GH", // Ghana
		GMD: "en-GM", // Gambia
		GTQ: "es-GT", // Guatemala
		GYD: "en-GY", // Guyana
		HKD: "en-HK", // Hong Kong
		HNL: "es-HN", // Honduras
		HRK: "hr-HR", // Croatia
		HTG: "ht-HT", // Haiti
		HUF: "hu-HU", // Hungary
		IDR: "id-ID", // Indonesia
		ILS: "he-IL", // Israel
		JMD: "en-JM", // Jamaica
		KES: "en-KE", // Kenya
		KGS: "ky-KG", // Kyrgyzstan
		KHR: "km-KH", // Cambodia
		KYD: "en-KY", // Cayman Islands
		KZT: "ru-KZ", // Kazakhstan
		LAK: "lo-LA", // Laos
		LBP: "ar-LB", // Lebanon
		LKR: "si-LK", // Sri Lanka
		LRD: "en-LR", // Liberia
		LSL: "en-LS", // Lesotho
		MAD: "ar-MA", // Morocco
		MDL: "ro-MD", // Moldova
		MKD: "mk-MK", // North Macedonia
		MMK: "my-MM", // Myanmar
		MNT: "mn-MN", // Mongolia
		MOP: "zh-MO", // Macau
		MUR: "en-MU", // Mauritius
		MVR: "dv-MV", // Maldives
		MWK: "en-MW", // Malawi
		MXN: "es-MX", // Mexico
		MYR: "ms-MY", // Malaysia
		NAD: "af-NA", // Namibia
		NGN: "en-NG", // Nigeria
		NIO: "es-NI", // Nicaragua
		NOK: "no-NO", // Norway
		NPR: "ne-NP", // Nepal
		NZD: "en-NZ", // New Zealand
		PEN: "es-PE", // Peru
		PGK: "en-PG", // Papua New Guinea
		PHP: "fil-PH", // Philippines
		PKR: "ur-PK", // Pakistan
		QAR: "ar-QA", // Qatar
		RUB: "ru-RU", // Russia
		SAR: "ar-SA", // Saudi Arabia
		SCR: "en-SC", // Seychelles
		SEK: "sv-SE", // Sweden
		SGD: "en-SG", // Singapore
		SLL: "en-SL", // Sierra Leone
		SOS: "so-SO", // Somalia
		SSP: "en-SS", // South Sudan
		SVC: "es-SV", // El Salvador
		SZL: "en-SZ", // Eswatini
		THB: "th-TH", // Thailand
		TTD: "en-TT", // Trinidad and Tobago
		TZS: "sw-TZ", // Tanzania
		ALL: "sq-AL", // Albania
		UYU: "es-UY", // Uruguay
		UZS: "uz-UZ", // Uzbekistan
		YER: "ar-YE", // Yemen
		ZAR: "en-ZA" // South Africa
	};

	const listOfCurrencyDifferentFormat = {
		// list of currency where the currency symbol comes after the amount e.g. 2.00 د.إ
		AED: "ar-AE",
		CHF: "de-CH",
		CZK: "cs-CZ",
		DKK: "da-DK",
		HUF: "hu-HU",
		NOK: "nb-NO",
		PLN: "pl-PL",
		SEK: "sv-SE"
	};

	const listOfNonStandardNumericCurrencies = {
		BDT: "bn-BD",
		EGP: "ar-EG",
		LBP: "ar-LB",
		QAR: "ar-QA",
		SAR: "ar-SA",
		YER: "ar-YE"
	};

	const ifCurrencyExist = () => {
		// return true if currency exist in listOfCurrencyDifferentFormat
		if (Object.keys(listOfCurrencyDifferentFormat).includes(currencyName)) {
			return true;
		}
		return false;
	};

	function getLocaleFromCurrency(currency) {
		const upperCaseCurrency = currency?.toUpperCase();
		if (
			Object.prototype.hasOwnProperty.call(
				listOfNonStandardNumericCurrencies,
				upperCaseCurrency
			)
		) {
			return "sv-SE";
		}
		if (Object.prototype.hasOwnProperty.call(currencyLocaleMap, upperCaseCurrency)) {
			return currencyLocaleMap[upperCaseCurrency];
		}
		// Default to en-IN for unknown currencies
		return "en-IN";
	}

	const _number = removeNegativeFromNumber ? Math.abs(number) : number; // remove negative sign

	// Convert the input number to a Number object and format it as currency
	const formattedNumber = Number(_number).toLocaleString(getLocaleFromCurrency(currencyName), {
		maximumFractionDigits: 2, // Set the maximum number of decimal places to 2
		minimumFractionDigits: 2 // Set the minimum number of decimal places to 2
	});

	function formatString(string) {
		// Remove trailing '.00' if present
		if (string.endsWith(".00") || string.endsWith(",00")) {
			return string.slice(0, -3); // Remove last 3 characters
		}
		return string; // Otherwise return the string as is
	}

	// return `${currencyName === "AUD" ? "A" : ""}${getSymbolFromCurrency(currencyName)} ${formattedNumber}`
	return (
		<>
			{ifCurrencyExist(currencyName) ? (
				<>
					<span style={{ marginRight: "3.5px" }}>{formatString(formattedNumber)}</span>
					<GetCurrency
						currency={currencyName}
						className={className}
						style={style}
						fontSize={fontSize}
					/>
				</>
			) : (
				<>
					<GetCurrency
						currency={currencyName}
						className={className}
						style={style}
						fontSize={fontSize}
					/>
					<span style={{ marginLeft: "3.5px" }}>{formatString(formattedNumber)}</span>
				</>
			)}
		</>
	);
};

export const IndianState = [
	{ label: "Andhra Pradesh", value: "AP" },
	{ label: "Arunachal Pradesh", value: "AR" },
	{ label: "Assam", value: "AS" },
	{ label: "Bihar", value: "BR" },
	{ label: "Chhattisgarh", value: "CG" },
	{ label: "Goa", value: "GA" },
	{ label: "Gujarat", value: "GJ" },
	{ label: "Haryana", value: "HR" },
	{ label: "Himachal Pradesh", value: "HP" },
	{ label: "Jammu and Kashmir", value: "JK" },
	{ label: "Jharkhand", value: "JH" },
	{ label: "Karnataka", value: "KA" },
	{ label: "Kerala", value: "KL" },
	{ label: "Madhya Pradesh", value: "MP" },
	{ label: "Maharashtra", value: "MH" },
	{ label: "Manipur", value: "MN" },
	{ label: "Meghalaya", value: "ML" },
	{ label: "Mizoram", value: "MZ" },
	{ label: "Nagaland", value: "NL" },
	{ label: "Odisha", value: "OD" },
	{ label: "Punjab", value: "PB" },
	{ label: "Rajasthan", value: "RJ" },
	{ label: "Sikkim", value: "SK" },
	{ label: "Tamil Nadu", value: "TN" },
	{ label: "Telangana", value: "TS" },
	{ label: "Tripura", value: "TR" },
	{ label: "Uttarakhand", value: "UK" },
	{ label: "Uttar Pradesh", value: "UP" },
	{ label: "West Bengal", value: "WB" },
	{ label: "Andaman and Nicobar Islands", value: "AN" },
	{ label: "Chandigarh", value: "CH" },
	{ label: "Dadra and Nagar Haveli", value: "DN" },
	{ label: "Daman and Diu", value: "DDD" },
	{ label: "Delhi", value: "DL" },
	{ label: "Lakshadweep", value: "LD" },
	{ label: "Puducherry", value: "PY" }
];

export const eventLevelOptions = [
	{ value: "AED", label: "AED" },
	{ value: "AUD", label: "AUD" },
	{ value: "BGN", label: "BGN" },
	{ value: "BRL", label: "BRL" },
	{ value: "CAD", label: "CAD" },
	{ value: "CHF", label: "CHF" },
	{ value: "CZK", label: "CZK" },
	{ value: "DKK", label: "DKK" },
	{ value: "EUR", label: "EUR" },
	{ value: "GBP", label: "GBP" },
	{ value: "HKD", label: "HKD" },
	{ value: "HUF", label: "HUF" },
	{ value: "INR", label: "INR" },
	// { value: "KWD", label: "KWD"},
	{ value: "MYR", label: "MYR" },
	{ value: "MXN", label: "MXN" },
	{ value: "NOK", label: "NOK" },
	{ value: "NZD", label: "NZD" },
	{ value: "PHP", label: "PHP" },
	{ value: "PLN", label: "PLN" },
	{ value: "RON", label: "RON" },
	{ value: "SEK", label: "SEK" },
	{ value: "SGD", label: "SGD" },
	{ value: "THB", label: "THB" },
	{ value: "USD", label: "USD" },
	{ value: "BHD", label: "BHD" },
	{ value: "OMR", label: "OMR" },
	{ value: "KWD", label: "KWD" },
	{ value: "EGP", label: "EGP" },
	{ value: "QAR", label: "QAR" },
	{ value: "SAR", label: "SAR" }
];

export const ticketLevelOptions = [
	{ value: "INR", label: "INR" },
	{ value: "USD", label: "USD" },
	{ value: "AMD", label: "AMD" },
	{ value: "ARS", label: "ARS" },
	{ value: "AUD", label: "AUD" },
	{ value: "AWG", label: "AWG" },
	{ value: "BBD", label: "BBD" },
	{ value: "BDT", label: "BDT" },
	{ value: "BMD", label: "BMD" },
	{ value: "BND", label: "BND" },
	{ value: "BOB", label: "BOB" },
	{ value: "BSD", label: "BSD" },
	{ value: "BWP", label: "BWP" },
	{ value: "BZD", label: "BZD" },
	{ value: "CAD", label: "CAD" },
	{ value: "CHF", label: "CHF" },
	{ value: "CNY", label: "CNY" },
	{ value: "COP", label: "COP" },
	{ value: "CRC", label: "CRC" },
	{ value: "CUP", label: "CUP" },
	{ value: "CZK", label: "CZK" },
	{ value: "DKK", label: "DKK" },
	{ value: "DOP", label: "DOP" },
	{ value: "DZD", label: "DZD" },
	{ value: "EGP", label: "EGP" },
	{ value: "ETB", label: "ETB" },
	{ value: "EUR", label: "EUR" },
	{ value: "FJD", label: "FJD" },
	{ value: "GBP", label: "GBP" },
	{ value: "GIP", label: "GIP" },
	{ value: "GHS", label: "GHS" },
	{ value: "GMD", label: "GMD" },
	{ value: "GTQ", label: "GTQ" },
	{ value: "GYD", label: "GYD" },
	{ value: "HKD", label: "HKD" },
	{ value: "HNL", label: "HNL" },
	{ value: "HRK", label: "HRK" },
	{ value: "HTG", label: "HTG" },
	{ value: "HUF", label: "HUF" },
	{ value: "IDR", label: "IDR" },
	{ value: "ILS", label: "ILS" },
	{ value: "AED", label: "AED" },
	{ value: "JMD", label: "JMD" },
	{ value: "KES", label: "KES" },
	{ value: "KGS", label: "KGS" },
	{ value: "KHR", label: "KHR" },
	{ value: "KYD", label: "KYD" },
	{ value: "KZT", label: "KZT" },
	{ value: "LAK", label: "LAK" },
	{ value: "LBP", label: "LBP" },
	{ value: "LKR", label: "LKR" },
	{ value: "LRD", label: "LRD" },
	{ value: "LSL", label: "LSL" },
	{ value: "MAD", label: "MAD" },
	{ value: "MDL", label: "MDL" },
	{ value: "MKD", label: "MKD" },
	{ value: "MMK", label: "MMK" },
	{ value: "MNT", label: "MNT" },
	{ value: "MOP", label: "MOP" },
	{ value: "MUR", label: "MUR" },
	{ value: "MVR", label: "MVR" },
	{ value: "MWK", label: "MWK" },
	{ value: "MXN", label: "MXN" },
	{ value: "MYR", label: "MYR" },
	{ value: "NAD", label: "NAD" },
	{ value: "NGN", label: "NGN" },
	{ value: "NIO", label: "NIO" },
	{ value: "NOK", label: "NOK" },
	{ value: "NPR", label: "NPR" },
	{ value: "NZD", label: "NZD" },
	{ value: "PEN", label: "PEN" },
	{ value: "PGK", label: "PGK" },
	{ value: "PHP", label: "PHP" },
	{ value: "PKR", label: "PKR" },
	{ value: "QAR", label: "QAR" },
	{ value: "RUB", label: "RUB" },
	{ value: "SAR", label: "SAR" },
	{ value: "SCR", label: "SCR" },
	{ value: "SEK", label: "SEK" },
	{ value: "SGD", label: "SGD" },
	{ value: "SLL", label: "SLL" },
	{ value: "SOS", label: "SOS" },
	{ value: "SSP", label: "SSP" },
	{ value: "SVC", label: "SVC" },
	{ value: "SZL", label: "SZL" },
	{ value: "THB", label: "THB" },
	{ value: "TTD", label: "TTD" },
	{ value: "TZS", label: "TZS" },
	{ value: "ALL", label: "ALL" },
	{ value: "UYU", label: "UYU" },
	{ value: "UZS", label: "UZS" },
	{ value: "YER", label: "YER" },
	{ value: "ZAR", label: "ZAR" },
	{ value: "BHD", label: "BHD" },
	{ value: "OMR", label: "OMR" },
	{ value: "KWD", label: "KWD" },
	{ value: "EGP", label: "EGP" },
	{ value: "QAR", label: "QAR" },
	{ value: "SAR", label: "SAR" }
];

export const featureRestrictions = {
	1: ["Email Templates", "Only 3 Emails campaigns", "Email"],
	2: [
		// Lite plan feature restrictions
		"No international ticket",
		"Only 3 Emails campaigns",
		"Referral Contest",
		"Quiz",
		"Feedback Forms",
		"Sponsor App",
		"Email Templates",
		"Event Page Templates",
		"White Label",
		"Integrations",
		"Registeration Buttons",
		"Embed Tickets",
		"Webhooks",
		"Email",
		"Payment Methods"
	]
};

export const isFeatureRestricted = (plan, featureName) => {
	if (featureRestrictions[plan]?.includes(featureName)) return true;
	return false;
};
