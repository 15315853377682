import React from "react";
import moment from "moment-timezone";

import globeIcon from "../../assets/img/common/globeIcon.svg";
import ToolTip from "./ToolTip";

const Global = ({ timeZone }) => {
	return (
		<span className="tooltipTimeZone">
			<ToolTip
				text={moment.tz(timeZone).format("z")}
				data={`Your event timezone is ${moment
					.tz(timeZone)
					.format("z")} (${moment
					.tz(timeZone)
					.format(
						"Z"
					)}). Times shown on the dashboard is respect to your event timezone. If you want to change it, you can do it by visiting "Event Info" => "Basic Info" screen.`}
			/>

			<style jsx>{`
				.tooltipTimeZone i {
					font-style: normal;
					font-size: 12px;
					text-decoration: underline;
				}

				.timezoneContainer {
					position: absolute;
					right: 5px;
				}
			`}</style>
		</span>
	);
};

export default Global;
