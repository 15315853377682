import axios from "./axios";

const API_URL = axios.defaults.baseURL;
const BASE_URL = "/event/";

export const addStripeAccount = (eventId, payload) => {
	return axios.post(API_URL + BASE_URL + eventId + "/stripe/connect", payload);
};

export const getStripeAccount = (eventId) => {
	return axios.get(API_URL + BASE_URL + eventId + "/stripe/account");
};

export const disconnectStripeAccount = (eventId) => {
	return axios.delete(API_URL + BASE_URL + eventId + "/stripe/disconnect");
};