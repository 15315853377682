import React, { useState, useEffect } from 'react'
import { addStripeAccount } from '../../revampCRUD/stripe.crud';
import { useHistory } from "react-router-dom";
import processingImage from "../../assets/processing.gif"

const StripeConnect = (props) => {
  const [loading, setLoading] = useState(false)
  const eventId = props.user.attributes["custom:lastEvent"]
  const history = useHistory();
  const code = new URLSearchParams(history.location.search).get("code")

  const addAccount = async (eventID, code) => {
    const payload = {
      authorization_code: code
    }
    try {
      setLoading(true);
      const res = await addStripeAccount(eventID, payload);
      if (res.status == 201) {
        history.push(`/e/${eventId}/payment/bankDetails`)
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      // errorToaster("Something went wrong, please try again later");
    }
  }

  useEffect(() => {
    if (code) addAccount(eventId, code)
  }, [code])

  return (
    <div className="parent">
      <div className="loading">
        <img src={processingImage} alt="" className="processing" />
        <p className="text-one text-center">Please wait while we are connecting your account to Stripe</p>
        <p className="text-two text-center">Note: Please do not refresh or close the window</p>
      </div>
      <style jsx>{`
      .parent{
        width: 100%;
        height: 100%;
        background: white;
      }
      .loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .processing{
        height: 300px;
        width: 300px;
        margin: auto;
      }
      .text-one{
        margin-top: -35px;
        font-family: 'Nunito';
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;
        color: #572148;
      }
      .text-two{
        margin-top: -5px;
        font-family: 'Nunito';
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        color: #572148;
      }
      `}</style>
    </div>
  )
}

export default StripeConnect;
