import React, { useState, useContext, useRef, useEffect } from "react";
import SidebarItem from "./SidebarItem";
import { Link, useParams } from "react-router-dom";
import navItems from "./_nav";
import Overlay from "./../../components/Overlay";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getEventCommonDetails } from "./../../revampCRUD/app.crud";
import { errorToaster, logout } from "../../reusable/common";

import logoFull from "./../../assets/img/KonfhubLogos/logo-brown.svg";
import logoShort from "./../../assets/img/konfhub-icon.svg";
import { baseUrl } from "../../config/config";
import pgConnect from "./../../assets/img/pg-connect.svg";

const SidebarItems = props => {
	const [eventDet, setEventDet] = useState(props?.prefeventinfo);
	const [isLoading, setIsLoading] = useState(false);
	const { eventId } = useParams();
	const history = useHistory();
	const eventCreatedDate = new Date(props?.prefeventinfo?.created_at);
	const today = new Date("2024-01-12");

	let totalNavItems = !eventId
		? []
		: navItems.map(navItem => {
				if (
					props.eventInfo.currency_name !== "INR" &&
					(navItem.name === "Payments & Taxes" || navItem.name === "Contact Attendees")
				) {
					if (navItem.name === "Payments & Taxes") {
						let newSubMenus = navItem.subMenus.filter(
							item => item.name !== "Payment Methods"
						);
						newSubMenus = newSubMenus.map(item =>
							item.name == "Bank Details"
								? {
										...item,
										name: "Connect your PG",
										icon: <img src={pgConnect} alt="" />
									}
								: item
						);
						navItem = { ...navItem, subMenus: newSubMenus };
					}
				}
				if (props.eventInfo.is_free && navItem.name === "Event Info") {
					const newSubMenus = navItem.subMenus.filter(item => item.name !== "Coupons");
					navItem = { ...navItem, subMenus: newSubMenus };
				}
				if (navItem.name === "Event Info" && today < eventCreatedDate) {
					const newSubMenus = navItem.subMenus.filter(item => item.name !== "Agenda"); // removing agenda from date 2024-12-01
					navItem = { ...navItem, subMenus: newSubMenus };
				}
				if (navItem.name === "Participants" && !props.prefeventinfo.team_count) {
					const newSubMenus = navItem.subMenus.filter(
						item => item.name !== "Team Registrations"
					);
					navItem = { ...navItem, subMenus: newSubMenus };
				}
				return navItem;
			});
	totalNavItems = totalNavItems.filter(navItem => {
		//updated version of nav (If userType is not 2 and event is free then settlements have to be removed, so need to update the routes)
		if (
			props.eventInfo.is_free &&
			(navItem.name === "Settlements" ||
				navItem.name === "Payments" ||
				navItem.name === "Payments & Taxes")
		)
			return false;
		else if (props.eventInfo.currency_name !== "INR" && navItem.name === "Settlements")
			return false;
		else return true;
	});

	// totalNavItems = totalNavItems.filter(navItem => {
	//     if(props.eventInfo.is_free && navItem.name === "Payments") return false;
	// })

	const navRef =
		totalNavItems.length > 0 ? useRef([...Array(totalNavItems.length - 1).keys()]) : null;

	const sidebarIsOpen = useSelector(state => state.sidebarIsOpen);
	// const sidemenuIsOpen = useSelector(state => state.sidebarIsOpen);
	const setSidebarIsOpen = useDispatch();
	const sidebarRef = useRef("");
	const getSideItemClass = i => {
		if (navRef !== null) {
			if (navRef.current[i] && navRef.current[i].parentElement) {
				return navRef.current[i].parentElement.className;
			} else return " ";
		}
	};

	const sideMenuHandler = (selectedIndex, navItem) => {
		if (navRef !== null) {
			if (sidebarIsOpen) {
				navRef.current.map((navItem, index) => {
					if (index === selectedIndex)
						navRef.current[index].parentElement.classList.toggle("showMenu");
					else navRef.current[index].parentElement.classList.remove("showMenu");
				});
			}
		}
	};

	// this will be used for sidebar item and submenu item component to pass the count of participants
	const getDetails = async () => {
		setIsLoading(true);
		try {
			const res = await getEventCommonDetails(eventId);
			setEventDet(res.data);
		} catch (err) {
			console.log(err);
			errorToaster("Something went wrong, could not refresh count");
		}
		setIsLoading(false);
	};

	return (
		<>
			{/* <i className="fa fa-bars hamburger-munu" aria-hidden="true" onClick={sidebarHandler}></i> */}
			{totalNavItems.length > 0 && (
				<>
					<div
						id="sidebar"
						ref={sidebarRef}
						className={`sidebar ${sidebarIsOpen ? "" : "closed"}`}
					>
						<div className="logo-details">
							<a href={baseUrl} target="_blank">
								<img
									src={sidebarIsOpen ? logoFull : logoShort}
									className={sidebarIsOpen ? "" : "w-50 d-block mx-auto"}
									title="KonfHub"
								/>
							</a>
						</div>
						<ul className="nav-links scrollbar-stable">
							{totalNavItems.map((navItem, i) => (
								<SidebarItem
									key={navItem.name}
									ref={el => {
										navRef.current[i] = el;
									}}
									navItem={navItem}
									index={i}
									eventId={eventId}
									sideMenuHandler={sideMenuHandler}
									pathname={history.location.pathname}
									eventCommonDetails={eventDet}
									reloadDetails={getDetails}
									isLoading={isLoading}
									className={getSideItemClass(i)}
									eventInfo={props.eventInfo}
								/>
							))}
							<div className="logout-btn w-100 justify-content-end">
								<button
									className="sidebar-btn"
									onClick={() => setSidebarIsOpen({ type: "toggle" })}
								>
									<i className="fa fa-chevron-right" aria-hidden="true"></i>
								</button>
							</div>
						</ul>
					</div>
					{sidebarIsOpen && (
						<Overlay zIndex={99} onClick={() => setSidebarIsOpen({ type: "toggle" })} />
					)}
				</>
			)}
			<style>{`
				.sidebar {
					background-color: ${localStorage.getItem("darkMode") ? "#2f2f2f" : "auto"};
					color: ${localStorage.getItem("darkMode") ? "#FFF !important" : "auto"};
				}

				.sidebar li .link_name {
					color: ${localStorage.getItem("darkMode") ? "#FFF !important" : "auto"};
				}

				.sidebar li.selected .link_name {
					color: ${localStorage.getItem("darkMode") ? "#2f2f2f !important" : "auto"};
				}
			`}</style>
		</>
	);
};

export default React.memo(SidebarItems);
