import React, { useEffect, useState } from "react";
import { Spinner } from "react-rainbow-components";
import TippyIcon from "../TippyIcon";

const CountBadge = ({ type, count, onReload, className, isLoading }) => {
	const defaultClasses = onReload
		? `tag greenStatus count-reload-badge`
		: `tag greenStatus`;

	useEffect(() => {
		if (count === "")
			throw Error(
				"The value you have given is an empty string, please add a valid value"
			);
	}, []);

	return (
		<>
			{count !== "" && (
				<span
					className={
						className ? `${defaultClasses} ${className}` : `${defaultClasses}`
					}
					onClick={onReload ? () => onReload() : ""}>
					{isLoading ? (
						<Spinner size="x-small" type="arc" variant="brand" />
					) : (
						<>
							<p className="badge-count m-0">{count}</p>
							{onReload && (
								<TippyIcon content="Refresh Count" placement="bottom">
									<i className="fas fa-redo reload-icon"></i>
								</TippyIcon>
							)}
						</>
					)}
				</span>
			)}
			<style>{`

            .reload-icon {
                opacity: 0;
                position: absolute;
                transform: scale(0.3);
                transition: opacity 0.1s, transform 0.2s !important;
            }

            .count-reload-badge {
                position: relative;
                width: auto;
                height: 28px;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: 0.2s all;
              }
              
              .count-reload-badge:hover {
                transform: scale(1.2);
                background: white;
                border-radius: 6px;
              }
              
              .count-reload-badge:hover .reload-icon {
                color: #572148 !important;
                transform: scale(1);
                opacity: 1 !important;
              }
              .count-reload-badge:hover .badge-count {
                color: #572148 !important;
                visibility: hidden !important;
              }

              .badge-count {
                  position: relative;
              }
        `}</style>
		</>
	);
};

export default CountBadge;
