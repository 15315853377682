import React, { useState, useEffect } from "react";
import { Spinner } from "react-rainbow-components";

const KonfhubButton = ({
	isLoading,
	btnType,
	disabled,
	formik,
	onClick,
	className,
	style,
	size,
	...props
}) => {
	const isDisabled = () => {
		if (disabled) {
			return true;
		} else if (!disabled) {
			return false;
		} else if (isLoading) {
			return true;
		} else if (formik) {
			if (formik.isValid && !formik.dirty) return true;
			else return false;
		} else return false;
	};

	const getClassName = () => {
		let newClassName = "";

		if (btnType === "secondary") newClassName = "k-btn cancel-btn";
		else if (btnType === "tertiary") newClassName = "k-btn secondary-btn";
		else if (btnType === "tertiaryWhite") newClassName = "k-btn secondary-btn secondary-btn-white";
		else if (btnType === "secondaryWhite") newClassName = "k-btn cancel-btn cancel-btn-white";
		else if (btnType === "gradient") newClassName = "k-btn gradient-btn";
		else newClassName = "k-btn save-btn";

		if (className) newClassName = `${newClassName} ${className}`;

		if(size) newClassName += ` ${size}`

		return newClassName;
	};

	return (
		<>
			<button
				type="button"
				style={style}
				className={`${getClassName()} position-relative`}
				disabled={isDisabled()}
				onClick={() => onClick()}>
				{isLoading ? (
					<div className="spinner-btn-container">
						<Spinner
							className={`${
								btnType === "secondary" ? "purple-spinner" : "white-spinner"
							}`}
							size="x-small"
							type="arc"
							variant="brand"
						/>
					</div>
				) : (
					props.children
				)}
			</button>
			<style>{`
				.purple-spinner > svg > circle {
					stroke: #572148 !important;
				}

				.white-spinner > svg > circle {
					stroke: white !important;
				}

				.purple-spinner,
				.white-spinner {
					// position: relative !important;
				}

				.spinner-btn-container {
					width: 20px;
					height: 22px;
				}
			`}</style>
		</>
	);
};

export default KonfhubButton;
