import React, { Suspense, useContext, useEffect, useState } from "react";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import { errorToaster, logout } from "./../reusable/common";
// import Loading from './../components/Loading';
import AllRoutes from "./Routes";
import AuthContext from "./../contexts/AuthContext";
import KonfhubLoad from "../components/KonfhubLoad";
// import EventContext from "./../contexts/EventContext";
import DashboardRoutes from "./DashboardRoutes";
import OtherRoutes from "./OtherRoutes";
import CommunityRoutes from "./CommunityRoutes";
import { baseUrl } from "../config/config";
import StripeConnect from "../revampPages/StaticPage/StripeConnect";
import ManageEvents from "../revampPages/ManageEvents/ManageEvents";

const Page404 = React.lazy(() => import("./../pages/Page404"));
// const ProfileLayout = React.lazy(() => import('./../containers/ProfileLayout'));

export default function Routes(props) {
	const Context = useContext(AuthContext);

	const getRedirectUrl = () => {
		// let lastEvent = Context.user.attributes["custom:lastEvent"]
		// let previousEventId = lastEvent ? lastEvent : false;
		// if(Context.organiserDetails.length > 0) {
		// 	if(previousEventId) return `/e/${previousEventId}/`
		// 	else return `/e/${Context.organiserDetails[0].event_id}/`
		// } else {
		// 	return `/create/event`
		// }
		return "/"
	}

	return (
		<Suspense fallback={<KonfhubLoad />}>
			<Switch>
				<Route
					path="/logout"
					component={props => {
						logout();
						Context.setAuth({}, false);
						// return <Redirect to="/" />;
						window.location.href = baseUrl;
						//return <></>
					}}
				/>
				<Route
					exact
					path="/"
					>
					<ManageEvents {...props} />
				</Route>
				<Route
					path="/my-community">
					<CommunityRoutes {...props}/>
				</Route>
				<Route
					path="/create">
					<OtherRoutes {...props}/>
				</Route>
				<Route
					path="/e/:eventId/">
					<DashboardRoutes {...props}/>
				</Route>
				<Route
    				path="/connect-stripe"
				>
					<StripeConnect {...props} />
				</Route>
				{/*Default Route*/}
				<Route component={Page404} />
			</Switch>
		</Suspense>
	);
}
