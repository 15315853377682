import React, { useEffect } from "react";
import Auth from "@aws-amplify/auth";
import { setAccessToken, setJwtToken } from "./../revampCRUD/axios";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import moment from "moment";
import TickImage from "./TickImage";

toast.configure();

//quiz
export const ADMIN_EMAIL = "olympus@codeops.tech"; //'manjunath.r@knownshare.in';
export const EMPTY_GUID = "00000000-0000-0000-0000-000000000000";
export const OWNER = "owner";
export const COLLABORATOR = "collaborator";
export const READER = "reader";
export const SOFT_DELETED = "soft-deleted";
export const ENDED = "ended";
export const ABANDONED = "abandoned";
export const PENDING = "pending";
export const STARTED = "started";
export const REGULAR = "regular";

export const accessType = [
	{ value: 1, label: "Collaborator (All Access - Same as Owner)" },
	{ value: 2, label: "Reader (Limited Access - View, No Edit & No Delete)" }
];

export const pgMethods = {
	1: "RAZORPAY",
	2: "STRIPE",
	3: "OPENBANK",
	4: "PHONEPE",
	5: "TAP"
};

export const getStatus = (startDateTime, endDateTime, status = "", mode = "") => {
	if (status === "pending") return "Upcoming";
	if (status === "started") return "Live";
	if (status === "ended" || status === "abandoned") return "Expired";
	if (status === "soft-deleted") return "Deleted";
	if (moment().isAfter(moment.utc(endDateTime).local())) return "Expired";
	if (moment().isBetween(moment.utc(startDateTime).local(), moment.utc(endDateTime).local()))
		return "Live";
	else return "Upcoming";
};

export const makeCSV = content => {
	let csv = "";
	content.forEach(value => {
		value.forEach((item, i) => {
			const innerValue = !item ? "" : item.toString();
			let result = innerValue.replace(/"/g, '""');
			if (result.search(/("|,|\n)/g) >= 0) {
				result = '"' + result + '"';
			}
			if (i > 0) {
				csv += ",";
			}
			csv += result;
		});
		csv += "\n";
	});
	return csv;
};

export const logout = async () => {
	try {
		localStorage.removeItem("jwtToken");
		localStorage.removeItem("userEmail");
		localStorage.removeItem("userName");
		localStorage.removeItem("user");
		localStorage.removeItem("eventId");
		setJwtToken("");
		setAccessToken("");
		// await Auth.signOut();
		await Auth.signOut({ global: true });
	} catch (err) {
		console.log(err);
	}
};

export const successToaster = (message, autoClose = 5000, id = false) => {
	toast.success(message, {
		position: "top-right",
		autoClose: autoClose,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		...(id && { toastId: id })
	});
};

export const errorToaster = (message, autoClose = 5000, id = false) => {
	toast.error(message, {
		position: "top-right",
		autoClose: autoClose,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		...(id && { toastId: id })
	});
};

export const clickOutside = (ref, onClickOutside) => {
	// Function to close dropdown when clicked anywhere outside
	useEffect(() => {
		/**
		 * Invoke Function onClick outside of element
		 */
		function handleClickOutside(event) {
			if (ref?.current && !ref?.current?.contains(event.target)) {
				onClickOutside();
			}
		}
		if (ref?.current) {
			// Bind
			document.addEventListener("mousedown", handleClickOutside);
			return () => {
				// dispose
				document.removeEventListener("mousedown", handleClickOutside);
			};
		}
	}, [ref, onClickOutside]);
};

// A custom hook that builds on useLocation to parse the query string for you.
export function useQuery() {
	const { search } = useLocation();

	const params = Object.fromEntries(React.useMemo(() => new URLSearchParams(search), [search]));

	return params;
}

export const regexMethods = {
	//This is used for certaing regex operation
	allowOnlyPositiveNumber: evt => {
		const charCode = String.fromCharCode(evt.which);
		if (!/[0-9]/.test(charCode)) {
			evt.preventDefault();
		}
		return;
	},
	allowAlphaNumeric: evt => {
		const charCode = String.fromCharCode(evt.which);
		if (!/^[a-z0-9-]+/i.test(charCode)) {
			evt.preventDefault();
		}
		return;
	},
	allowAlphaNumericAndSpecial: evt => {
		//alphanumeric and accpet special characters :- @, &, #, $, !, (, ), *
		const charCode = String.fromCharCode(evt.which);
		if (!/^[a-zA-Z0-9@&#$!()*-]+$/.test(charCode)) {
			evt.preventDefault();
		}
		return;
	},
	noSpaces: evt => {
		const charCode = String.fromCharCode(evt.which);
		if (!/^\S*$/.test(charCode)) {
			evt.preventDefault();
		}
		return;
	},
	numbersAnsDecimal: evt => {
		const charCode = String.fromCharCode(evt.which);
		if (!/^\d*\.?\d*$/.test(charCode)) {
			evt.preventDefault();
		}
		return;
	}
};

export const paymentMethods = payment_method => {
	const PAYMENT_METHOD_TYPES = {
		1: "UPI",
		2: "Cards", //Card rzp
		3: "Net Banking",
		4: "Cards-stripe",
		5: "Wallet",
		6: "Stripe-pg",
		7: "Tap"
	};
	return PAYMENT_METHOD_TYPES[payment_method];
};

export function replaceAll(string, search, replace) {
	// replaceAll alternative as replaceAll doesnt work while deploying
	return string.split(search).join(replace);
}

export const silverPlanFeaturesDomestic = [
	<li>
		<TickImage />
		Upto 2500 Attendees per Event **
	</li>,
	<li>
		<TickImage />
		Supports All Available Payment Methods
	</li>,
	<li>
		<TickImage />
		Non-INR Tickets are supported at <b>5% + GST</b> per Ticket
	</li>,
	<li>
		<TickImage />
		Approval Based Registrations
	</li>,
	<li>
		<TickImage />
		Group Discounts
	</li>,
	<li>
		<TickImage />
		Ticket Categories
	</li>,
	<li>
		<TickImage />
		Hidden Tickets
	</li>,
	<li>
		<TickImage />
		Customized Emails (with KonfHub Headers & footers){" "}
	</li>,
	<li>
		<TickImage />
		Upto 10 Email Campaigns to Participants (KonfHub Headers & Footers)
	</li>,
	<li>
		<TickImage />
		Automated Referral Contests
	</li>,
	<li>
		<TickImage />3 Event Page Templates
	</li>,
	<li>
		<TickImage />
		QuizHub Free Version with 100 Live Participants per Quiz (Limited)
	</li>,
	<li>
		<TickImage />
		Collect Audience Feedback and issue Standard Certificates
	</li>,
	<li>
		<TickImage />
		Webhook
	</li>,
	<li>
		<TickImage />
		Developer Integrations (Registration API, Registration Button, Widget)
	</li>,
	<li>
		<TickImage />
		Check-in App
	</li>,
	<li>
		<TickImage />
		Sponsor App
	</li>,
	<li>
		<TickImage />
		Phone & Email Support (Working Days - 10 am to 7 pm IST)
	</li>
];
export const LitePlanFeaturesDomestic = [
	<li>
		<TickImage />
		Upto 300 Attendees per Event **
	</li>,
	<li>
		<TickImage />
		Supports Only UPI Payment Method
	</li>,
	<li>
		<TickImage />
		Analytics Dashboard
	</li>,
	<li>
		<TickImage />
		Unlimited Discount Codes
	</li>,
	<li>
		<TickImage />
		Team-Based Registrations
	</li>,
	<li>
		<TickImage />
		Waitilisted Attendees
	</li>,
	<li>
		<TickImage />
		Bulk Upload Attendees (100 max)
	</li>,
	<li>
		<TickImage />
		Upto 3 Email Campaigns to Participants (KonfHub Headers & Footers)
	</li>,
	<li>
		<TickImage />
		Check-in App (Basic Features)
	</li>,
	<li>
		<TickImage />
		Email Support (Working Days - 10 am to 7 pm IST)
	</li>
];

export const GoldPlanFeaturesDomestic = [
	<li>
		<TickImage />
		Upto 5000 Attendees per Event **
	</li>,
	<li>
		<TickImage />
		GST (Tax) Invoices{" "}
	</li>,
	<li>
		<TickImage />
		Listing in Featured Events
	</li>,
	<li>
		<TickImage />
		Customized Emails (Custom Headers & Footers){" "}
	</li>,
	<li>
		<TickImage />
		Unlimited Email Campaigns (Custom / Branded Headers & Footers)
	</li>,
	<li>
		<TickImage />
		Bring Your Own Email ID & Sender ID
	</li>,
	<li>
		<TickImage />
		Whitelabeling: Logo & Branding Changes
	</li>,
	<li>
		<TickImage />
		Collect Audience Feedback & issue Customized Certificates
	</li>,
	<li>
		<TickImage />
		Zaiper Integration
	</li>,
	<li>
		<TickImage />
		Priority Support over Phone & Email Support (Working Days - 10 am to 7 pm IST)
	</li>
];

export const silverPlanFeaturesInternational = [
	<li>
		<TickImage />
		Upto 2500 Registrations per Paid Event
	</li>,
	<li>
		<TickImage />
		Approval based registrations
	</li>,
	<li>
		<TickImage />
		Group discounts
	</li>,
	<li>
		<TickImage />
		Ticket categories
	</li>,
	<li>
		<TickImage />
		Hidden Tickets
	</li>,
	<li>
		<TickImage />
		Customized Emails (with KonfHub headers & footers){" "}
	</li>,
	<li>
		<TickImage />
		Upto 10 Email Campaigns to Participants (KonfHub Headers & Footers)
	</li>,
	<li>
		<TickImage />
		Automated Referral Contests
	</li>,
	<li>
		<TickImage />3 Event Page Templates
	</li>,
	<li>
		<TickImage />
		QuizHub Free Version with 100 Live Participants per Quiz (Limited)
	</li>,
	<li>
		<TickImage />
		Collect Audience Feedback and issue Standard Certificates
	</li>,
	<li>
		<TickImage />
		Webhook
	</li>,
	<li>
		<TickImage />
		Developer Integrations (Registration API, Registration Button, Widget)
	</li>,
	<li>
		<TickImage />
		Check-in App
	</li>,
	<li>
		<TickImage />
		Sponsor App
	</li>,
	<li>
		<TickImage />
		Phone & Email Support (Working Days - 10am to 7pm IST)
	</li>
];
export const LitePlanFeaturesInternational = [
	<li>
		<TickImage />
		Upto 300 Attendees per Event **
	</li>,
	<li>
		<TickImage />
		Analytics Dashboard
	</li>,
	<li>
		<TickImage />
		Unlimited Discounts Codes
	</li>,
	<li>
		<TickImage />
		Team-based Registrations
	</li>,
	<li>
		<TickImage />
		Waitilisted Attendees
	</li>,
	<li>
		<TickImage />
		Bulk Upload Attendees (100 max)
	</li>,
	<li>
		<TickImage />
		Upto 3 Email Campaigns to Participants (KonfHub Headers & Footers)
	</li>,
	<li>
		<TickImage />
		Check-in App (Basic Features)
	</li>,
	<li>
		<TickImage />
		Email Support (Working Days - 10am to 7pm IST)
	</li>
];

export const GoldPlanFeaturesInternational = [
	<li>
		<TickImage />
		Upto 5000 Attendees per Event
	</li>,
	<li>
		<TickImage />
		Tax Invoices{" "}
	</li>,
	<li>
		<TickImage />
		Listing in Featured Events
	</li>,
	<li>
		<TickImage />
		Customized Emails (Custom headers & footers)
	</li>,
	<li>
		<TickImage />
		Unlimited Email Campaigns (Custom/branded headers & footers) Bring Your Own Email ID &
		Sender ID
	</li>,
	<li>
		<TickImage />
		Whitelabeling: Logo & branding changes{" "}
	</li>,
	<li>
		<TickImage />
		Collect Audience Feedback & issue Customized Certificates
	</li>,
	<li>
		<TickImage />
		Zaiper Integration
	</li>,
	<li>
		<TickImage />
		Priority Support over Phone & Email (Working Days - 10am to 7pm IST)
	</li>
];

export const restrictedLitePages = [
	"Payement Method",
	"No international ticket",
	"Only 3 Emails campaigns",
	"Referral Contest",
	"Quiz",
	"Feedback Forms",
	"Check-in App",
	"Sponsor App",
	"Email Templates",
	"Event Page Templates",
	"White Label",
	"Integrations",
	"Registeration Buttons",
	"Embed Tickets",
	"Webhooks"
];
