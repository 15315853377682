import { getCurrentEventId } from "../Utils/helpers";
import axios from "./axios";
import { api } from "./axios.config";

const API_URL = api.checkinAppUrl;
export const getCheckedInUsers = () => {
	const eventId = getCurrentEventId();
	const PAYLOAD = {
		query: `
			query{
                getAttendeesForEvent(eventId: "${eventId}", checkInStatus: "true") {
                    attendeeDetails {
                      bookingId
                      checkInTime
                      checkInStatus
                      countryCode
                      designation
                      dialCode
                      emailId
                      eventId
                      id
                      name
                      organisation
                      phoneNumber
                      status
                      ticketId
                      ticketName
                    }
                    nextToken
                  }                
			}
		`
	};
	return axios.post(API_URL, PAYLOAD);
};

export const downloadCheckedInUsersInUsers = () => {
	const eventId = getCurrentEventId();
	const PAYLOAD = {
		query: `
			query{
        downloadAttendees(eventId: "${eventId}", checkInStatus: "true")              
			}
		`
	};
	return axios.post(API_URL, PAYLOAD);
};

export const resetCheckinData = accessCode => {
	const event_Id = getCurrentEventId();
	const PAYLOAD = {
		query: `
			mutation MyMutation {
				clearEventStatuses(accessCode: "${accessCode}", eventId: "${event_Id}", resetCheckInStatus: true)
			}`
	};
	return axios.post(API_URL, PAYLOAD);
};

export const getListOfRegistrationStaff = () => {
	const event_Id = getCurrentEventId();
	const PAYLOAD = {
		query: `
      query MyQuery {
        getAppUsers(approvedList: true, eventId: "${event_Id}")
      }`
	};
	return axios.post(API_URL, PAYLOAD);
};

export const getSettings = accessCode => {
	const event_Id = getCurrentEventId();
	const PAYLOAD = {
		query: `
      query MyQuery {
	getAppSettings(accessCode: "${accessCode}", eventId: "${event_Id}")
  }`
	};
	return axios.post(API_URL, PAYLOAD);
};

export const updateSettings = (accessCode, settingsArr) => {
	const event_Id = getCurrentEventId();
	console.log(settingsArr);
	const PAYLOAD = {
		query: `
      mutation MyMutation {
    updateSettings(accessCode: "${accessCode}", eventId: "${event_Id}", enableApprovalFlow: ${settingsArr.enableApprovalFlow}, enableGoodies: ${settingsArr.enableGoodies}, printCardStatus: ${settingsArr.printCardStatus}, enableDoubleSidedPrint: ${settingsArr.enableDoubleSidedPrint})
  }`
	};
	return axios.post(API_URL, PAYLOAD);
};
