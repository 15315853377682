import React, { useState, useEffect, useRef, useCallback } from "react";
import _, { filter } from "lodash";
import KonfhubButton from "../Buttons/KonfhubButton";
import { clickOutside, pgMethods } from "../../../reusable/common";
import { getTickets } from "../../../revampCRUD/ticketsNforms.crud";
import { getEventDetails } from "../../../revampCRUD/eventDetails.crud";
import LoadingSpinner from "../Loading";
import FilterBadge from "../Badge/FilterBadge";
import { organisations } from "../../../config/organisations";
import SearchBar from "../SearchBar";
import Filter from "../../../assets/img/Filter.svg";
import { getTeamRegistrations } from "../../../revampCRUD/Participants/TeamRegistrations.crud";
import { getCoupons } from "../../../revampCRUD/copounNcharges.crud";
import customToast from "../../../components/common/basic/Toast";
import { getListOfRegistrationStaff } from "../../../revampCRUD/check-in.crud";

const emirateOptions = [
	"Abu Dhabi",
	"Ajman",
	"Dubai",
	"Fujairah",
	"Ras Al Khaimah",
	"Sharjah",
	"Umm Al Quwain",
];

const DataTableFilter = ({
	tempData,
	getDataFunction,
	setFilterParams,
	filterParams,
	setSelectedFilters,
	heading,
	eventId,
	perPage,
	search,
	filterToRemove,
	setFilterToRemove,
	view,
	showFilterDropdown = true
}) => {
	const AhlanModeEvents = [
		"3665a374-b755-43d3-9785-3957bf9674f4",
		"c2c3c899-9b66-4952-b93e-8fc0bd0d2103",
		"62d717ba-8782-4d32-930e-77e1c70de8da",
		"00c167cb-9164-4993-9650-a9cc6bbbc04a",
		"de611c9d-3bab-4ec8-b456-47a680853f2f",
		"9e9f42e0-718d-44e2-901e-747152eb570c",
		"d70fe8b0-8665-4e91-b704-6b54a3ee6ad7",
	];

	const peopleofDeterminationFormId = {
		[eventId]: "00000",
		"3665a374-b755-43d3-9785-3957bf9674f4": "142913",
		"c2c3c899-9b66-4952-b93e-8fc0bd0d2103": "31037",
		"62d717ba-8782-4d32-930e-77e1c70de8da": "31882",
		"00c167cb-9164-4993-9650-a9cc6bbbc04a": "142710",
		"de611c9d-3bab-4ec8-b456-47a680853f2f": "143499",
		"d70fe8b0-8665-4e91-b704-6b54a3ee6ad7": "32080",
	};

	const emiratesFormId = {
		[eventId]: "00000",
		"c2c3c899-9b66-4952-b93e-8fc0bd0d2103": "31031",
		"62d717ba-8782-4d32-930e-77e1c70de8da": "31886",
		"d70fe8b0-8665-4e91-b704-6b54a3ee6ad7": "31033",
		"6b4e0831-6c04-47e8-9e4b-2478c44261a3": "139314",
		"00c167cb-9164-4993-9650-a9cc6bbbc04a": "142713",
		"22da35ea-b796-4560-9b0a-9a419289a967": "141261",
		"de611c9d-3bab-4ec8-b456-47a680853f2f": "143502",
	};

	const [dropActive, setDropActive] = useState(false); // To open and close filter drop down
	const [selTab, setSelTab] = useState(
		heading === "Attendees"
			? 0
			: heading === "Waitlist"
				? 2
				: heading === "Approval Based Registration"
					? 3
					: heading.includes("Uploaded On")
						? 0
						: 4,
	); // Filter dropdown tabs
	const initialFilterValues = {
		registration_status: [],
		refund_type: [],
		ticket_ids: [],
		check_in_by_email: [],
		coupon_codes: [],
		waitlist_status: [],
		check_in_status: [],
		[peopleofDeterminationFormId[eventId]]: [],
		organisation: [],
		[emiratesFormId[eventId]]: [],
		currencies: [],
		pg_type: [],
		team_id: [],
	};
	let [filterParamsObj, setFilterParamsObj] = useState(initialFilterValues); // Filter state
	const [isFilterRemoved, setIsFilterRemoved] = useState(false); //To trigger whenever a filter is removed via filter badges (not via uncheck)
	const [tickets, setTickets] = useState(false); //get ticket data
	const [checkInAppStaffs, setCheckInAppStaffs] = useState([]); //get checkin app staffs
	const [InitialLoaded, setInitialLoaded] = useState(false);
	const [filteredOrganisations, setFilteredOrganisations] =
		useState(organisations);
	const [currencies, setCurrencies] = useState([]);
	const [pg_type, setPg_type] = useState([]);
	const [teamsList, setTeamsList] = useState(false);
	const [hasTeams, setHasTeams] = useState(false); // for tab
	const [tempValue, setTempValue] = useState(null);
	const [ticketsSearch, setTicketsSearch] = useState([]);
	const [teamsIsLoading, setTeamsIsLoading] = useState(false);

	const wrapperRef = useRef("");

	const searchOrganisation = (searchTerm) => {
		const newFilteredOrganisations = organisations.filter((org) =>
			org.toLowerCase().includes(searchTerm.toLowerCase()),
		);
		setFilteredOrganisations([...newFilteredOrganisations]);
	};

	// Custom hook to close dropdown whenever clicked outside while drop down is open
	clickOutside(wrapperRef, () => {
		setDropActive(false);
	});

	const getTicketsData = async () => {
		try {
			const res = await getTickets(eventId);
			const currencies = [];
			const newTicketDet = res.data.uncategorized.map((ticket) => {
				currencies.push(ticket.currency_name);
				return { ...ticket, checked: false };
			});
			res.data.categorized.forEach((category) => {
				category.tickets.forEach((ticket) => {
					currencies.push(ticket.currency_name);
					newTicketDet.push({ ...ticket, checked: false });
				});
			});
			setTickets(newTicketDet);
			setTicketsSearch(newTicketDet)
			setCurrencies([...new Set(currencies)]);
		} catch (err) {
			setTickets(false);
			setTicketsSearch(false)
		}
	};
	const getTeamsData = async (searchText = "") => {
		setTeamsIsLoading(true)
		try {
			const res = await getTeamRegistrations(eventId, 0, 0, searchText);
			setTeamsList(res.data.team_details);
			if (res.data.team_details.length > 0) setHasTeams(true)
			setTeamsIsLoading(false)
		} catch (err) {
			setTeamsList(false);
			setTeamsIsLoading(false)
		}
	};

	const getEventData = async () => {
		try {
			const res = await getEventDetails(eventId);
			const pg_type = [];
			if (res.data.event_payment_methods) {
				res.data.event_payment_methods.forEach((pg) => {
					if (pg === 1 || pg === 2 || pg === 3 || pg === 5) {
						if (!pg_type.includes(1)) pg_type.push(1);
					}
					if (pg === 4 || pg === 6) {
						if (!pg_type.includes(2)) pg_type.push(2);
					}
					if (pg === 7) {
						if (!pg_type.includes(5)) pg_type.push(5);
					}
				});
			}
			setPg_type(pg_type);
		} catch (err) {
			setPg_type([]);
		}
	};

	const getCheckedInAppStaff = async() => {
		try {
			const tempResult = await getListOfRegistrationStaff();
			const list = [...JSON.parse(tempResult.data.data.getAppUsers).body]
			setCheckInAppStaffs(list);
		} catch (error) {
			customToast.error(error.message);
		}
	}

	//Function which is called whenever a filter is added or removed
	const handleCheck = (id, isTicketIds = false, isFilterBadge = false) => {
		let referenceObj = {
			//this is used as a reference obj to add selected filters in the selectedFilters state
			registration_status: { 2: "Active Tickets", 3: "Cancelled Tickets" },
			refund_type: { 2: "Complete Refund", 1: "Partial Refund" },
			waitlist_status: {
				1: "Pending",
				2: "Approved",
				3: "Rejected",
				5: "Pending",
				6: "Approved",
				7: "Rejected",
				9: "On Hold",
			},
			ticket_ids: {},
			coupon_codes: {},
			check_in_by_email: {},
			check_in_status: { true: "True", false: "False" },
			[peopleofDeterminationFormId[eventId]]: { true: "Yes", false: "No" },
			organisation: filteredOrganisations,
			[emiratesFormId[eventId]]: emirateOptions,
			currencies,
			pg_type,
			team_id: {},
		};

		if (tickets) {
			//If tickets api is finished loading and data is recieved
			let newTicketIdObj = {}; //this will be an object with ticket id as key and ticket name as value
			tickets.map((ticket) => {
				newTicketIdObj[ticket.ticket_id] = ticket.ticket_name;
			});
			referenceObj["ticket_ids"] = newTicketIdObj; //Add all tickets with ticket id and name to refObject
		}
		if (teamsList.length) {
			const newTeamIdObj = {};
			teamsList.map((team) => {
				newTeamIdObj[team.team_id] = team.team_name;
			});
			referenceObj["team_id"] = newTeamIdObj;
		}

		if (pg_type.length) {
			let newPgMethodObj = {};
			pg_type.map((pg) => {
				newPgMethodObj[pg] = pgMethods[pg];
			});
			referenceObj["pg_type"] = newPgMethodObj;
		}
		if(id.includes("coupon_codes")){
			const regex = /coupon_codes-(\w+)$/;
			const match = id.match(regex);
			const clickedCouponId = match[1];
			const newCouponCodeObj = {}; //this will be an object with coupon code as key and coupon code as value
			newCouponCodeObj[clickedCouponId] = `${clickedCouponId}`;
			referenceObj.coupon_codes = newCouponCodeObj; //Add 
		}
		if(id.includes("email_ids")){
			//If checkin checkin app staff api is finished loading and data is recieved
			const newEmailIdObj = {}; //this will be an object with checkin app staff email as key and staff name as value
			checkInAppStaffs.map((each) => {
				newEmailIdObj[each.userEmail] = each.name;
			});
			referenceObj.check_in_by_email = newEmailIdObj; //Add all checkin app staffs with email and name to refObject
		}

		let newFilterParamsObj = {}; //this will be the new filterParamsObj state

		const removeFilterParam = (statusNo, filterType) => {
			newFilterParamsObj = { ...filterParamsObj };
			delete newFilterParamsObj[filterType][statusNo];
			setFilterParamsObj(newFilterParamsObj);
		};
		if (!isTicketIds) {
			//if filter type is not tickets (that includes registration_status, refund_type & waitlist_status)
			let filterType = id.split("-")[0]; //The type -> eg: if id is registration_status-1 this value will be registration_status
			let statusNo = id.split("-")[1]; //The status number -> eg: if id is registration_status-1 this value will be 1 which is status number for active attendees

			if (
				filterType === "organisation" ||
				filterType === emiratesFormId[eventId] ||
				filterType === "currencies"
			) {
				if (filterParamsObj[filterType].hasOwnProperty(`${statusNo}`))
					removeFilterParam(statusNo, filterType);
				else {
					newFilterParamsObj = {
						...filterParamsObj,
						[filterType]: {
							...filterParamsObj[filterType],
							[statusNo]: statusNo,
						},
					};
					setFilterParamsObj(newFilterParamsObj);
				}
			} else {
				if(id.includes("email_ids")){
					const filterType = "check_in_by_email";
					const match1 = id.includes("&name") ? id?.split("&")[0] : id;
					const match2 = id.includes("&name") ? id?.split("&")[1] : "";
					const clickedEmailId = match1?.split("email_ids-")[1];
					const name = match2.includes("name-") ? match2?.split("name-")[1] : "";
					if (filterParamsObj[filterType].hasOwnProperty(`${clickedEmailId}`) && !name){
						removeFilterParam(clickedEmailId, "check_in_by_email");
						//if email id present remove
					}
					else {
						newFilterParamsObj = {
							...filterParamsObj,
							check_in_by_email: {
								...(filterParams.includes("&name")) && selTab !== 12 ? initialFilterValues.check_in_by_email : filterParamsObj.check_in_by_email,
								[clickedEmailId]: name ? name : referenceObj.check_in_by_email[clickedEmailId],
							},
						};
						filterParamsObj = newFilterParamsObj; // directly mutating the obj for faster performace
						setFilterParamsObj(newFilterParamsObj); //if ticket id not present add to ticket_ids
					}
				}
				else if(id.includes("coupon_codes")){
					const filterType = "coupon_codes";
					const regex = /coupon_codes-(\w+)$/;
					const match = id.match(regex);
					const clickedTicketId = match[1]; // get coupon code from input
					if (filterParamsObj[filterType].hasOwnProperty(`${clickedTicketId}`))
						removeFilterParam(clickedTicketId, "coupon_codes");
					//if coupon code present remove
					else {
						newFilterParamsObj = {
							...filterParamsObj,
							coupon_codes: {
								...filterParamsObj.coupon_codes,
								[clickedTicketId]: referenceObj["coupon_codes"][clickedTicketId],
							},
						};
						filterParamsObj = newFilterParamsObj; // directly mutating the obj for faster performace
						setFilterParamsObj(newFilterParamsObj); //if coupon code not present add to coupon_codes
					}
				} else {
					if (filterParamsObj[filterType].hasOwnProperty(`${statusNo}`)) {
						removeFilterParam(statusNo, filterType);
					}
					else {
						newFilterParamsObj = {
							...filterParamsObj,
							[filterType]: {
								...filterParamsObj[filterType],
								[statusNo]: referenceObj[filterType][statusNo],
							},
						};
						filterParamsObj = newFilterParamsObj; //mutate directly
						setFilterParamsObj(newFilterParamsObj);
					}
				}
			}
		} else {
			let filterType = id.split("-")[0];
			let clickedTicketId = parseInt(id.split("-")[1]); // get ticket id from input
			if (filterParamsObj[filterType].hasOwnProperty(`${clickedTicketId}`))
				removeFilterParam(clickedTicketId, "ticket_ids");
			//if ticket id present remove
			else {
				newFilterParamsObj = {
					...filterParamsObj,
					ticket_ids: {
						...filterParamsObj.ticket_ids,
						[clickedTicketId]: id?.split("ticket_name=")[1] || referenceObj["ticket_ids"][clickedTicketId],
					},
				};
				filterParamsObj = newFilterParamsObj; // directly mutating the obj for faster performace
				setFilterParamsObj(newFilterParamsObj); //if ticket id not present add to ticket_ids
			}
		}
		let tempValue = ``; // this will be contain the filters added as query param to attach to API call
		Object.keys(newFilterParamsObj).map((filterType) => {
			if (!_.isEmpty(newFilterParamsObj[filterType])) {
				let allStatus = [];
				if (
					filterType === "check_in_status" &&
					newFilterParamsObj[filterType].hasOwnProperty("true") &&
					newFilterParamsObj[filterType].hasOwnProperty("false")
				)
					return; //If check in status has both true and false dont add it to query (because that means all)
				Object.keys(newFilterParamsObj[filterType]).map((statusNo) => {
					allStatus.push(statusNo);
				});
				tempValue += `&${filterType}=${allStatus.join(
					encodeURIComponent("|"),
				)}`;
			}
		});
		setFilterParams(tempValue);

		if (isFilterBadge) setIsFilterRemoved(true); // This only runs if the user clicked close on the filter badges / tags
	};

	useEffect(() => {
		// Useeffect to remove filters if user has removed a selected filter badge
		if (filterToRemove) {
			handleCheck(filterToRemove[0], filterToRemove[1], filterToRemove[2]);
			setFilterToRemove(false);
		}
	}, [filterToRemove]);

	useEffect(() => {
		if (isFilterRemoved) {
			handleFilterCheck();
			setIsFilterRemoved(false);
		}
	}, [isFilterRemoved]);

	const checkInitialFilters = async () => {
		if (!filterParamsObj.ticket_ids.length && !filterParamsObj.coupon_codes.length && !filterParamsObj.check_in_by_email.length) {
			let separator = "=";
			let sepPos = filterParams.indexOf(separator);
			let filters = filterParams.substring(sepPos + 1, filterParams.length);
			const filterName = filterParams.substring(1, sepPos);

			const filterIDs = filterName === "ticket_ids" || filterName === "coupon_codes" ? filters.split(encodeURIComponent("|")) : filters.split(",");
			if (filterName === "ticket_ids") {
				filterIDs.forEach((ticketId) => {
					handleCheck(`ticket_ids-${ticketId}`, true);
				});
			}
			if (filterName === "team_id") {
				filterIDs.forEach((teamId) => {
					handleCheck(`team_id-${teamId}`);
				});
			}
			if (filterName === "coupon_codes") {
				filterIDs.forEach((couponId) => {
					handleCheck(`coupon_codes-${couponId}`);
				});
			}
			if (filterName === "check_in_by_email") {
				filterIDs.forEach((each) => {
					const email = each.split("&name=")[0];
					const name = each.split("&name=")[1];
					if(name){
						handleCheck(`email_ids-${email}&name-${name}`);
					} else {
						handleCheck(`email_ids-${email}`);
					}
				});
			}

			let newSelectedFilters = [];
			Object.keys(filterParamsObj).map((filterType) => {
				Object.keys(filterParamsObj[filterType]).map((statusNo) => {
					if (filterType !== "ticket_ids") {
						let filterObj = {
							filterType,
							statusNo,
							filterName:
								filterType === "pg_type"
									? pgMethods[filterParamsObj[filterType][statusNo]]
									: filterParamsObj[filterType][statusNo],
						};
						newSelectedFilters.push(filterObj);
					} else
						newSelectedFilters.push({
							filterType,
							statusNo,
							filterName: filterParamsObj[filterType][statusNo],
							isTicket: true,
						});
				});
			});
			setDropActive(false);
			setFilteredOrganisations(organisations);
			if(newSelectedFilters?.length > 0 && newSelectedFilters?.filter(each=>each?.filterType === "check_in_by_email")?.length > 0){ 
				// Only for check_in_by_email in checkin page filter attendee table
				if(filterParams.includes("&name")){
					const match = filterParams?.split("&name")[0];
					const email = match.split("&check_in_by_email=")[1];
					const _filterParamsObj = {...filterParamsObj};
					setFilterParamsObj({
						..._filterParamsObj,
						check_in_by_email: {
							[email]: _filterParamsObj.check_in_by_email[email]
						},
						ticket_ids: {}
					})
					setSelectedFilters(newSelectedFilters?.filter(each=>each?.statusNo === email));
				} else {
					setSelectedFilters(newSelectedFilters?.filter(each=>each?.filterType === "check_in_by_email"));
				}
				getDataFunction(eventId, 0, perPage, search, filterParams);
			} else {
				setSelectedFilters(newSelectedFilters);
			}
		}
	};

	const handleTicketsSearch = (searchText) => {
		let newSearchedTickets = [...tickets];
		newSearchedTickets = newSearchedTickets.filter(ticket =>
			ticket.ticket_name.toLowerCase().includes(searchText.toLowerCase())
		);
		setTicketsSearch(newSearchedTickets);
	}

	const handleTeamsSearchDebounce = useCallback(_.debounce(getTeamsData, 500), [])

	useEffect(() => {
		getTicketsData();
		getEventData();
		getTeamsData();
	}, []);

	useEffect(()=>{
		if(tempData){
			getCheckedInAppStaff();
		}
	}, [tempData])

	useEffect(() => {
		if (filterParams !== "" && ((filterParams.includes("ticket_ids")) || filterParams.includes("team_id") && !_.isEmpty(teamsList)) && !InitialLoaded) {
			checkInitialFilters();
			setInitialLoaded(true);
		}
	}, [tickets]);

	useEffect(()=>{
		//check_in_by_email in checkin page filter attendee table
		if(filterParams !== "" && filterParams.includes("check_in_by_email")){
			if(!InitialLoaded){
				// this is for filter dropdown button in checkedin attendee table
				checkInitialFilters();
				setTempValue("email_ids");
				setInitialLoaded(true);
			} 
			else if(filterParams.includes("&name")){ 
				// if we click on total checkins count in checkin access table then  
				// only that particular volunteer filter attendee be only shown in attendee table 
				// i.e only one volunteer filter label should be shown
				setSelTab(4);
				setTempValue("email_ids");
				setDropActive(false);
				checkInitialFilters();
			}
		}
	}, [filterParams]);

	useEffect(()=>{
		if (filterParams !== "" && filterParams.includes("coupon_codes") && !InitialLoaded) {
			checkInitialFilters();
			setInitialLoaded(true);
		}
	}, [filterParams])

	const filterTabs = () => {
		//tabs to show for Attendees, Leads & Waitlist filter option
		if (heading === "Attendees") {
			return (
				<>
					<div
						className={`w-100 p-2 pl-3 ${selTab == 0 ? "fil-btn-active" : "fil-btn"
							}`}
						onClick={() => {
							setSelTab(0);
						}}
					>
						Ticket Status
					</div>
					<div
						className={`w-100 p-2 pl-3 ${selTab == 1 ? "fil-btn-active" : "fil-btn"
							}`}
						onClick={() => {
							setSelTab(1);
						}}
					>
						Refund Status
					</div>
					<div
						className={`w-100 p-2 pl-3 ${selTab == 4 ? "fil-btn-active" : "fil-btn"
							}`}
						onClick={() => {
							setSelTab(4);
						}}
					>
						Ticket Name
					</div>
					<div
						className={`w-100 p-2 pl-3 ${selTab == 5 ? "fil-btn-active" : "fil-btn"
							}`}
						onClick={() => {
							setSelTab(5);
						}}
					>
						Check In status
					</div>
					{AhlanModeEvents.includes(eventId) && (
						<>
							<div
								className={`w-100 p-2 pl-3 ${selTab == 6 ? "fil-btn-active" : "fil-btn"
									}`}
								onClick={() => {
									setSelTab(6);
								}}
							>
								Organization
							</div>
							<div
								className={`w-100 p-2 pl-3 ${selTab == 7 ? "fil-btn-active" : "fil-btn"
									}`}
								onClick={() => {
									setSelTab(7);
								}}
							>
								People of Determination
							</div>
							<div
								className={`w-100 p-2 pl-3 ${selTab == 8 ? "fil-btn-active" : "fil-btn"
									}`}
								onClick={() => {
									setSelTab(8);
								}}
							>
								Emirates
							</div>
						</>
					)}
					{currencies.length > 1 && (
						<div
							className={`w-100 p-2 pl-3 ${selTab == 9 ? "fil-btn-active" : "fil-btn"
								}`}
							onClick={() => {
								setSelTab(9);
							}}
						>
							Ticket Currency
						</div>
					)}
					{pg_type.length > 1 && (
						<div
							className={`w-100 p-2 pl-3 ${selTab == 10 ? "fil-btn-active" : "fil-btn"
								}`}
							onClick={() => {
								setSelTab(10);
							}}
						>
							Payment Gateway
						</div>
					)}
					{hasTeams && (
						<div
							className={`w-100 p-2 pl-3 ${selTab == 11 ? "fil-btn-active" : "fil-btn"
								}`}
							onClick={() => {
								setSelTab(11);
							}}
						>
							Teams
						</div>
					)}
				</>
			);
		} else if (heading === "Waitlist") {
			return (
				<>
					<div
						className={`w-100 p-2 pl-3 ${selTab == 2 ? "fil-btn-active" : "fil-btn"
							}`}
						onClick={() => {
							setSelTab(2);
						}}
					>
						Waitlist Status
					</div>
					<div
						className={`w-100 p-2 pl-3 ${selTab == 4 ? "fil-btn-active" : "fil-btn"
							}`}
						onClick={() => {
							setSelTab(4);
						}}
					>
						Ticket Name
					</div>
				</>
			);
		} else if (heading === "Approval Based Registration") {
			return (
				<>
					<div
						className={`w-100 p-2 pl-3 ${selTab == 3 ? "fil-btn-active" : "fil-btn"
							}`}
						onClick={() => {
							setSelTab(3);
						}}
					>
						Approval Status
					</div>
					<div
						className={`w-100 p-2 pl-3 ${selTab == 4 ? "fil-btn-active" : "fil-btn"
							}`}
						onClick={() => {
							setSelTab(4);
						}}
					>
						Ticket Name
					</div>
					{AhlanModeEvents.includes(eventId) && (
						<>
							<div
								className={`w-100 p-2 pl-3 ${selTab == 6 ? "fil-btn-active" : "fil-btn"
									}`}
								onClick={() => {
									setSelTab(6);
								}}
							>
								Organization
							</div>
							<div
								className={`w-100 p-2 pl-3 ${selTab == 7 ? "fil-btn-active" : "fil-btn"
									}`}
								onClick={() => {
									setSelTab(7);
								}}
							>
								People of Determination
							</div>
							<div
								className={`w-100 p-2 pl-3 ${selTab == 8 ? "fil-btn-active" : "fil-btn"
									}`}
								onClick={() => {
									setSelTab(8);
								}}
							>
								Emirates
							</div>
						</>
					)}
				</>
			);
		} else if (heading.includes("Uploaded On")) {
			return (
				<>
					<div
						className={`w-100 p-2 pl-3 ${selTab == 3 ? "fil-btn-active" : "fil-btn"
							}`}
						onClick={() => {
							setSelTab(0);
						}}
					>
						Ticket Status
					</div>
					<div
						className={`w-100 p-2 pl-3 ${selTab == 4 ? "fil-btn-active" : "fil-btn"
							}`}
						onClick={() => {
							setSelTab(1);
						}}
					>
						Refund Status
					</div>
				</>
			);
		} else if (heading === "All Registration Attempts") {
			return (
				<div
					className={`w-100 p-2 pl-3 ${selTab == 4 ? "fil-btn-active" : "fil-btn"
						}`}
					onClick={() => {
						setSelTab(4);
					}}
				>
					Ticket Name
				</div>
			);
		} else if (heading === "Overview") {
			return (
				<div
					className={`w-100 p-2 pl-3 ${selTab == 4 ? "fil-btn-active" : "fil-btn"
						}`}
					onClick={() => {
						setSelTab(4);
					}}
				>
					Zone
				</div>
			);
		} else if (heading === "Checked-in Attendees"){
			return (
				<>
					<div
						className={`w-100 p-2 pl-3 ${
							selTab == 4 ? "fil-btn-active" : "fil-btn"
						}`}
						onClick={() => {
							setSelTab(4);
						}}
					>
						Ticket Name
					</div>
					{
						checkInAppStaffs.length > 0 &&
						<div
							className={`w-100 p-2 pl-3 ${
								selTab === 12 ? "fil-btn-active" : "fil-btn"
							}`}
							onClick={() => {
								setSelTab(12);
							}}
						>
							Checked-In By
						</div>
					}
					
				</>
			);
		}
	};

	//This is the function that triggers the API call with the filters
	const handleFilterCheck = () => {
		let newSelectedFilters = [];
		Object.keys(filterParamsObj).map((filterType) => {
			Object.keys(filterParamsObj[filterType]).map((statusNo) => {
				if (filterType !== "ticket_ids") {
					let filterObj = {
						filterType,
						statusNo,
						filterName:
							filterType === "pg_type"
								? pgMethods[filterParamsObj[filterType][statusNo]]
									? filterType === "team_id"
									: filterParamsObj[filterType][statusNo]
								: filterParamsObj[filterType][statusNo],
					};
					newSelectedFilters.push(filterObj);
				} else
					newSelectedFilters.push({
						filterType,
						statusNo,
						filterName: filterParamsObj[filterType][statusNo],
						isTicket: true,
					});
			});
		});

		setSelectedFilters(newSelectedFilters);
		setDropActive(false);
		setFilteredOrganisations(organisations);

		getDataFunction(eventId, 0, perPage, search, filterParams);
	};

	return (
		<>
			<div ref={wrapperRef}>
				{
					showFilterDropdown &&
					<>
						{view === "mobile" ? (
							<div
								className="filter_icon d-flex justify-content-center align-items-center"
								onClick={() => setDropActive(!dropActive)}
							>
								<img src={Filter} alt="Filter" />
							</div>
						) : (
							<div className="mr-4 d-flex position-relative">
								<button
									className="fltBtn"
									onClick={() => setDropActive(!dropActive)}
								>
									<i
										className="fas fa-sliders"
										style={{
											rotate: "270deg",
											marginRight: "10px",
											color: "red",
										}}
									></i>
									Filter
									<i
										className={
											dropActive ? "fas fa-chevron-down" : "fas fa-chevron-up"
										}
										style={{ marginLeft: "20px" }}
									></i>
								</button>
							</div>
						)}
					</>
				}
				

				{dropActive && (
					<div className="dropContent">
						<div className="d-flex justify-content-between align-items-center p-2 head-cont">
							<div className="flt-head ml-2">Filters</div>
							<button
								className="rst-btn pr-2"
								onClick={() => {
									getDataFunction(eventId, 0, perPage, search);
									setFilterParamsObj(initialFilterValues);
									setFilterParams("");
								}}
							>
								Reset Filters
							</button>
						</div>
						<div className="d-flex">
							<div className="l-side">{filterTabs()}</div>
							<div className="r-side p-3">
								{selTab == 0 ? (
									<div>
										<div className="mb-2">
											<input
												className="mr-2"
												type="checkbox"
												id="registration_status-2"
												checked={filterParamsObj.registration_status.hasOwnProperty(
													"2",
												)}
												onClick={(e) => handleCheck(e.target.id)}
											/>
											<label for="registration_status-2">Active Tickets</label>
										</div>
										<div className="mb-2">
											<input
												className="mr-2"
												type="checkbox"
												id="registration_status-3"
												checked={filterParamsObj.registration_status.hasOwnProperty(
													"3",
												)}
												onClick={(e) => handleCheck(e.target.id)}
											/>
											<label for="registration_status-3">
												Cancelled Tickets
											</label>
										</div>
									</div>
								) : selTab == 1 ? (
									<div>
										<div className="mb-2">
											<input
												className="mr-2"
												type="checkbox"
												id="refund_type-2"
												checked={filterParamsObj.refund_type.hasOwnProperty(
													"2",
												)}
												onClick={(e) => handleCheck(e.target.id)}
											/>
											<label for="refund_type-2">Complete Refund</label>
										</div>
										<div className="mb-2">
											<input
												className="mr-2"
												type="checkbox"
												id="refund_type-1"
												checked={filterParamsObj.refund_type.hasOwnProperty(
													"1",
												)}
												onClick={(e) => handleCheck(e.target.id)}
											/>
											<label for="refund_type-1">Partial Refund</label>
										</div>
									</div>
								) : selTab == 2 ? (
									<div>
										<div className="mb-2">
											<input
												className="mr-2"
												type="checkbox"
												id="waitlist_status-1"
												checked={filterParamsObj.waitlist_status.hasOwnProperty(
													"1",
												)}
												onClick={(e) => handleCheck(e.target.id)}
											/>
											<label for="waitlist_status-1">Pending</label>
										</div>
										<div className="mb-2">
											<input
												className="mr-2"
												type="checkbox"
												id="waitlist_status-2"
												checked={filterParamsObj.waitlist_status.hasOwnProperty(
													"2",
												)}
												onClick={(e) => handleCheck(e.target.id)}
											/>
											<label for="waitlist_status-2">Approved</label>
										</div>
										<div className="mb-2">
											<input
												className="mr-2"
												type="checkbox"
												id="waitlist_status-3"
												checked={filterParamsObj.waitlist_status.hasOwnProperty(
													"3",
												)}
												onClick={(e) => handleCheck(e.target.id)}
											/>
											<label for="waitlist_status-3">Rejected</label>
										</div>
									</div>
								) : selTab == 3 ? (
									<div>
										<div className="mb-2">
											<input
												className="mr-2"
												type="checkbox"
												id="waitlist_status-5"
												checked={filterParamsObj.waitlist_status.hasOwnProperty(
													"5",
												)}
												onClick={(e) => handleCheck(e.target.id)}
											/>
											<label for="waitlist_status-5">Pending</label>
										</div>
										<div className="mb-2">
											<input
												className="mr-2"
												type="checkbox"
												id="waitlist_status-6"
												checked={filterParamsObj.waitlist_status.hasOwnProperty(
													"6",
												)}
												onClick={(e) => handleCheck(e.target.id)}
											/>
											<label for="waitlist_status-6">Approved</label>
										</div>
										<div className="mb-2">
											<input
												className="mr-2"
												type="checkbox"
												id="waitlist_status-7"
												checked={filterParamsObj.waitlist_status.hasOwnProperty(
													"7",
												)}
												onClick={(e) => handleCheck(e.target.id)}
											/>
											<label for="waitlist_status-7">Rejected</label>
										</div>
										<div className="mb-2">
											<input
												className="mr-2"
												type="checkbox"
												id="waitlist_status-9"
												checked={filterParamsObj.waitlist_status.hasOwnProperty(
													"9",
												)}
												onClick={(e) => handleCheck(e.target.id)}
											/>
											<label for="waitlist_status-9">On Hold</label>
										</div>
									</div>
								) : selTab == 5 ? (
									<div>
										<div className="mb-2">
											<input
												className="mr-2"
												type="checkbox"
												id="check_in_status-true"
												checked={filterParamsObj.check_in_status.hasOwnProperty(
													true,
												)}
												onClick={(e) => handleCheck(e.target.id)}
											/>
											<label for="check_in_status-true">True</label>
										</div>
										<div className="mb-2">
											<input
												className="mr-2"
												type="checkbox"
												id="check_in_status-false"
												checked={filterParamsObj.check_in_status.hasOwnProperty(
													false,
												)}
												onClick={(e) => handleCheck(e.target.id)}
											/>
											<label for="check_in_status-false">False</label>
										</div>
									</div>
								) : selTab === 6 ? (
									<div className="mb-5">
										<div className="search-organisation-container">
											<SearchBar setSearchFunction={searchOrganisation} />
										</div>
										{filteredOrganisations.map((org) => (
											<div key={org} className="mb-2">
												<input
													type="checkbox"
													id={`organisation-${org}`}
													checked={filterParamsObj.organisation.hasOwnProperty(
														`${org}`,
													)}
													onClick={(e) => handleCheck(e.target.id)}
												/>
												<label for={`organisation-${org}`}>{org}</label>
											</div>
										))}
									</div>
								) : selTab === 7 ? (
									<div>
										<div className="mb-2">
											<input
												className="mr-2"
												type="checkbox"
												id={`${peopleofDeterminationFormId[eventId]}-true`}
												checked={filterParamsObj[
													peopleofDeterminationFormId[eventId]
												].hasOwnProperty(true)}
												onClick={(e) => handleCheck(e.target.id)}
											/>
											<label
												for={`${peopleofDeterminationFormId[eventId]}-false`}
											>
												Yes
											</label>
										</div>
										<div className="mb-2">
											<input
												className="mr-2"
												type="checkbox"
												id={`${peopleofDeterminationFormId[eventId]}-false`}
												checked={filterParamsObj[
													peopleofDeterminationFormId[eventId]
												].hasOwnProperty(false)}
												onClick={(e) => handleCheck(e.target.id)}
											/>
											<label
												for={`${peopleofDeterminationFormId[eventId]}-false`}
											>
												No
											</label>
										</div>
									</div>
								) : selTab === 8 ? (
									<>
										{emirateOptions.map((emirate) => (
											<div key={emirate} className="mb-2">
												<input
													type="checkbox"
													id={`${emiratesFormId[eventId]}-${emirate}`}
													checked={filterParamsObj[
														emiratesFormId[eventId]
													].hasOwnProperty(`${emirate}`)}
													onClick={(e) => handleCheck(e.target.id)}
												/>
												<label for={`${emiratesFormId[eventId]}-${emirate}`}>
													{emirate}
												</label>
											</div>
										))}
									</>
								) : selTab === 9 ? (
									<div>
										{currencies.map((currency) => (
											<div key={currency} className="mb-2">
												<input
													type="checkbox"
													id={`currencies-${currency}`}
													checked={filterParamsObj.currencies.hasOwnProperty(
														`${currency}`,
													)}
													onClick={(e) => handleCheck(e.target.id)}
												/>
												<label for={`currencies-${currency}`}>{currency}</label>
											</div>
										))}
									</div>
								) : selTab === 10 ? (
									<div>
										{pg_type.map((pg) => (
											<div key={pg} className="mb-2">
												<input
													type="checkbox"
													id={`pg_type-${pg}`}
													checked={filterParamsObj.pg_type.hasOwnProperty(
														`${pg}`,
													)}
													onClick={(e) => handleCheck(e.target.id)}
												/>
												<label for={`pg_type-${pg}`}>
													{pgMethods[`${pg}`]}
												</label>
											</div>
										))}
									</div>
								) : selTab === 11 ? (
									<div>
										{tickets.length >= 10 &&
											<div className="mb-2">
												<SearchBar searchPlaceholder="Search Teams..." setSearchFunction={(searchText) => handleTeamsSearchDebounce(searchText)} height="30px" />
											</div>}
										{teamsIsLoading ? <LoadingSpinner /> 
											: teamsList.length > 0 
											? teamsList.map((team) => (
												<div key={team.team_id} className="mb-2">
													<input
														type="checkbox"
														id={`team_id-${team.team_id}`}
														checked={filterParamsObj.team_id.hasOwnProperty(
															`${team.team_id}`,
														)}
														onClick={(e) => handleCheck(e.target.id)}
													/>
													<label for={`teams-${team.team_id}`}>
														{team.team_name}
													</label>
												</div>)) 
											: <div className="search-none-text">
													No teams
												</div>}
									</div>
								) : heading === "Checked-in Attendees" && selTab === 12 ? 
									<div className="mb-5">
										{checkInAppStaffs.map((each) => (
											<div key={each.userEmail} className="mb-2">
												<input
													type="checkbox"
													id={`email_ids-${each.userEmail}`}
													checked={filterParamsObj.check_in_by_email.hasOwnProperty(
														`${each.userEmail}`,
													)}
													onClick={(e) => {
														setInitialLoaded(true);
														handleCheck(e.target.id)
													}}
												/>
												<label for={`email_ids-${each.userEmail}`}>
													{each.name}
												</label>
											</div>
										))}
									</div>
								: tickets ? (
									<div className="mb-5">
										{tickets.length > 10 && 
										<div className="mb-2">
											<SearchBar  searchPlaceholder="Search Tickets..." setSearchFunction={(searchText) => handleTicketsSearch(searchText)} height="30px"/>
										</div>}
										{ticketsSearch.length > 0 ? ticketsSearch.map((ticket) => (
											<div key={ticket.ticket_id} className="mb-2">
												<input
													type="checkbox"
													id={`ticket_ids-${ticket.ticket_id}`}
													checked={filterParamsObj.ticket_ids.hasOwnProperty(
														`${ticket.ticket_id}`,
													)}
													onClick={(e) => handleCheck(e.target.id, true)}
												/>
												<label style={{wordBreak: "break-word"}} for={`ticket_ids-${ticket.ticket_id}`}>
													{ticket.ticket_name}
												</label>
											</div>
										)) : <div className="search-none-text">
											No tickets	
										</div>}
									</div>
								) : (
									<LoadingSpinner />
								)}
							</div>
							<div className="btn-wrap d-flex justify-content-between">
								<KonfhubButton
									onClick={() => {
										setDropActive(false);
									}}
									btnType="tertiaryWhite"
								>
									Cancel
								</KonfhubButton>
								<KonfhubButton
									className="ml-2"
									onClick={() => handleFilterCheck()}
									btnType="tertiary"
									disabled={filterParams ? false : true}
								>
									Apply
								</KonfhubButton>
							</div>
						</div>
					</div>
				)}
				<style>
					{`
						.search-organisation-container .searchIconContainer {
							width: 230px !important;
							margin-bottom: 10px;
						}
						.dropContent{
							display: block;
							position: absolute;
							background-color: white;
							width: 94%;
							max-width: 402px;
							border: 1px solid #C3CDDF;
							z-index: 100000;
							right: ${heading === "Checked-in Attendees" ? "10%" : "13%"};
							top: ${heading === "Checked-in Attendees" ? "auto" : "78px"};
							box-shadow: 0px 0px 8px #c3cddf;
							height: 348px;
							font-size: 14px;
							margin-top: ${heading === "Checked-in Attendees" ? "7px" : "auto"};
						}
						.search-none-text {
							margin-top: 40px;	
							text-align: center;
							color: #6D7780;
						}
						@media screen and (max-width: 428px) {
							.dropContent {
								right: 3%;
							}
						}
                    `}
				</style>
			</div>
		</>
	);
};

export default DataTableFilter;
