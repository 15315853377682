import React from 'react'

const AddButton = ({ openModal, title }) => {
    return (
        <>
            <div className="d-flex flex-column justify-content-center align-items-center form-card form-card-add-new border ml-3 mr-3 py-3"
                onClick={openModal}>
                <i className="icon-color fa fa-plus fa-lg" aria-hidden="true"></i>
                <span className="forms-card-name mt-2">{title}</span>
            </div>
            <style jsx global>{`
				.add-new {
					position: absolute;
					bottom: 0px;
					right: 0px;
				}
				.form-card-add-new {
					transition: all 0.3s
				}
				.form-card-add-new:hover {
					background: #f4f4f4;
					cursor: pointer;
				}	
				.sx {
					min-height: 300px;
				}
				.icon-color {
					color: #572148;
				}
				.icon-color:hover {
					color: grey;
				}
			`}</style>
        </>
    )
}

export default AddButton
