import axios from "./axios";

const API_URL = axios.defaults.baseURL;
const BASE_URL = "/event/";
const Tickets_base = "/tickets";
const Forms_base = "/form";
const CATEGORY_URL = "/category";

export const getEventDetails = eventId => {
	return axios.get(API_URL + BASE_URL + eventId);
};

export const getTickets = eventId => {
	return axios.get(API_URL + BASE_URL + eventId + Tickets_base);
};

export const duplicateTicketCheck = (eventId, payload, ticketID) => {
	const ticketIDParam = ticketID ? `&&ticket_id=${encodeURIComponent(ticketID)}` : '';
	return axios.get(
		`${API_URL + BASE_URL + eventId + Tickets_base}/validate?ticket_name=${encodeURIComponent(payload)}${ticketIDParam}`
	);
};

/*getting All deleted tickets . */
export const getDeletedTickets = async eventId => {
	return axios.get(
		API_URL + BASE_URL + eventId + Tickets_base + `?includeDeleted=true`
	);
};

export const addTickets = (eventId, payload) => {
	return axios.post(
		API_URL + BASE_URL + eventId + Tickets_base,
		payload
	);
};

export const deleteTickets = (eventId, ticketID, ticketName) => {
	return axios.delete(
		API_URL +
		BASE_URL +
		eventId +
		Tickets_base +
		`/${ticketID}` +
		`?ticket_name=${ticketName}`
	);
};

export const editTickets = (eventId, ticketID, payload) => {
	return axios.put(
		API_URL + BASE_URL + eventId + Tickets_base + `/${ticketID}`,
		payload
	);
};

export const reorderTickets = (eventId, payload) => {
	return axios.put(
		API_URL + BASE_URL + eventId + Tickets_base,
		payload
	);
};

export const validateTickets = (eventId, ticketName) => {
	return axios.get(
		API_URL +
		BASE_URL +
		eventId +
		Tickets_base +
		`/validate` +
		`?ticket_name=${ticketName}`
	);
};

// Clone a Ticket
export const cloneTicket = (eventId, ticket_id, payload) => {
	return axios.post(
		API_URL + BASE_URL + eventId + Tickets_base + "/" + ticket_id + "/clone",
		payload
	);
};

// FORMS

export const getForms = eventId => {
	return axios.get(
		API_URL + BASE_URL + eventId + Forms_base + "?include_deleted=false"
	);
};

export const getDeletedNonDeletedForms = async eventId => {
	try {
		return await axios.get(
			API_URL +
			BASE_URL +
			eventId +
			Forms_base +
			"?include_deleted=true"
		);
	} catch (err) {
		return err;
	}
};

export const getTicketCategory = (eventId) => {
	return axios.get(API_URL + BASE_URL + eventId + CATEGORY_URL + '?category_type=3');
}

export const createTicketCategory = (eventId, payload) => {
	return axios.post(API_URL + BASE_URL + eventId + CATEGORY_URL, payload);
}

export const updateTicketCategory = (eventId, payload, categoryId) => {
	return axios.put(API_URL + BASE_URL + eventId + CATEGORY_URL + '/' + categoryId, payload);
}

export const deleteTicketCategory = (eventId, categoryId) => {
	return axios.delete(API_URL + BASE_URL + eventId + CATEGORY_URL + '/' + categoryId + '?category_type=3');
}

export const reorderTicketCategory = (eventId, payload) => {
	return axios.put(API_URL + BASE_URL + eventId + CATEGORY_URL + '/reorder', payload);
}

export const sequenceNumber = (eventId) => {
	return axios.get(API_URL + BASE_URL + eventId + "/sequence_number");
}

export const addSequenceNumber = (eventId, payload) => {
	return axios.post(API_URL + BASE_URL + eventId + "/sequence_number", payload);
}

export const editSequenceNumber = (eventId, sequenceID, payload) => {
	return axios.put(API_URL + BASE_URL + eventId + "/sequence_number/" + sequenceID, payload);
}

export const deleteSequenceNumber = (eventId, sequenceID) => {
	return axios.delete(API_URL + BASE_URL + eventId + "/sequence_number/" + sequenceID);
}

