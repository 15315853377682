import React, { Suspense, useContext, useEffect, useState } from "react";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import { logout } from "./../reusable/common";
// import Loading from './../components/Loading';
import AllRoutes from "./Routes";
import AuthContext from "./../contexts/AuthContext";
import KonfhubLoad from "../components/KonfhubLoad";

const EditMyCommunityLayout = React.lazy(() =>
	import("./../containers//Header/MyCommunity/EditMyCommunityLayout")
);

export default function OtherRoutes(props) {
	const Context = useContext(AuthContext);

	const CreateEventRoute = pageProps => <Route {...pageProps} {...props} />;

	return (
		<Suspense fallback={<KonfhubLoad />}>
			<EditMyCommunityLayout>
				<Switch>
					{AllRoutes.map((route, index) => {
						switch (route.authentication) {
							case "unauthenticated":
								return (
									<Redirect exact from={route.path} to={`/`} key={index} />
								);
							case "authenticated":
							case "both":
							default:
								if (!route.layout) {
									return (
										<Route
											exact
											path={route.path}
											name={route.name}
											render={pageProps => (
												<route.component
													{...props}
													{...pageProps}
													key={index}
												/>
											)}
											key={index}
										/>
									);
								}
						}
					})}
				</Switch>
			</EditMyCommunityLayout>
		</Suspense>
	);
}
