import React from "react";
import { websiteUrl } from "../../config/config";

const Banner = ({
	isNotCreator,
	eventId,
	stripeConnect = false,
	isBankDetailsAdded,
	isKYCVerified,
	isSettlementsDisabled
}) => {
	const getBanner = () => {
		if (stripeConnect) {
			return (
				<>
					{isNotCreator && (
						<h4 className="banner-div">
							Paid ticket registrations are disabled for this event. Please ask the
							event creator to connect their payment gateway to allow paid
							registrations.
						</h4>
					)}
					{!isNotCreator && (
						<h4 className="banner-div">
							Paid ticket registrations are disabled for this event. Please connect
							your payment gateway to allow paid registrations.
							<span
								className="ml-1 pointer-cursor"
								style={{ textDecoration: "underline", fontWeight: 600 }}
								onClick={() =>
									window.open(`/e/${eventId}/payment/bankDetails`, "_self")
								}
							>
								Connect Payment Gateway
							</span>
						</h4>
					)}
				</>
			);
		}

		if (isSettlementsDisabled) {
			return (
				<h4 className="banner-div">
					Automatic settlements for this event has been disabled by the event creator.{" "}
					{isNotCreator ? (
						<span>Ask the event creator to update.</span>
					) : (
						<>
							<span>It can be enabled again from</span>
							<span
								className="ml-1 pointer-cursor"
								style={{ textDecoration: "underline", fontWeight: 600 }}
								onClick={() =>
									window.open(`${websiteUrl}/e/${eventId}/settlements`, "_self")
								}
							>
								**here**
							</span>
						</>
					)}
				</h4>
			);
		}
		if (!isBankDetailsAdded && !isKYCVerified) {
			return (
				<h4 className="banner-div">
					Automatic settlements are disabled for this event due to incomplete bank details
					and KYC.{" "}
					{isNotCreator ? (
						<span>Ask the event creator to update.</span>
					) : (
						<span
							className="ml-1 pointer-cursor"
							style={{ textDecoration: "underline", fontWeight: 600 }}
							onClick={() =>
								window.open(
									`${websiteUrl}/e/${eventId}/payment/bankDetails`,
									"_self"
								)
							}
						>
							Please update to receive funds.
						</span>
					)}
				</h4>
			);
		}
		if (isBankDetailsAdded && !isKYCVerified) {
			return (
				<h4 className="banner-div">
					Automatic settlements are disabled for this event due to incomplete KYC.{" "}
					{isNotCreator ? (
						<span>Ask the event creator to update.</span>
					) : (
						<span
							className="ml-1 pointer-cursor"
							style={{ textDecoration: "underline", fontWeight: 600 }}
							onClick={() =>
								window.open(
									`${websiteUrl}/e/${eventId}/payment/bankDetails`,
									"_self"
								)
							}
						>
							Please update KYC to receive funds.
						</span>
					)}
				</h4>
			);
		} else if (!isBankDetailsAdded && isKYCVerified) {
			return (
				<h4 className="banner-div">
					Automatic settlements are disabled for this event due to incomplete bank
					details.{" "}
					{isNotCreator ? (
						<span>Ask the event creator to update.</span>
					) : (
						<span
							className="ml-1 pointer-cursor"
							style={{ textDecoration: "underline", fontWeight: 600 }}
							onClick={() =>
								window.open(
									`${websiteUrl}/e/${eventId}/payment/bankDetails`,
									"_self"
								)
							}
						>
							Please update Bank Details to receive funds.
						</span>
					)}
				</h4>
			);
		}
		return <></>;
	};

	return (
		<>
			<div className="banner d-flex px-2 px-md-0 justify-content-center my-auto align-items-center">
				{getBanner()}
			</div>

			<style>{`
                .banner {
                    min-height: 25px;
                    background: #FFDBA6;
                }	
                .banner-div{
                    font-size: 14px;
                    margin-top: 10px;        
                }
                @media (max-width: 600px) {
                    .banner-div{
                        font-size: 12.5px !important;
                    }
                }
			
            `}</style>
		</>
	);
};

export default Banner;
