import * as yup from "yup";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import { ValidateURL } from "../../../../revampCRUD/eventDetails.crud";
import { boolean } from "yup/lib/locale";
import { errorToaster } from "../../../../reusable/common";
import { CHAR_LIMITS } from "../../config/constants";

const event_urlRegExp = /^[a-zA-Z0-9-]+$/;
const numRegExp = /[a-zA-Z]+/;
const shortNameRegExp = /^(?=.*[a-zA-Z])[^*|\":<>[\]%{}=\/+\.^!,`\\()?'~;#@&$]{1,32}$/;
const URLRegExp =
	/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&\/\/=]*)/;

export function validationSchema(values) {
	const errors = {};
	const event_urlRegExp = /^[a-zA-Z0-9-]+$/;
	const numRegExp = /^[-:()\[\]|,/<>#,.&@'+\w\sÀ-ÖØ-öø-ÿ]+$/;
	const shortNameRegExp = /^[a-zA-Z0-9 _\-]{1,32}$/;
	const URLRegExp =
		/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&\/\/=]*)/;
	const emojiRegex = /[^\u0000-\u007F]+/;
	const englishOnlyRegex = /^[a-zA-Z0-9\s!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~’“”–—…]+$/;

	//event name
	if (values.name === "") errors.name = "Please provide an event name";
	else if (!values.name.match(numRegExp))
		errors.name = "Please enter a valid event name (special characters are not allowed)";
	else if (values.name.match(emojiRegex)) errors.name = "Emojis are not allowed";
	else if (values.name.legnth < 2)
		errors.name = "Please enter a valid event name greater than 1 character";

	//event short name
	if (values.event_short_name === "") errors.event_short_name = "Please provide a short name";
	else if (!values.event_short_name.match(shortNameRegExp))
		errors.event_short_name = "Please provide a valid short name";
	else if (/^[\d-_ ]+$/.test(values.event_short_name)) {
		errors.event_short_name =
			"We recommend you to use alphabets in your event url so your audience can easily recognise";
	} else if (values.event_short_name.match(emojiRegex))
		errors.event_short_name = "Emojis are not allowed";

	if (!values.is_virtual) {
		// Event city
		if (values.venue && !values.venue.match(numRegExp))
			errors.venue = "Please enter a valid venue (special characters are not allowed)";
		if (values.city === "" || values.city == null) errors.city = "Please enter event city";
		else if (!values.city.match(numRegExp)) errors.city = "Please enter a valid event city";
		else if (values.city.length < 2)
			errors.city = "Please enter a valid event city greater than 1 character";

		if (
			values.currency_name === "INR" &&
			values.is_free === false &&
			(values.state === "" || values.state == null)
		)
			errors.state = "Please enter event state";
	} else {
		// Event link
		if (!values.event_live_link.match(URLRegExp) && values.event_live_link !== "")
			errors.event_live_link = "Please enter a valid URL";
	}

	if (values.start_date > values.end_date)
		errors.start_date = "Start date can not be greater than End date";

	if (values.start_date === values.end_date && values.start_time >= values.end_time)
		errors.same_day_error =
			"For single day event, the end time should be later than the start time";

	//Additional Venue Details Description
	if (
		values.additional_venue_details != null &&
		values.additional_venue_details.length > CHAR_LIMITS.about.basicDescMax
	)
		errors.additional_venue_details = "You have reached maximum characters limit";
	else if (values?.additional_venue_details && !englishOnlyRegex.test(values?.additional_venue_details)) {
		errors.additional_venue_details =
			"The platform supports only English. Kindly provide your details in English.";
	}

	if (values.event_url === "") errors.event_url = "Please provide an event name";
	else if (!values.event_url.match(event_urlRegExp))
		errors.event_url =
			"For event URL, special characters and spaces are not allowed (only alphanumerics & hyphes are allowed)";

	return errors;
}

// export const validationSchema = yup.object({
// 	is_virtual: yup.boolean(),

// 	name: yup
// 		.string()
// 		.matches(numRegExp, "Please provide a valid event name")
// 		.required("Please provide event name")
// 		.test(
// 			"emoji-val",
// 			`Emojis are not allowed`,
// 			(val) => {
// 			  let regex = /[^\u0000-\u007F]+/
// 			 if(val === undefined) return true;
// 			 else if(!regex.test(`${val}`)) return true;
// 			}
// 		  ),

// 	event_short_name: yup
// 		.string()
// 		.required("Please provide a short name")
// 		.matches(shortNameRegExp, "Please provide a valid short name"),

// 	event_live_link: yup
// 		.string()
// 		.matches(
// 			URLRegExp,
// 			"Invalid URL. Please provide a valid URL prefixed with http:// or https://"
// 		)
// 		.nullable(),

// 	// venue: yup.string().nullable(),

// 	city: yup.string().when("is_virtual", {
// 		is: false, // alternatively: (val) => val == true
// 		then: yup
// 			.string()
// 			.required("Please provide city name")
// 			.matches(numRegExp, "City is invalid"),
// 		otherwise: yup.string().nullable()
// 	}),

// 	start_date: yup
// 			.date()
// 			.required("Please provide start date"),

// 	end_date: yup
// 		.date()
// 		.required("End date is required")
// 		.min(yup.ref("start_date"), "End Date can not be less than start date"),

// 	start_timestamp: yup.date(),

// 	event_url: yup
// 		.string()
// 		.required("Please provide event URL")
// 		.matches(
// 			event_urlRegExp,
// 			"For event URL, special characters not allowed (use only alphanumeric & hyphen)."
// 		),

// 	is_virtual: yup.boolean(),

// 	expected_speakers: yup
// 		.number()
// 		.min(0, "Please provide a number greater than or equal to 0")
// 		.nullable()
// 		.integer("Please provide an integer"),
// 	expected_attendees: yup
// 		.number()
// 		.min(0, "Please provide a number greater than or equal to 0")
// 		.nullable()
// 		.integer("Please provide an integer")
// });

// export default validationSchema;
/*// end_time: yup
	// 	.date()
	// 	.required("End date is required")
	// 	.min(
	// 		yup.ref("start_time"),
	// 		"In case of Single day event, End time cannot be less than start time "
	// 	),

	// end_date: yup
	// 	.date()
	// 	.required("end time cannot be empty")
	// 	.min(yup.ref("start_date"), "End Date can not be less than start date")
	// 	.test("is-greater", "end time should be greater", function (value) {
	// 		const { start_date } = this.parent;
	// 		return moment(value, "YYYY-MM-DD HH:mm:ss").isSameOrAfter(
	// 			moment(start_date, "YYYY-MM-DD HH:mm:ss")
	// 		);
	// 	}),

	// .when("is_past", {
	// 	is: false,
	// 	then: yup.date().min(
	// 		xx,
	// 		// props.eventInfo.created_at,
	// 		"Date cannot be less than today's date"
	// 	)

	// }), */

/*Realtime URL Validation function all props are explained at BasicInfoFirstTwoRos.js file */
export const ValidateUrlRealtime = (
	formik,
	setAPICalled,
	setIsValueChanged,
	setUrlFailerResponse,
	setUrlSuccessResponse,
	setLoad
) => {
	const ValidateURLFunction = async url => {
		setAPICalled(true); // for render conditions at line : 169.
		setIsValueChanged(true); // same reason as above.
		try {
			const response = await ValidateURL(url);
			if (response.status == 200) {
				/* Erasing the FailedResponse first //-> because it glitched for a second before updating the success Info Icon*/
				setUrlFailerResponse("");
				setUrlSuccessResponse(response.data);
			} else {
				/* same reason as above */
				setUrlSuccessResponse("");
				setUrlFailerResponse(JSON.stringify(response));
			}
		} catch (err) {
			setUrlSuccessResponse("");
			errorToaster("Something went wrong while validating event URL");
			// setUrlFailerResponse();
		}
		setLoad(false);
	};
	const handlerURL = useCallback(debounce(ValidateURLFunction, 500), []);
	useEffect(() => {
		const preUrl = formik.initialValues.event_url;
		const newUrl = formik.values.event_url;
		//code to remove hyphens from string to check if string contains special characters
		const urlWithoutHyphen = `${newUrl.replace(/-/g, "")}`;
		const containsSpecialCharacter = /[^a-zA-Z0-9]/.test(urlWithoutHyphen);
		if (newUrl !== preUrl && !containsSpecialCharacter) {
			setLoad(true);
			handlerURL(formik.values.event_url);
		}

		setTimeout(() => {
			/* Condtion to check if the user tried new url and then went back to default URL //-> Don't show any info icon*/
			if (newUrl === preUrl) setIsValueChanged(false);
			/* Condtion to check if the user erased the entire field //-> Don't show any info icon*/
			if (newUrl.length == 0) setIsValueChanged(false);
		}, 500);
	}, [formik?.values?.event_url]);
};

/* Cutom function to validate end time for single day event */ /*END DATES*/
export const CheckifSameDateError = (formik, tempDate, tempTime) => {
	/* Check if start date and end date are same and start time is greater than end time.*/
	if (formik?.values?.start_date === tempDate && formik.values.start_time >= tempTime) {
		/*Explicitly setting same_day_error if event starts and ends on same day and end time is less than start time. */
		formik.errors.same_day_error = `For single day event, the end time should be later than the start time`;
	} else if (formik?.values?.start_date === tempDate && formik.values.start_time < tempTime) {
		delete formik.errors.same_day_error; //deleting the explicityly created error object if conditions are false.
	} else {
		delete formik.errors.same_day_error;
	}
};

/*START DATES*/
export const CheckifSameTimeError = (formik, tempDate, tempTime) => {
	/* Check if start date and end date are same and start time is greater than end time.*/
	if (formik?.values?.end_date === tempDate && formik.values.end_time <= tempTime) {
		/*Explicitly setting time_error if event starts and ends on same day and end time is less than start time. */
		formik.errors.time_error = `For single day event, the start time should be before than the end time`;
	} else if (formik?.values?.end_date === tempDate && formik.values.end_date > tempTime) {
		delete formik.errors.time_error; //deleting the explicityly created error object if conditions are false.
	} else {
		delete formik.errors.time_error;
	}
};
