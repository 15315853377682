import React from "react";
import Modal from "react-modal";

import FormButtons from "../../Molecule/Buttons/FormButtons";
import KonfhubContainer from "../Container";
import { KonfhubButton } from "../Buttons";

const CustomModal = ({
	openModal,
	setOpenModal,
	onClose,
	content,
	onOkay,
	onCancel,
	saveBtnContent,
	isLoading,
	cancelBtnContent,
	paddingRequired,
	cancelBtnDisabled,
	saveBtnDisabled,
	header,
	subHeader,
	floatingButton,
	floatingCancelButton,
	onClick,
	styles,
	width,
	className,
	subHeaderClassName,
	overflow,
	height,
	headerTextAlign,
	headerStyle,
	noClose = false,
	marginButtomHeader = "mb-4"
}) => {
	const customStyles = {
		// Style for the Modal
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			maxWidth: "90%",
			maxHeight: floatingButton == "Download" ? "calc(100% - 120px)" : "calc(100% - 75px)",
			width: width ? width : "auto",
			height: height ? height : "auto",
			overflow: overflow ? overflow : "hidden",
			overflowX: "hidden"
		},
		overlay: {
			background: "rgba(0,0,0,0.75)",
			height: "100%",
			zIndex: "100"
		}
	};
	return (
		<>
			<Modal
				ariaHideApp={false}
				isOpen={openModal}
				shouldCloseOnEsc={false}
				shouldCloseOnOverlayClick={false}
				onRequestClose={() => {
					setOpenModal(false);
				}}
				className={className}
				style={styles ? styles : customStyles}
			>
				<KonfhubContainer>
					{header == null ? (
						<>
							{!noClose && (
								<div className="row w-100 ml-0 d-flex justify-content-end">
									<i
										className="fas fa-times closeIcon"
										onClick={() => {
											if (setOpenModal) setOpenModal(false);
											if (onClose) onClose();
										}}
									></i>
								</div>
							)}
						</>
					) : (
						<>
							<div className="row w-100 ml-0 d-md-flex justify-content-between">
								<div className="col-10 px-0">
									<p
										style={
											headerStyle
												? headerStyle
												: {
														textAlign: headerTextAlign
															? headerTextAlign
															: "left"
													}
										}
										className={`formTitle fw-bold ${
											!headerStyle ? "px-3" : ""
										} ${marginButtomHeader}`}
									>
										{header}
									</p>
									{subHeader ? (
										<p
											className={`${marginButtomHeader} text-left pl-3 subHeader${
												subHeaderClassName ? ` ${subHeaderClassName}` : ""
											}`}
										>
											{subHeader}
										</p>
									) : (
										""
									)}
								</div>
								{!noClose && (
									<div className="col-2 px-0 text-right">
										<i
											className="fas fa-times closeIcon"
											onClick={() => {
												if (setOpenModal) setOpenModal(false);
												if (onClose) onClose();
											}}
										></i>
									</div>
								)}
							</div>
						</>
					)}
					{content}

					{saveBtnContent != null && (
						<div className="mt-4 mx-3">
							<FormButtons
								onSave={onOkay}
								onCancel={onCancel}
								isLoading={isLoading}
								saveBtnContent={saveBtnContent}
								saveBtnDisabled={saveBtnDisabled || false}
								cancelBtnDisabled={cancelBtnDisabled || false}
								paddingRequired={paddingRequired || "no"}
								cancelBtnContent={cancelBtnContent || "Close"}
							/>
						</div>
					)}
				</KonfhubContainer>
				{floatingButton != null && (
					<div className="downloadCheckboxes-buttons p-2">
						<KonfhubButton
							btnType="secondary"
							className="mr-2"
							onClick={() => setOpenModal(false)}
						>
							{floatingCancelButton}
						</KonfhubButton>
						<KonfhubButton
							className="mr-2"
							isLoading={isLoading}
							disabled={isLoading}
							onClick={() => onClick()}
						>
							{floatingButton}
						</KonfhubButton>
					</div>
				)}
			</Modal>

			<style>{`
				.subHeader{
					margin-top: -25px;
					font-weight: 500;
					font-size: 15px;
					line-height: 24px;
					color: #572148;
				}
				.closeIcon {
					font-size: 1rem;
					color: black;
					opacity: 0.5;
				}

				.closeIcon:hover {
					// color: black;
					opacity: 0.8;
				}

				body.modal-open {
					overflow: hidden;
				}
				.downloadCheckboxes-buttons {
					display: flex;
					justify-content: flex-end;
                    height: 55px;
    				position: sticky;
    				bottom: 0px;
    				left: 0;
    				right: 0;
                    border-top: 1px solid #dbdbdb;
                    width: 100%;
                    text-align: right;
                    background: #EFE9ED;
					box-shadow: 0px 0px 9px rgba(87, 33, 72, 0.12);
                }

			`}</style>
		</>
	);
};

export default CustomModal;
