export const organisations = [
	"Abu Dhabi Bengali Association (ADIBC)",
	"Abu Dhabi Goa Community",
	"ACKAF",
	"ACKAF Events",
	"Amity University",
	"Art of Living, UAE",
	"Assam Association, UAE",
	"BAPS Mandir",
	"Bhojpuriya Parivar, UAE",
	"Bhojpuriya Samaj, UAE",
	"Bihar Jharkhand Samaj, Abu Dhabi",
	"Bihar Samaj , Abu Dhabi",
	"Bihar, UP & Jharkhand Community UAE",
	"BITS, UAE",
	"CAS Ladies Assn Chaturvedi Samaj UAE",
	"Chinmaya Mission, UAE",
	"Dubai Kerala Muslim Cultural Centre",
	"Dubai Priyadarshini Volunteering Team",
	"EKATA",
	"Emirates Bengal Club",
	"Emirates Telangana Cultural & Welfare Association (ETCA)",
	"FOI",
	"Friends of India, FOI UAE",
	"Friends of MP",
	"Global Purvanchal Forum, UAE",
	"Gujarati Samaj Abu Dhabi",
	"Gulf Maharashtra Business Forum, UAE",
	"Guru Nanak Darbar",
	"Gurukul",
	"Hindu Mandir, Jebel Ali",
	"IBPC- UAE",
	"IBPG Abu Dhabi",
	"ICAI Abu Dhabi Chapter",
	"ICAI Dubai",
	"IKKF",
	"IMAN Cultural Center",
	"India Club",
	"India Social & Cultural Center, Abu Dhabi",
	"Indian Association – Ajman",
	"Indian Association Khorfakkan",
	"Indian Association Sharjah",
	"Indian Association Umm Al Quwain",
	"Indian High School, Dubai",
	"Indian Islamic Center, Abu Dhabi",
	"Indian Ladies Association, Abu Dhabi",
	"Indian People`s Forum, UAE",
	"Indian Relief Committee – RAK",
	"Indian Social & Cultural Club, Kalba",
	"Indian Social Centre, Al Ain",
	"Indian Social Centre, Ajman",
	"Indian Social Club Fujairah",
	"Indian Social Club Khorfakkan",
	"Indian Women in Dubai, IWD",
	"International Sadguru Foundation, UAE Council",
	"ISHA",
	"Iskcon Temple",
	"Ismaili Centre",
	"Jain Social Association",
	"JITO",
	"Kannada Brahmana Sangha",
	"Kannada Patha Shale Dubai",
	"Karnataka NRI Forum",
	"Karnataka Sangha Dubai / Sharjah / Abu Dhabi",
	"Kerala Social Center",
	"Maharashtra Mandal, Abu Dhabi",
	"Maharashtra Mandal, Dubai",
	"Maharashtra Mandal, NE Region",
	"Malayalee Samajam, Abu Dhabi",
	"Manipal",
	"Marthoma Church - Karama",
	"Marwari Community, UAE",
	"Marwari Yuva Manch, Abu Dhabi",
	"MYM Dubai",
	"NSS",
	"Odia Samaj Abu Dhabi",
	"Odisha Samaj",
	"ORMA",
	"Orthodox Church - Jebel Ali",
	"PIOCCI",
	"Prabasi Odia Samaj Abu Dhabi",
	"Punjab Association, UAE",
	"Purvanchal Mahasangh, UAE",
	"Raja Yoga Centre",
	"RBPG",
	"Samanwaya, RAK",
	"Santhwanam UAE",
	"Sevanam UAE",
	"Skyline University",
	"Smartlife Foundation",
	"SNDP",
	"Tamil Ladies Association",
	"Tamil Makkal Mandram, Abu Dhabi",
	"Tamil Sangam",
	"Taxation Society",
	"Telangana Association Abu Dhabi",
	"Telangana Association, Abu Dhabi",
	"Telangana Community Dubai & NE Region",
	"Telugu Association",
	"Texmas",
	"Theeram UAE",
	"The Dawoodi Bohra Community",
	"UIBC- UAE Chapter",
	"Uttarakhand Association, UAE",
	"Vikas Cultural Center, Ajman",
	"WMC",
	"Others",
];
