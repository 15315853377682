import React from "react";
import Header from "./Header/Header";
import DashboardSidebar from "./Sidebar/DashboardSidebar";

const DashboardLayout = props => {
	return (
		<>
			<div className="">
				<DashboardSidebar {...props} />
				<div className={`${!props?.prefeventinfo ? "" : "dashboard-content"}`}>
					<Header {...props} />
					{props.children}
				</div>
			</div>
			<style>{`
                .dashboard-content {
                    background-color: ${localStorage.getItem("darkMode") ? "#2f2f2f" : "auto"}
                }
            `}</style>
		</>
	);
};

export default DashboardLayout;
