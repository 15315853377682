import React, { Suspense, useState, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { useLocation } from 'react-router-dom'
// import Loading from './../components/Loading';
import AllRoutes from './Routes';
import KonfhubLoad from "../components/KonfhubLoad";
import { loginUrl } from "../config/config";

const Page404 = React.lazy(() => import('./../pages/Page404'));
const DashboardLayout = React.lazy(() => import('./../containers/DashboardLayout'));
// const ProfileLayout = React.lazy(() => import('./../containers/ProfileLayout'));

export default function Routes(props) {
    const location = useLocation();

    const LayoutRoute = (pageProps) => (
        <DashboardLayout {...pageProps} {...props}>
            <Route {...pageProps}/>
        </DashboardLayout>
    )

    // const ProfileRoute = (pageProps) => (
    //     <ProfileLayout {...pageProps} {...props}>
    //         <Route {...pageProps}/>
    //     </ProfileLayout>
    // )

    useEffect(()=> {
        if(window.location.hostname !== "localhost") { // if local host dont redirect to central login page
            if(location.pathname !== "/"){
                window.location.href = `${loginUrl}/login?redirect=KonfhubEvent&redirectToRoute=${location.pathname}`
            } else {
                window.location.href = `${loginUrl}/login?redirect=KonfhubEvent`
            }
        }
    }, [])

    return (
        <>
        {window.location.hostname == "localhost" && ( // if local host show login page
            <Suspense fallback={<KonfhubLoad />}>
            <Switch>
                {
                    AllRoutes.map((route, index) => {
                        switch(route.authentication) {
                            case 'authenticated':
                                return(<Redirect exact from={route.path} to={`/login?redirect=${location.pathname}`} key={index}/>);
                            case 'unauthenticated':
                                return(<Route exact path={route.path} name={route.name} component={route.component}/>);
                            case 'both':
                            default:
                                return(<LayoutRoute exact path={route.path} name={route.name} render={(pageProps) => <route.component {...props} {...pageProps} key={index}/>} key={index}/>);
                                // if(!route.layout) {
                                //     if(route.profileLayout)
                                //         return(
                                //         <ProfileRoute exact path={route.path} name={route.name} render={(pageProps) => <route.component {...props} {...pageProps} key={index}/>} key={index}/>);
                                //     else
                                //         return(<Route exact path={route.path} name={route.name} render={(pageProps) => <route.component {...props} {...pageProps} key={index}/>} key={index}/>);
                                // } else {
                                //     return(<LayoutRoute exact path={route.path} name={route.name} render={(pageProps) => <route.component {...props} {...pageProps} key={index}/>} key={index}/>);
                                // }
                        }
                    })
                }
                <Redirect from="/" to="/login" />
                {/* Default Route */}
                <Route component={Page404}/>
    
            </Switch>
        </Suspense>
        )}
        </>
    );
}
