import React from "react";
// import {ReactComponent as EventInfoIcon} from "../../assets/img/Sidebar/event-info-icon.svg"
// import {ReactComponent as AnalyticsIcon} from "../../assets/img/Sidebar/analytics-icon.svg"
import {
	OverviewIcon,
	EventInfoIcon,
	AnalyticsIcon,
	ParticipantsIcon,
	ContactIcon,
	GamificationIcon,
	PostEventIcon,
	SettlementsIcon,
	AdvancedIcon,
	TicketsNdCouponsIcon,
	FormsIcon,
	EventNdOrganiserIcon,
	DiscountIcon,
	SponsorsIcon,
	AttendeesIcon,
	WaitlistIcon,
	LeadsIcon,
	ApprovalIcon,
	EmailIcon,
	SmsIcon,
	WhatsAppIcon,
	ReferralIcon,
	QuizIcon,
	WidgetIcon,
	AdvancedTemplateIcon,
	AppsIcon,
	SponsorAppIcon,
	WorkshopIcon,
	FeedbackIcon,
	CheckinAppIcon,
	AdvancedEventTemplateIcon,
	BrandingAndCustomizationIcon,
	Integrations,
	AttendeeUploads,
	DeveloperIcon,
	ApiIcons,
	WidgetIcons,
	WebhookIcon,
	Payment,
	PaymentMethods,
	OrganizerIcon,
	TaxNCharges
} from "../../assets/img/Sidebar/index";
import CountBadge from "../../revampComponents/Atom/Badge/CountBadge";
import { getCurrentEventId } from "../../Utils/helpers";

const eventId = getCurrentEventId();

/* 	{
		name: "Overview", -> This is name of the sidebar
		to: `/`, -> This is which route you want it to go when clicked on it
		icon: <OverviewIcon />, -> The SVG icon you want to display next to the sidebar item
		userType: 4 -> what type of user should it be viewable to (read below to see what this number indicates)
	},
*/

/* 	These are what userType number indicates:
	If userType is 1 only Super Admin will be able to see it (reachus@konfhub.com)
	If userType is 2 Super Admin & konfhub team (users with codeops.tech email) will be able to see it,
	If userType is 3 Super Admin, konfhub team & normal user will be able to see it,
	If userType is 4 Super Admin, everyone will be able to see it

	Note: this is how it is done in the FE but actually userType 4 means organizer
*/
const _nav = [
	{
		name: "Overview",
		to: `/`,
		icon: <OverviewIcon />,
		userType: 4
	},
	{
		name: "Analytics",
		to: `/stats`,
		icon: <AnalyticsIcon />,
		userType: 4
	},
	{
		name: "Event Info",
		icon: <EventInfoIcon />,
		userType: 4,
		subMenus: [
			{
				name: "Event Details",
				to: `/eventInfo/eventdetails/basic`,
				icon: <EventNdOrganiserIcon />
			},
			{
				name: "Tickets",
				to: `/eventInfo/tickets/tickets`,
				icon: <TicketsNdCouponsIcon />
			},
			{
				name: "Forms",
				to: `/eventInfo/forms/forms`,
				icon: <FormsIcon />
			},
			{
				name: "Coupons",
				to: `/eventInfo/coupons`,
				icon: <DiscountIcon />
			},
			{
				name: "Organizer Details",
				to: `/eventInfo/organiserDetails`,
				icon: <OrganizerIcon />
			},
			{
				name: "Speakers",
				to: `/eventInfo/speakers`,
				icon: <ParticipantsIcon />
			},
			{
				name: "Agenda",
				to: `/eventInfo/agenda`,
				icon: (
					<i
						className="fas fa-calendar-day"
						aria-hidden="true"
						style={{ color: "#572148" }}
					></i>
				)
			},
			{
				name: "Sponsors",
				to: `/eventInfo/sponsors`,
				icon: <SponsorsIcon />
			},
			{
				name: "Workshop",
				to: `/eventInfo/workshop`,
				icon: <WorkshopIcon />
			}
			// {
			//     name: "Event & Organiser",
			//     to: `/eventInfo/eventdetails`,
			//     icon: <EventNdOrganiserIcon />,
			// },
			// {
			//     name: "Tickets & Coupons",
			//     to: `/eventInfo/ticketsnforms`,
			//     icon: <TicketsNdCouponsIcon />,
			// },
		]
	},
	{
		name: "Participants",
		icon: <i className="fa fa-users" aria-hidden="true"></i>,
		countBadge: true,
		userType: 4,
		subMenus: [
			{
				name: "Attendees",
				to: `/attendees`,
				icon: <AttendeesIcon />,
				countBadge: true
			},
			{
				name: "Team Registrations",
				to: `/team-registrations`,
				icon: (
					<i className="fas fa-users" aria-hidden="true" style={{ color: "#572148" }}></i>
				),
				countBadge: true
			},
			{
				name: "Waitlist",
				to: `/waitlist`,
				icon: <WaitlistIcon />,
				countBadge: true
			},
			{
				name: "Reg. Attempts",
				to: `/leads`,
				icon: <LeadsIcon />,
				countBadge: true
			},
			{
				name: "Approval Requests",
				to: `/approval`,
				icon: <ApprovalIcon />,
				countBadge: true
			},
			{
				name: "Attendee Uploads",
				to: `/attendee-uploads`,
				icon: <AttendeeUploads />,
				countBadge: false
			}
		]
	},
	{
		name: "Contact Attendees",
		icon: <ContactIcon />,
		userType: 4,
		subMenus: [
			{
				name: "Email",
				to: `/email`,
				icon: <EmailIcon />
			},
			{
				name: "SMS",
				to: `/sms`,
				icon: <SmsIcon />
			},
			{
				name: "WhatsApp",
				to: `/whatsapp`,
				icon: <WhatsAppIcon />
			}
		]
	},
	{
		name: "Team",
		to: `/team`,
		icon: <i className="fas fa-users" aria-hidden="true"></i>,
		userType: 4
	},
	{
		name: "Gamification",
		icon: <GamificationIcon />,
		userType: 4,
		subMenus: [
			{
				name: "Referral Contest",
				to: `/ReferralContest`,
				icon: <ReferralIcon />,
				badge: ""
			},
			{
				name: "Quizzes",
				to: `/Quiz`,
				icon: <img src={QuizIcon} style={{ marginLeft: -6 }} />,
				badge: ""
			}
		]
	},
	{
		name: "Payments & Taxes",
		icon: <Payment />,
		userType: 4,
		subMenus: [
			{
				name: "Bank Details",
				to: `/payment/bankDetails`,
				icon: (
					<i
						className="fas fa-university"
						aria-hidden="true"
						style={{ color: "#572148" }}
					></i>
				),
				badge: ""
			},
			{
				name: "Tax & Charges",
				to: `/charges/tax`,
				icon: <TaxNCharges />,
				badge: ""
			},
			{
				name: "Payment Methods",
				to: `/payment/methods`,
				icon: <PaymentMethods />,
				badge: ""
			}
		]
	},
	{
		name: "Post Event",
		icon: <PostEventIcon />,
		userType: 4,
		subMenus: [{ name: "Feedbacks", to: `/feedback`, icon: <FeedbackIcon /> }]
	},
	{
		name: "Settlements",
		to: `/settlements`,
		icon: <SettlementsIcon />,
		userType: 2
	},
	{
		name: "Advanced",
		icon: <AdvancedIcon />,
		userType: 4,
		subMenus: [
			{
				name: "Email Templates",
				to: `/advanced/advancedMail`,
				icon: <AdvancedTemplateIcon />,
				badge: ""
			},
			{
				name: "Event Page Templates",
				to: `/advanced/advancedEventPage`,
				icon: <AdvancedEventTemplateIcon />,
				badge: ""
			},
			{
				name: "White Label",
				to: `/advanced/brandingAndCustomization/branding`,
				icon: <BrandingAndCustomizationIcon />
			}
			// {
			//     name: "Widgets",
			//     to: `/advanced/widget`,
			//     icon: <WidgetIcon />,
			// },
		]
	},
	{
		name: "Apps",
		icon: <AppsIcon />,
		userType: 4,
		subMenus: [
			{
				name: "Sponsor App",
				to: `/SponsorApp`,
				icon: <SponsorAppIcon />,
				badge: ""
			},
			{
				name: "Check-in App",
				to: `/CheckinApp/details`,
				icon: <CheckinAppIcon />,
				badge: ""
			}
		]
	},
	{
		name: "Developers",
		icon: <DeveloperIcon />,
		userType: 4,
		subMenus: [
			{
				name: "Integrations",
				to: `/developers/integrations`,
				icon: <ApiIcons />
			},
			{
				name: "Reg. Buttons",
				to: `/developers/widgets`,
				icon: <WidgetIcons />
			},
			{
				name: "Embed Ticket(s)",
				to: `/developers/embed`,
				icon: <i className="fas fa-code" style={{ color: "#572148" }} />
			},
			{
				name: "Webhook",
				to: `/developers/webhook`,
				icon: <WebhookIcon />
			}
		]
	}
];

export default _nav;
