import { Spinner, RainbowThemeContainer } from "react-rainbow-components";
const theme = {
	rainbow: {
		palette: {
			brand: "#572148"
		}
	}
};
const LoadingSpinner = ({height = 400}) => {
	return (
		<>
			<div className="spinner d-flex justify-content-center align-items-center">
				<RainbowThemeContainer theme={theme}>
					<Spinner style={{zIndex: 99}} size="large" type="arc" variant="brand" />
				</RainbowThemeContainer>
			</div>
			<style>{`
				.spinner {
					height: ${height}px;
				}
				.spinner:before {
					border-top-color: transparent;
				}
			`}</style>
		</>
	);
};

export default LoadingSpinner;
