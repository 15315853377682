import React from "react";
import CustomModal from "./CustomModal";
import warning from "../../../assets/img/Models/warning.svg";
import ReactHtmlParser from "react-html-parser";

const WarningModal = ({
	openModal,
	setOpenModal,
	setLoad,
	onOkay,
	onCancel,
	isLoading,
	item,
	text,
	subText,
	width,
	primaryBtnContent,
	saveBtnDisabled,
	saveBtnContent = null, 
	cancelBtnContent = null
}) => {
	return (
		<>
			<CustomModal
				width={width}
				openModal={openModal}
				setOpenModal={setOpenModal}
				setLoad={setLoad}
				saveBtnContent={saveBtnContent}
				cancelBtnContent={cancelBtnContent}
				content={
					<div className="mt-2 mx-4 d-flex flex-column align-items-center justify-content-center text-center">
						<div>
							<img
								src={warning}
								className="mb-3"
								style={{
									width: "4rem"
								}}
							/>
						</div>
						<p className="formTitle fw-bold mb-2 text-center text-danger">
							{ReactHtmlParser(item)}
						</p>

						<div className="text-center custom_text_style">
							<div className="text-center">{ReactHtmlParser(text)}</div>
						</div>

						{
							subText &&
							<div className="mt-3 mb-3">
								<div className="px-3 py-3 text-center d-flex justify-content-center warning--subtext-box">
									{ReactHtmlParser(subText)}
								</div>
							</div>
						}

					</div>
				}
				isLoading={isLoading}
				onClose={onCancel}
				onCancel={onCancel}
				onOkay={onOkay}
				saveBtnDisabled={saveBtnDisabled}
			/>
			<style>
				{
					`
						.custom_text_style {
							font-family: Hind;
							font-size: 15px;
							font-weight: 400;
							line-height: 24px;
							letter-spacing: 0em;
							text-align: center;
							color: #572148;
						}
						.warning--subtext-box {
							border: 1px solid #E2E2E2;
							background: #F8F8F8;
							border-radius: 5px;
							font-family: Hind;
							font-size: 14px;
							font-weight: 400;
							line-height: 22px;
							letter-spacing: 0em;
							text-align: center;
							color: #6C757D;
						}
					`
				}
			</style>
		</>
	);
};

export default WarningModal;
