// commonAPIs.crud.js
import { api } from "./axios.config";
import axios from "./axios";
import { getCurrentEventId } from "../Utils/helpers";

const API_URL = api.apiUrl;
const BASE_URL = "/event/";

const EVENT_URL = "/event/";

const orgId = `?organiser_id=${localStorage.getItem("user")}`;

// **** Test Mail ********8

export const testEmail = (eventID, payload) => {
	return axios.post(
		API_URL + BASE_URL + eventID + `/send-test-email` + orgId,
		payload
	);
};

// ****************** Attachments ***********************

export const UploadUrl = (eventID, payload) => {
	return axios.post(API_URL + BASE_URL + eventID + `/attachments`, payload);
};

export const GetAttached = (eventID, payload) => {
	return axios.post(API_URL + BASE_URL + eventID + `/get-attachments/`, payload);
};

export const DeleteAttachment = (eventID, fileName) => {
	return axios.delete(
		API_URL + BASE_URL + eventID + `/attachments/` + fileName
	);
};

// ****************** Event ***********************

export const getEventDetails = async eventId => {
	return axios.get(API_URL + EVENT_URL + eventId);
};

export const postEventDetails = eventId => {
	return axios.post(API_URL + EVENT_URL + eventId);
};

export const UpdateEventDetails = (payload, eventId) => {
	return axios.put(API_URL + EVENT_URL + eventId + orgId, payload);
};

// ****************** Tickets ***********************

export const getTickets = eventId => {
	return axios.get(API_URL + EVENT_URL + eventId + "/tickets" + orgId);
};

export const getDeletedTickets = eventId => {
	return axios.get(
		API_URL + EVENT_URL + eventId + "/tickets" + `?includeDeleted=true`
	);
};

// Clone Api

export const cloneEventApiCall = (eventId, payload) => {
	return axios.post(`/event/${eventId}/clone`, payload);
};
