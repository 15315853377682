/** @format */

export const api = {
	apiUrl: process?.env.REACT_APP_API_URL || "https://dev-api.konfhub.com",
	graphQlUrl:
		process?.env.REACT_APP_GRAPHQL_API_URL ||
		"https://dev-sponsor-api.konfhub.com/graphql",
	certhubAPIUrl:
		process?.env.REACT_APP_CERTHUB_API || "https://dev-certhub-api.konfhub.com",
	checkinAppUrl:
		process?.env.REACT_APP_CHECKIN_APP_API_URL ||
		"https://dev-checkin-api.konfhub.com/graphql",
	getQuizzesApiUrl:
		process?.env.REACT_APP_GET_QUIZZES_API_URL ||
		"https://dev-qh-api.konfhub.com",
	quizhubApiUrl:
		process?.env.REACT_APP_QUIZHUB_API_URL ||
		"https://quizhub-testing-apim.azure-api.net",
	filesApiUrl:
		process?.env.REACT_APP_FILES_API || "https://dev-files.konfhub.com"
};
