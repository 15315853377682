import React from "react";

const CreateEvent = React.lazy(
	() => import("./../revampPages/CreateEvent/index"),
);

const EditMyCommunity = React.lazy(
	() => import("./../revampPages/EditMyCommunity/EditMyCommunity"),
);

const ManageEvents = React.lazy(
	() => import("./../revampPages/ManageEvents/ManageEvents"),
);

const Login = React.lazy(() => import("./../pages/Auth/Login"));
// const Signup = React.lazy(() => import('./../pages/Auth/Signup'));
const ForgotPassword = React.lazy(
	() => import("./../pages/Auth/ForgotPassword"),
);
const NewPassword = React.lazy(() => import("./../pages/Auth/NewPassword"));

const Overview = React.lazy(() => import("./../revampPages/Overview/Overview"));

const Analytics = React.lazy(() => import("./../revampPages/Analytics/index"));

const EventDetails = React.lazy(
	() => import("./../revampPages/EventInfo/EventDetails/index"),
);
const Tickets = React.lazy(
	() => import("./../revampPages/EventInfo/Tickets/index"),
);
const Forms = React.lazy(
	() => import("./../revampPages/EventInfo/Forms/index"),
);
const Coupons = React.lazy(
	() => import("./../revampPages/EventInfo/Coupons/index"),
);
const OrganiserDetails = React.lazy(
	() => import("./../revampPages/EventInfo/OrganizerDetails/index"),
);
const Speakers = React.lazy(
	() => import("./../revampPages/EventInfo/Speakers/index"),
);
const Sponsors = React.lazy(
	() => import("./../revampPages/EventInfo/Sponsors/index"),
);
const Workshop = React.lazy(
	() => import("./../revampPages/EventInfo/Workshop/index"),
);
const Agenda = React.lazy(
	() => import("./../revampPages/EventInfo/Agenda/index"),
);

const Attendees = React.lazy(
	() => import("./../revampPages/Participants/Attendees/index"),
);
const TeamRegistrations = React.lazy(
	() => import("./../revampPages/Participants/TeamRegistrations/index"),
);
const Leads = React.lazy(
	() => import("../revampPages/Participants/Leads/index"),
);
const Waitlist = React.lazy(
	() => import("../revampPages/Participants/WaitList/index"),
);

const Approval = React.lazy(
	() => import("../revampPages/Participants/Approval/index"),
);

const AttendeeUploads = React.lazy(
	() => import("../revampPages/Participants/AttendeeUploads/index"),
);

const Team = React.lazy(() => import("../revampPages/Team/index"));

const Email = React.lazy(
	() => import("./../revampPages/ContactAttendees/Email/index"),
);

const EmailAnalytics = React.lazy(() =>
	import("./../revampPages/ContactAttendees/Email/EmailAnalytics/index")
);

const WhatsAppAnalytics = React.lazy(() =>
	import("./../revampPages/ContactAttendees/WhatsApp/WhatsAppAnalytics")
);

const SMS = React.lazy(() =>
	import("./../revampPages/ContactAttendees/SMS/index")
);
const WhatsApp = React.lazy(
	() => import("./../revampPages/ContactAttendees/WhatsApp/index"),
);

const UserCredit = React.lazy(
	() => import("./../revampPages/ContactAttendees/WhatsApp/index"),
);

const ReferralContest = React.lazy(
	() => import("./../revampPages/Gamification/ReferralContest/index"),
);

const Feedback = React.lazy(
	() => import("../revampPages/PostEvent/Feedback/index"),
);
// const Quizzes = React.lazy(() => import('./../revampPages/Gamification/Quiz/Quiz'));

const Settlements = React.lazy(
	() => import("./../revampPages/Settlements/index"),
);
const IndividualSettlement = React.lazy(
	() => import("../revampPages/Settlements/Tables/IndividualSettlement"),
);
const Daywise = React.lazy(
	() => import("../revampPages/Settlements/Tables/DaywiseTransaction"),
);
const Quiz = React.lazy(
	() => import("./../revampPages/Gamification/Quiz/index"),
);

const BankDetails = React.lazy(
	() => import("./../revampPages/Payment&Taxes/Bank Details/index"),
);

const Tax = React.lazy(
	() => import("./../revampPages/Payment&Taxes/Tax&Charges/index"),
);

const PaymentMethods = React.lazy(
	() => import("./../revampPages/Payment&Taxes/PaymentMethods/index"),
);

// const Quiz = React.lazy(() => import('./../revampPages/Gamification/Quiz/index'));

const EmailTemplates = React.lazy(
	() => import("../revampPages/Advanced/EmailTemplates/index"),
);

const EventPageTemplates = React.lazy(
	() => import("../revampPages/Advanced/EventPageTemplates/index"),
);

const BrandingAndCustomization = React.lazy(
	() => import("../revampPages/Advanced/BrandingAndCustomization/index"),
);

const Widget = React.lazy(() => import("../revampPages/Advanced/Widget/index"));

const SponsorApp = React.lazy(
	() => import("../revampPages/Apps/SponsorApp/index"),
);

const CheckinApp = React.lazy(
	() => import("../revampPages/Apps/CheckinApp/index"),
);

const Integrations = React.lazy(
	() => import("../revampPages/Developers/Integrations/index"),
);

const RegisterButton = React.lazy(
	() => import("../revampPages/Developers/Buttons/index"),
);

const EmbedTicket = React.lazy(
	() => import("../revampPages/Developers/EmbedTicket/EmbedTicket"),
);

const Webhook = React.lazy(
	() => import("../revampPages/Developers/Webhook/index"),
);

const StripeConnect = React.lazy(
	() => import("../revampPages/StaticPage/StripeConnect"),
);

const MySecret = React.lazy(() => import("../revampPages/MySecret/MySecret"));

const Routes = [
	{
		path: "/login",
		name: "Login",
		component: Login,
		layout: false,
		authentication: "unauthenticated",
	},
	// { path: "/signup", name: "Signup", component: Signup, layout: false, authentication: "unauthenticated" },
	{
		path: "/forgot-password",
		name: "ForgotPassword",
		component: ForgotPassword,
		layout: false,
		authentication: "unauthenticated",
	},
	{
		path: "/new-password",
		name: "New Password",
		component: NewPassword,
		layout: false,
		authentication: "unauthenticated",
	},

	{
		path: "/create/event",
		name: "Create Event",
		component: CreateEvent,
		layout: false,
		authentication: "authenticated",
	},

	//Edit My Community
	{
		path: "/my-community",
		name: "Edit My Community",
		component: EditMyCommunity,
		layout: false,
		authentication: "authenticated",
	},

	//Manage Events
	{
		path: "/",
		name: "ManageEvents",
		component: ManageEvents,
		layout: true,
		authentication: "authenticated",
		exact: true
	},

	//Dashboard/Overview
	{
		path: "/e/:eventId/",
		name: "Overview",
		component: Overview,
		layout: true,
		authentication: "authenticated",
		exact: true,
	},

	//Analytics
	{
		path: "/e/:eventId/stats",
		name: "Analytics",
		component: Analytics,
		layout: true,
		authentication: "authenticated",
		exact: true,
	},

	//Event Info
	{
		path: "/e/:eventId/eventInfo/eventdetails",
		name: "Event Details",
		component: EventDetails,
		layout: true,
		authentication: "authenticated",
		exact: false,
	},
	{
		path: "/e/:eventId/eventInfo/tickets",
		name: "Tickets",
		component: Tickets,
		layout: true,
		authentication: "authenticated",
		exact: false,
	},
	{
		path: "/e/:eventId/eventInfo/forms",
		name: "Forms",
		component: Forms,
		layout: true,
		authentication: "authenticated",
		exact: false,
	},
	{
		path: "/e/:eventId/eventInfo/coupons",
		name: "Coupons",
		component: Coupons,
		layout: true,
		authentication: "authenticated",
		exact: false,
	},
	{
		path: "/e/:eventId/eventInfo/organiserDetails",
		name: "Organiser Details",
		component: OrganiserDetails,
		layout: true,
		authentication: "authenticated",
		exact: false,
	},
	{
		path: "/e/:eventId/eventInfo/Speakers",
		name: "Speakers",
		component: Speakers,
		layout: true,
		authentication: "authenticated",
		exact: false,
	},
	{
		path: "/e/:eventId/eventInfo/Sponsors",
		name: "Sponsors",
		component: Sponsors,
		layout: true,
		authentication: "authenticated",
		exact: false,
	},
	{
		path: "/e/:eventId/eventInfo/Workshop",
		name: "Workshop",
		component: Workshop,
		layout: true,
		authentication: "authenticated",
		exact: false,
	},
	{
		path: "/e/:eventId/eventInfo/agenda",
		name: "Agenda",
		component: Agenda,
		layout: true,
		authentication: "authenticated",
		exact: false,
	},

	//Participants
	{
		path: "/e/:eventId/attendees",
		name: "Attendees",
		component: Attendees,
		layout: true,
		authentication: "authenticated",
		exact: false,
	},
	{
		path: "/e/:eventId/team-registrations",
		name: "Team Registrations",
		component: TeamRegistrations,
		layout: true,
		authentication: "authenticated",
		exact: false,
	},
	{
		path: "/e/:eventId/leads",
		name: "Leads",
		component: Leads,
		layout: true,
		authentication: "authenticated",
		exact: false,
	},
	{
		path: "/e/:eventId/waitlist",
		name: "Waitlist",
		component: Waitlist,
		layout: true,
		authentication: "authenticated",
		exact: false,
	},
	{
		path: "/e/:eventId/approval",
		name: "Approval Request",
		component: Approval,
		layout: true,
		authentication: "authenticated",
		exact: false,
	},
	{
		path: "/e/:eventId/attendee-uploads",
		name: "Attendee Uploads",
		component: AttendeeUploads,
		layout: true,
		authentication: "authenticated",
		exact: false,
	},

	//Contact Attendees
	{
		path: "/e/:eventId/email/:campaignId",
		name: "Email Analytics",
		component: EmailAnalytics,
		layout: true,
		authentication: "authenticated",
		exact: false
	},
	{
		path: "/e/:eventId/email",
		name: "Email",
		component: Email,
		layout: true,
		authentication: "authenticated",
		exact: true,
	},
	{
		path: "/e/:eventId/sms",
		name: "SMS",
		component: SMS,
		layout: true,
		authentication: "authenticated",
		exact: true,
	},
	{
		path: "/e/:eventId/whatsapp/analytics/:campaignId",
		name: "WhatsApp Analytics",
		component: WhatsAppAnalytics,
		layout: true,
		authentication: "authenticated",
		exact: false
	},
	{
		path: "/e/:eventId/whatsapp",
		name: "WhatsApp",
		component: WhatsApp,
		layout: true,
		authentication: "authenticated",
		exact: true,
	},
	{
		path: "/e/:eventId/whatsapp/UserCredit",
		name: "User Credit",
		component: UserCredit,
		layout: true,
		authentication: "authenticated",
		exact: true,
	},

	// Organizers
	{
		path: "/e/:eventId/team",
		name: "Team",
		component: Team,
		layout: true,
		authentication: "authenticated",
		exact: true,
	},

	// Gamification
	{
		path: "/e/:eventId/ReferralContest",
		name: "Referral Contest",
		component: ReferralContest,
		layout: true,
		authentication: "authenticated",
		exact: false,
	},
	// Payment Settings
	{
		path: "/e/:eventId/payment/bankDetails",
		name: "Bank Details",
		component: BankDetails,
		layout: true,
		authentication: "authenticated",
		exact: true,
	},
	{
		path: "/e/:eventId/charges",
		name: "Tax",
		component: Tax,
		layout: true,
		authentication: "authenticated",
		exact: false,
	},
	{
		path: "/e/:eventId/payment/methods",
		name: "Payment Methods",
		component: PaymentMethods,
		layout: true,
		authentication: "authenticated",
		exact: true,
		restrictionMessage: "Only UPI is enabled in your plan. Upgrade your plan to enable more payment methods like Cards, Netbanking, etc"
	},
	// {path: "/e/:eventId/Quizzes", name: "Quizzes", component: Quizzes, layout: true, authentication: "authenticated", exact: false},

	//Post Events
	{
		path: "/e/:eventId/feedback",
		name: "Feedback Forms",
		component: Feedback,
		layout: true,
		authentication: "authenticated",
		exact: true,
	},

	//Settlements
	{
		path: "/e/:eventId/settlements",
		name: "Settlements",
		component: Settlements,
		layout: true,
		authentication: "authenticated",
		exact: true,
	},
	{
		path: "/e/:eventId/:settlementsId/settlements_table",
		name: "Individual Settlement",
		component: IndividualSettlement,
		layout: true,
		authentication: "authenticated",
		exact: true,
	},
	{
		path: "/e/:eventId/:transactionDate/transaction_table",
		name: "Daywise",
		component: Daywise,
		layout: true,
		authentication: "authenticated",
		exact: true,
	},

	// Quiz
	{
		path: "/e/:eventId/Quiz",
		name: "Quiz",
		component: Quiz,
		layout: true,
		authentication: "authenticated",
		exact: false,
	},

	// // Quiz
	// {path: "/e/:eventId/Quiz", name: "Quiz", component: Quiz, layout: true, authentication: "authenticated", exact: false},

	// Advanced - Email Templates
	{
		path: "/e/:eventId/advanced/advancedMail",
		name: "Email Templates",
		component: EmailTemplates,
		layout: true,
		authentication: "authenticated",
		exact: false,
		restrictionMessage: "Email templates cannot be customized in your plan. Upgrade your plan to access the feature."
	},
	// {path: "/e/:eventId/advanced/widget", name: "EmailTemplates", component: Widget, layout: true, authentication: "authenticated", exact: false},

	{
		path: "/e/:eventId/advanced/advancedEventPage",
		name: "Event Page Templates",
		component: EventPageTemplates,
		layout: true,
		authentication: "authenticated",
		exact: false,
	},

	{
		path: "/e/:eventId/advanced/brandingAndCustomization",
		name: "White Label",
		component: BrandingAndCustomization,
		layout: true,
		authentication: "authenticated",
		exact: false,
	},
	//Apps - Sponsor App

	{
		path: "/e/:eventId/SponsorApp",
		name: "Sponsor App",
		component: SponsorApp,
		layout: true,
		authentication: "authenticated",
		exact: false,
	},
	{
		path: "/e/:eventId/CheckinApp",
		name: "Check-in App",
		component: CheckinApp,
		layout: true,
		authentication: "authenticated",
		exact: false,
	},

	//Developers
	{
		path: "/e/:eventId/developers/integrations",
		name: "Integrations",
		component: Integrations,
		layout: true,
		authentication: "authenticated",
		exact: false,
	},
	{
		path: "/e/:eventId/developers/widgets",
		name: "Registeration Buttons",
		component: RegisterButton,
		layout: true,
		authentication: "authenticated",
		exact: false,
	},
	{
		path: "/e/:eventId/developers/embed",
		name: "Embed Tickets",
		component: EmbedTicket,
		layout: true,
		authentication: "authenticated",
		exact: false,
	},
	{
		path: "/e/:eventId/developers/webhook",
		name: "Webhooks",
		component: Webhook,
		layout: true,
		authentication: "authenticated",
		exact: false,
	},
	{
		path: "/e/:eventId/secret",
		name: "Secret Page",
		component: MySecret,
		layout: true,
		authentication: "authenticated",
		exact: false,
	},
];

export default Routes;
