import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import CountBadge from "../../revampComponents/Atom/Badge/CountBadge";

const SubMenuItem = ({
	subItems,
	navItemName,
	navItem,
	eventId,
	pathname,
	eventCommonDetails,
	reloadDetails,
	isLoading
}) => {
	const getBadge = type => {
		// Only for Participants sub Menu
		switch (type) {
			case "Attendees":
				return eventCommonDetails.attendees_count;
			case "Reg. Attempts":
				return eventCommonDetails.lead_count;
			case "Waitlist":
				return eventCommonDetails.waitlist_count;
			case "Participants":
				return eventCommonDetails.attendees_count;
			case "Approval Requests":
				return eventCommonDetails.approval_count;
			case "Team Registrations":
				return eventCommonDetails.team_count;
			default:
				break;
		}
	};

	return (
		<ul className="sub-menu">
			<li>
				{navItem.to ? (
					<Link className="link_name" to={`/e/${eventId}${navItem.to}`}>
						{navItemName}
						{/*navItem.name === "Settlements" && <div className="link_name ml-2"><span className="badge badge-info sub-link">Beta</span></div>*/}
					</Link>
				) : (
					<a className="link_name">
						{navItemName}
						{navItem.countBadge && (
							<CountBadge
								className="countBadge-margin-subitem"
								count={getBadge(navItem.name)}
								onReload={() => reloadDetails(eventId)}
								isLoading={isLoading}
							/>
						)}
					</a>
				)}
			</li>
			{subItems &&
				subItems.map((subItem, i) => {
					const subItemPath = `/e/${eventId}${subItem.to}`;
					return (
						<li key={i} className={subItemPath === pathname ? "selected" : " "}>
							<Link to={subItemPath}>
								<div className="sub-icon-wrapper">{subItem.icon}</div>
								<span className="sub-link">{subItem.name}</span>
								{(subItem.name === "Agenda") && <span className="badge badge-info sub-link ml-2">Beta</span>}
								{subItem.countBadge && (
									<CountBadge
										className="countBadge-margin-subitem"
										count={getBadge(subItem.name)}
										onReload={() => reloadDetails(eventId)}
										isLoading={isLoading}
									/>
								)}
							</Link>
						</li>
					);
				})}
			<style>
				{`
                    .countBadge-margin-subitem {
                        margin-left: auto !important;
                        margin-right: 12px;
                    }
                    .sidebar.closed .countBadge-margin-subitem {
                        margin-left: auto !important;
                        margin-right: 0px;
                    }
                `}
			</style>
		</ul>
	);
};

export default SubMenuItem;
