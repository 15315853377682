export const CHAR_LIMITS = {
	// About and Basic MAX Character
	about: {
		descMax: 45000,
		basicDescMax: 900,
		speakersLimit: 32767
	},
	// Ticket Section MAX Character
	ticket: {
		descMax: 5000,
		categoryNameMax: 250,
		categoryDescMax: 5000
	},
	// Organizer Section MAX Character
	organizer: {
		descMax: 30000
	},
	// Speaker Section MAX Character
	speaker: {
		sectionTitleMax: 25,
		sectionDescMax: 30000,
		commonMax: 128,
		commonLess: 129,
		aboutMax: 30000
	},
	// Sponsors Section MAX Character
	sponsor: {
		sectionTitleMax: 25,
		sectionTitleLess: 26,
		sectionDescMax: 30000,
		nameMax: 128,
		nameLess: 129,
		urlMax: 128,
		urlLess: 129,
		categoryNameMax: 128,
		categoryNameLess: 129
	},
	// Workshop Section MAX Character
	workshop: {
		sectionTitleMax: 25,
		sectionTitleLess: 26,
		sectionDescMax: 30000,
		titleMax: 1000,
		descMax: 30000
	}
};
