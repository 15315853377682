import React from "react";
import Select from "react-select";

const customStyles = {
	option: (styles, { isFocused, isSelected }) => ({
		...styles,
		background: isSelected ? "#572148" : undefined,
		margin: "0px",
		color: isSelected ? "white" : undefined,
		zIndex: 10,
		overflowX: "hidden",
		wordBreak: "break-word"
	}),
	menu: (provided, state) => ({
		...provided,
		marginTop: "2px",
		overflow: "hidden"
		// zIndex: 10,
	}),
	menuPortal: base => ({ ...base, zIndex: 9999 }),
	dropdownIndicator: base => ({
		...base,
		color: "#572148",
		padding: "5px 8px",
		zIndex: 10,
		"&:hover": {
			color: "#572148"
		}
	}),
	control: (base, state) => ({
		...base,
		border: state.isFocused ? "1px solid #572148" : "1px solid #cdd4db",
		boxShadow: "none",
		borderRadius: "2px",
		// zIndex: 10,
		minHeight: "36px"
		// width: "100%"
	})
};

const Dropdown = ({
	options,
	disabled,
	id,
	value,
	onChange,
	editable,
	placeholder,
	getOptionLabel,
	getOptionValue,
	defaultValue,
	className,
	containerClass,
	menuShouldScrollIntoView = false
}) => {
	return (
		<>
			<div className={containerClass ? containerClass : ""}>
				{id === "ticketForm" ? (
					<Select
						isDisabled={disabled}
						id={id}
						name={id}
						defaultValue={value}
						onChange={e => onChange(e)}
						className={id}
						placeholder={editable == "Edit" ? value : placeholder}
						styles={customStyles}
						options={options}
						maxMenuHeight={170}
					/>
				) : id == "mappingSelect" ? (
					<Select
						isDisabled={disabled}
						id={id}
						name={id}
						defaultValue={value}
						onChange={e => onChange(e)}
						placeholder={placeholder}
						className={id}
						styles={customStyles}
						options={options}
						getOptionLabel={getOptionLabel}
						getOptionValue={getOptionValue}
						components={components}
					/>
				) : (
					<Select
						// menuPortalTarget={document.body}
						menuShouldScrollIntoView={menuShouldScrollIntoView}
						isDisabled={disabled}
						id={id}
						name={id}
						defaultValue={value}
						value={value}
						placeholder={placeholder}
						onChange={e => onChange(e)}
						className={className ? className : id}
						styles={customStyles}
						options={options}
						// maxMenuHeight={"auto"}
						menuPlacement="auto"
						menuPortalTarget={document.querySelector("body")}
					/>
				)}
			</div>

			<style>{`
				.${id} {
					border-radius: 2px;
					height: 2.5em;
					line-height: 1.5;

					font-size: 0.9rem;
					max-height: 2.5em;
				}
				.css-1aieuwr-control {
					max-height: 2.5em;
				}
				.css-883u3f-menu {
					z-index: 10 !important;
				}
			`}</style>
		</>
	);
};

export default Dropdown;
