import React, { useContext, useState } from "react";
import BasicModal from "../../revampComponents/Atom/Modals/BasicModal";
import {
	GoldPlanFeaturesDomestic,
	GoldPlanFeaturesInternational,
	LitePlanFeaturesDomestic,
	LitePlanFeaturesInternational,
	errorToaster,
	paymentMethods,
	silverPlanFeaturesDomestic,
	silverPlanFeaturesInternational,
	successToaster
} from "../../reusable/common";
import WarningModal from "../../revampComponents/Atom/Modals/WarningModal";
import { changeEventPlan } from "../../revampCRUD/eventDetails.crud";
import { CustomModal } from "../../revampComponents/Atom/Modals";
import successImage from "../../assets/img/sucess-two.svg";
import DashboardContext from "../../contexts/DashboardContext";
import { getEventPlan } from "../../revampCRUD/payments.crud";
import { Spinner } from "react-rainbow-components";
const domesticPlans = {
	2: {
		planName: "Lite",
		planFeatures: LitePlanFeaturesDomestic,
		featuresOf: "Freemium",
		amount: "2",
		plusAmountText: " + 18% GST*",
		extraChargeText: "And Nothing Extra!",
		btnText: "Switch to Lite Plan",
		meantForText: "Meant for Small Events (UPI Only) "
	},
	3: {
		planName: "Silver",
		planFeatures: silverPlanFeaturesDomestic,
		featuresOf: "Lite",
		amount: "3.75",
		plusAmountText: " + 18% GST*",
		extraChargeText: "Inclusive of Payment Gateway Charges",
		btnText: "Contact Us",
		meantForText: "Meant for Mid-sized Events"
	},
	4: {
		planName: "Gold",
		planFeatures: GoldPlanFeaturesDomestic,
		featuresOf: "Silver",
		amount: "4.25",
		plusAmountText: " + 18% GST*",
		extraChargeText: "Inclusive of Payment Gateway Charges",
		btnText: "Contact Us",
		meantForText: "Meant for Large Events"
	}
};

const internationalPlans = currentPlan => ({
	2: {
		planName: "Lite",
		planFeatures: LitePlanFeaturesInternational,
		featuresOf: "Freemium",
		amount: "$499",
		plusAmountText: " Per Event",
		extraChargeText: null,
		btnText: currentPlan === 0 ? "Purchase Plan" : "Contact Us",
		meantForText: "Meant for Small Events (UPI Only) "
	},
	3: {
		planName: "Silver",
		planFeatures: silverPlanFeaturesInternational,
		featuresOf: "Lite",
		amount: "$1499",
		plusAmountText: " Per Event",
		extraChargeText: null,
		btnText: currentPlan === 0 ? "Purchase Plan" : "Contact Us",
		meantForText: "Meant for Mid-sized Events"
	},
	4: {
		planName: "Gold",
		planFeatures: GoldPlanFeaturesInternational,
		featuresOf: "Silver",
		amount: "$2999",
		plusAmountText: " Per Event",
		extraChargeText: null,
		btnText: currentPlan === 0 ? "Purchase Plan" : "Contact Us",
		meantForText: "Meant for Large Events"
	}
});
export default function PricingPlanModal({
	history,
	reloadApi,
	eventPlan,
	eventId,
	isInternational,
	hasStripeOrTap,
	subscriptionPaymentId
}) {
	if (!subscriptionPaymentId && isInternational) {
		eventPlan = 0; // Technically no eventPlan selected until stripe or tap is added
	}
	const [openConfirmation, setOpenConfirmation] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const plans = !isInternational ? domesticPlans : internationalPlans(eventPlan);
	const params = new URLSearchParams(history.location.search);
	const featureName = params.get("plan");
	const planModalHeading = search => {
		if (featureName !== "Lite") {
			return `${featureName ? featureName : "This feature"} is not available in your current plan. <a href="mailto:reachus@konfhub.com">Upgrade</a> your plan to access this feature.`;
		}
		if (isInternational && !subscriptionPaymentId) {
			return "To connect your payment gateway and receive payments, please purchase a paid plan first.";
		}
		return "Switch plan";
	};

	const buyPlanHandle = async event_plan => {
		try {
			setIsLoading(event_plan);
			const res = await getEventPlan(eventId, event_plan, 6);
			if (res.status === 200 && res.data.paid === true) {
				history.push(`/e/${eventId}/payment/bankDetails?event_plan=${event_plan}`);
			} else if (res.status === 200 && res.data.paid === false) {
				window.location.replace(`${res.data.url}`);
			}
			setIsLoading(false);
		} catch (err) {
			const errorMessage = err.response || "Something went wrong";
			setIsLoading(false);
			customToast.error(errorMessage.data.error);
		}
	};

	return (
		<>
			<BasicModal
				openModal={featureName}
				onClose={() => history.push(history.location.pathname)}
			>
				<h5
					className="text-center mb-4 pricing-plan-brown-text"
					dangerouslySetInnerHTML={{ __html: planModalHeading(history.location.search) }}
				></h5>
				<div className="row justify-content-center px-3">
					{Object.keys(plans).map(planType => (
						<div
							key={plans[planType].planName}
							className={`col-12 col-lg-6 pricing-card-plan p-3 mr-3 ml-3 mb-3 ${Number.parseInt(planType) === eventPlan ? "border-pricing-plan" : ""}`}
						>
							<div className="free_event">
								<h1 className="plan_type">{plans[planType].planName}</h1>
								<div>
									<h2 className="plan_amount d-inline-block">
										{plans[planType].amount}
									</h2>
									<span className="pricing-plan-brown-text">
										{plans[planType].plusAmountText}
									</span>
								</div>
								<p className="nunito-font-pricing pricing-plan-brown-text mb-2">
									{plans[planType].extraChargeText}
								</p>
								<button
									type="button"
									className={`nunito-font-pricing btn ${Number.parseInt(planType) === eventPlan ? "btn-explore" : "btn-theme"} pricing-plan-btn-weight  w-100`}
									disabled={Number.parseInt(planType) === eventPlan}
									onClick={() => {
										if (plans[planType].btnText === "Contact Us") {
											window.location = "mailto:reachus@konfhub.com";
										} else {
											if (isInternational) {
												buyPlanHandle(planType);
											} else {
												history.push(history.location.pathname);
												setOpenConfirmation(true);
											}
										}
									}}
								>
									{isLoading === planType ? (
										<div
											className="position-relative"
											style={{ padding: "0.8rem 0rem" }}
										>
											<Spinner variant="base" size="x-small" type="arc" />
										</div>
									) : Number.parseInt(planType) === eventPlan ? (
										"Current Plan"
									) : (
										plans[planType].btnText
									)}
								</button>
								<hr />
							</div>
							<div className="">
								<p className="nunito-font-pricing mb-0 bold-text-pricing features-subText">
									All Features in {plans[planType].planName} Plan{" "}
								</p>
								<p className="nunito-font-pricing mb-0 features-subText">
									{plans[planType].meantForText}
								</p>
								<ul className="nunito-font-pricing list px-0 py-3">
									{plans[planType].planFeatures.map(features => features)}
								</ul>
							</div>
						</div>
					))}
				</div>
				<style>{`
            	.pricing-card-plan {
					min-width: 320px;
					max-width: 320px;
					color: #1C1C1C;
					font-family: "Nunito", sans-serif ;
					box-shadow: 0px 5px 30px 2px #57214833;
					border-radius: 8px;
				}
				.bold-text-pricing {
					font-weight: 600;
					font-family: "Nunito", sans-serif;
					color: #525252;
				}
				.plan_type {
					font-size: 22px;
					font-weight: 600;
					color: #572148;
					font-family: "Nunito", sans-serif;
				}
				.plan_amount {
					font-size: 40px;
					color: #572148;
					font-weight: 600;
					font-family: "Nunito", sans-serif;
				}
				.nunito-font-pricing {
					font-family: "Nunito", sans-serif;
				}
				ul.nunito-font-pricing > li {
					font-family: inherit;
					font-size: 14px;
					margin-bottom: 7px;
				}
				.list{
  					padding: 0;
  					margin: 0;
  					list-style-type: none;
				}
				.tick_style {
      				width: 15px;
      				margin-top: -2px;
    			}
				.pricing-plan-brown-text{
					color: #572148;
				}
				.pricing-plan-btn-weight {
					font-weight: 600 !important;
					font-size: 16px;
				}
				.btn-theme.nunito-font-pricing:hover {
					background: #fff;
					color: #FB5850;
					border: 2px solid #FB5850;
				}
				.btn-explore.nunito-font-pricing:hover {
					background: #fff;
					color: #FB5850;
					cursor: not-allowed;
				}
				.border-pricing-plan {
					border: 3px solid #572148;
				}
                .features-subText {
                    color: #525252;
                    font-size: 15px;
                }
            `}</style>
			</BasicModal>
			<PricingPlanConfirmModal
				openModal={openConfirmation}
				setOpenModal={setOpenConfirmation}
				reloadApi={reloadApi}
				eventId={eventId}
			/>
		</>
	);
}
const PricingPlanConfirmModal = ({ openModal, setOpenModal, reloadApi, eventId }) => {
	const [isLoading, setIsLoading] = useState(false);
	const { switchedPlan, setSwitchedPlan } = useContext(DashboardContext);
	const eventPlanHandler = async plan => {
		setIsLoading(true);
		const payload = { event_plan: Number.parseInt(plan) };
		try {
			const res = await changeEventPlan(eventId, payload);
			reloadApi(eventId);
			setIsLoading(false);
			setSwitchedPlan(plan === 2 ? "Lite" : "Silver");
			// successToaster("You have changed your plan to Lite plan successfully")
		} catch (err) {
			setIsLoading(false);
			console.log(err.message);
			errorToaster(err.message);
		}
	};
	return (
		<>
			<WarningModal
				openModal={openModal}
				setOpenModal={setOpenModal}
				text={
					"Please confirm that you wish to switch to Lite plan which is an irreversible action."
				}
				subText={
					"Please note that by doing so, you will not be able to switch to Silver plan again."
				}
				saveBtnContent="Yes, proceed"
				isLoading={isLoading}
				cancelBtnContent="No"
				onCancel={() => setOpenModal(false)}
				onOkay={() => eventPlanHandler(2)}
			/>
			<CustomModal
				openModal={switchedPlan}
				setOpenModal={setSwitchedPlan}
				saveBtnContent={null}
				header={null}
				floatingButton={null}
				floatingCancelButton="Close"
				content={
					<div className="d-flex flex-column justify-content-center align-items-center">
						<img src={successImage} alt="successImage" className="mb-3" />
						<p className="success-text">
							Your plan has been changed to {switchedPlan} successfully.
						</p>
					</div>
				}
			/>
		</>
	);
};
