import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import KonfhubContainer from "../Container";

const BasicModal = ({
    openModal,
    children,
    styles,
    width,
    className,
    overflow,
    height,
    onClose,
    noClose = false,
    setOpenModal = () => {},
}) => {
    const customStyles = {
        // Style for the Modal
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            padding: "0px",
            maxWidth: "90%",
            maxHeight: "calc(100% - 75px)",
            width: width ? width : 'auto',
            height: height ? height : 'auto',
            overflow: overflow ? overflow : "auto",
            overflowX: 'auto'
        },
        overlay: {
            background: "rgba(0,0,0,0.75)",
            height: "100%",
            zIndex: "100",
        }
    };
    return (
        <>
            <Modal
                ariaHideApp={false}
                isOpen={openModal}
                // shouldCloseOnEsc={false}
                // shouldCloseOnOverlayClick={false}
                onRequestClose={() => { setOpenModal(false) }}
                className={className}
                style={styles ? styles : customStyles}>
                <KonfhubContainer>
                    {
                        !noClose &&
                        <div className="row w-100 ml-0 d-flex justify-content-end">
                            <button
                                type="button"
                                className="iconOnly"
                                onClick={() => { setOpenModal(false); if (onClose) onClose() }}
                            >
                                <i className="fas fa-times closeIcon" />
                            </button>
                        </div>
                    }
                    {children}
                </KonfhubContainer>
            </Modal>

            <style>{`
				.subHeader{
					margin-top: -25px;
					font-weight: 500;
					font-size: 15px;
					line-height: 24px;
					color: #572148;
				}
				.closeIcon {
					font-size: 1rem;
					color: black;
					opacity: 0.5;
				}
				.closeIcon:hover {
					// color: black;
					opacity: 0.8;
				}
                .iconOnly {
                    background: none;
                    border: none;
                }
			`}</style>
        </>
    );
};

export default BasicModal;
