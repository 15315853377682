import React from "react"
import {createStore} from "redux"


const initialState = {
    sidebarIsOpen: true
}

const changeState = (state = initialState, {type}) => {
    switch(type) {
        case "toggle": 
            return {
                sidebarIsOpen: !state.sidebarIsOpen
            }
        default: 
            return state;
        }

}

const store = createStore(changeState)

export default store;