import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { LastLocationProvider } from "react-router-last-location";
// import { onAuthUIStateChange } from '@aws-amplify/ui-components';
import * as workerTimers from "worker-timers";
import Cookies from "js-cookie";

import Modal from "react-modal";

import axios from "./axios/axios"; //Temporary

import Amplify, { Auth } from "aws-amplify";
import awsconfig from "./config/aws.exports";
Amplify.configure(awsconfig);

import AuthenticatedRoutes from "./routes/AuthenticatedRoutes";
import UnauthenticatedRoutes from "./routes/UnauthenticatedRoutes";

import AuthContext from "./contexts/AuthContext";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "font-awesome/css/font-awesome.min.css";
import "./assets/scss/style.scss";
// import "@fortawesome/fontawesome-free/css/all.min.css";
import "react-toastify/dist/ReactToastify.css";
import "react-loading-skeleton/dist/skeleton.css";
import "tippy.js/dist/tippy.css";
import KonfhubLoad from "./components/KonfhubLoad";
import { setAccessToken, setJwtToken } from "./revampCRUD/axios";
import { sortEventDetails } from "./Utils/helpers";
import { getOrganiserEventsDetails } from "./revampCRUD/app.crud";
import { getCommunityDetails } from "./revampCRUD/myCommunity.crud";
import { errorToaster } from "./reusable/common";

import TagManager from "react-gtm-module";

const tagManagerArgs = {
	gtmId: "GTM-KHHN35F"
};

TagManager.initialize(tagManagerArgs);

Modal.setAppElement("#root");

//Pages
const Page404 = React.lazy(() => import("./pages/Page404"));
// const Page500 = React.lazy(() => import('./pages/Page500'));

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user: { attributes: {} },
			signedIn: null,
			refreshToken: null,
			organiserDetails: null,
			communityDetails: null,
			eventInfo: {}
		};
		// onAuthUIStateChange(newAuthState  => {
		// 	this.checkSession()
		// })
	}

	componentDidMount() {
		this.checkSession();
	}

	checkSession = async () => {
		let user = { attributes: {} },
			signedIn = false;
		let organiserId, organiserDetails, communityDetails;
		try {
			user = await Auth.currentAuthenticatedUser({
				bypassCache: true
			});
			Cookies.set("userEmail", user.attributes.email);
			signedIn = true;
			organiserId = user.attributes.sub;
			// localStorage.setItem("jwtToken", user.signInUserSession.idToken.jwtToken);
			// localStorage.setItem('userEmail', user.attributes.email);
			// localStorage.setItem('userName', user.attributes.name);
			// localStorage.setItem('user', organiserId);
			localStorage.setItem("eventId", user.attributes["custom:lastEvent"]);
			setJwtToken(user.signInUserSession.idToken.jwtToken);
			setAccessToken(user.signInUserSession.accessToken.jwtToken);
			this.refreshToken();
			organiserDetails = await this.getOrganiserDetails();
			try {
				communityDetails = await getCommunityDetails();
			} catch (err) {
				if (err.response.status === 404) communityDetails = null;
				else throw new Error(err.message);
			}
		} catch (err) {
			console.log("cs -- error log -- ", err);
		}

		this.setState({
			...this.state,
			user,
			signedIn,
			organiserDetails,
			communityDetails
		});
	};

	refreshToken = async () => {
		const refreshToken = workerTimers.setInterval(
			async () => {
				try {
					const user = await Auth.currentAuthenticatedUser();
					// const session = await Auth.currentSession();
					const session = await Auth.currentSession();
					// const session = await Auth.currentSession();
					// const idToken =  session.getIdToken().getJwtToken();
					// localStorage.setItem("jwtToken", idToken.jwtToken);
					user.refreshSession(session.refreshToken, (err, session) => {
						const { idToken, accessToken } = session;
						setJwtToken(idToken.jwtToken);
						setAccessToken(accessToken.jwtToken);
					});
				} catch (err) {
					clearInterval(refreshToken);
					console.log("cs -- error log -- ", err);
					window.location.href = "/logout";
				}
			},
			60 * 60 * 1000
		);
		this.setState({ ...this.state, refreshToken });
	};

	getOrganiserDetails = async () => {
		let organiserDetails = [];
		try {
			const orgRes = await getOrganiserEventsDetails();
			if (orgRes.status == 200) {
				let temp = [];
				temp = orgRes.data;
				organiserDetails = sortEventDetails(temp);
				return organiserDetails;
			} else if (orgRes.status == 404) {
				errorToaster("There is no user with this Id, please log in again");
				this.setAuth({}, false);
			}
		} catch (err) {
			console.log(err);
			errorToaster("Something went wrong, please try again later");
		}
	};

	setAuth = async (user = {}, signedIn = true) => {
		let organiserDetails;
		clearInterval(this.state.refreshToken);
		if (signedIn) {
			await this.refreshToken();
			organiserDetails = await this.getOrganiserDetails();
		}
		this.setState({ ...this.state, user, signedIn, organiserDetails });
	};

	refreshOrganiserDetails = async () => {
		const organiserDetails = await this.getOrganiserDetails();
		this.setState({ ...this.state, organiserDetails });
	};

	setCommunityDetails = (communityDetails = null) => {
		this.setState({ ...this.state, communityDetails });
	};

	setEventDetails = async eventInfo => {
		this.setState({ ...this.state, eventInfo });
	};

	render() {
		const { user, signedIn, refreshToken, organiserDetails, communityDetails, eventInfo } =
			this.state;

		return (
			<>
				<div className="content-container h-100 d-flex flex-column">
					<AuthContext.Provider
						value={{
							user,
							signedIn,
							setAuth: this.setAuth,
							eventInfo,
							setEventDetails: this.setEventDetails,
							refreshToken,
							organiserDetails,
							communityDetails,
							refreshOrganiserDetails: this.refreshOrganiserDetails,
							setCommunityDetails: this.setCommunityDetails
						}}
					>
						<BrowserRouter
							getUserConfirmation={() => {
								/* Empty callback to block the default browser prompt */
							}}
						>
							<LastLocationProvider>
								<React.Suspense fallback={<KonfhubLoad />}>
									<Switch>
										<Route
											exact
											path="/404"
											name="Page 404"
											render={props => <Page404 {...props} />}
										/>
										{/* <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} /> */}
										{signedIn === null ? (
											<KonfhubLoad />
										) : (
											<>
												{signedIn ? (
													<AuthenticatedRoutes
														signedIn={signedIn}
														user={user}
													/>
												) : (
													<>
														<UnauthenticatedRoutes
															signedIn={signedIn}
															user={user}
														/>
													</>
												)}
											</>
										)}
									</Switch>
								</React.Suspense>
							</LastLocationProvider>
						</BrowserRouter>
					</AuthContext.Provider>
				</div>
				<style>{`
				.content-container {
					background-color: ${localStorage.getItem("darkMode") ? "#2f2f2f" : "auto"};
				}
			`}</style>
			</>
		);
	}
}

export default App;
