import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";

import TippyIcon from "../TippyIcon";
import { KonfhubButton } from "../Buttons";
import CustomModal from "../Modals/CustomModal";
import Dropdown from "../Dropdown";
import { successToaster, errorToaster } from "../../../reusable/common";

import { getTickets } from "../../../revampCRUD/ticketsNforms.crud";
import Warning from "../../../assets/pages/Section/Participants/attendee-upload/Warning.svg";
import Download from "../../../assets/pages/Section/Participants/attendee-upload/Download.svg";
import Success from "../../../assets/pages/Section/Participants/attendee-upload/Success.svg";
import {
	downloadSampleCSV,
	uploadBulkAttendees,
	uploadBulkAttendeesUrl
} from "../../../revampCRUD/Participants/Attendees.crud";
import { getUploads } from "../../../revampCRUD/Participants/AttendeeUploads.crud";

const customStyles = {
	// Style for the Modal
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		padding: 0,
		width: "100%",
		maxWidth: "410px",
		maxHeight: "60vh",
	},
	overlay: {
		background: "rgba(0,0,0,0.75)"
	}
};

const BulkAttendees = ({ eventId, getUploadsData, setLoad, type, view, bulkUploadDetails, prop = {}}) => {
	const [modal, setModal] = useState(false); // Modal state
	const [uploadDetails, setUploadDetails] = useState(null); // upload details from api

	const [bulkAttendeeErrorResponse, setBulkAttendeeErrorResponse] = useState(null); // bulk attendee Error response after upload
	const [exceededMaxUpload, setExceededMaxUpload] = useState(false);
	const [invalidFileFormat, setInvalidFileFormat] = useState(false);
	const [noDetailsFound, setNoDetailsFound] = useState(false);
	
	const [loadingUploadDetails, setLoadingUploadDetails] = useState(false); // loading while fetching upload details
	const [isLoading, setLoading] = useState(false); // Loading for button
	const [loadingUploadFile, setLoadingUploadFile] = useState(false); // while uploading a file
	// If the bulk upload is from the attendees section, then need to show a confirmation message post upload
	const [confirmationModal, setConfirmationModal] = useState(false);

	const [tickets, setTickets] = useState(false); //get ticket data
	const [selectTicket, setSelectedTicket] = useState({});
	const [file, setFile] = useState();
	const [uploadUrl, setUploadUrl] = useState(null);
	const [sendConfirmationMail, setSendConfirmationMail] = useState(false);

	const [successCount, setSuccessCount] = useState(0);
	const [errorCount, setErrorCount] = useState(0);
	const [errorLink, setErrorLink] = useState(null);
	const history = useHistory();

	const getUploadDetailsFromApi = async() => {
		setLoadingUploadDetails(true);
		try {
			const response = await getUploads(eventId);
			setUploadDetails(response.data);
		} catch (error) {
			console.log(error);
			errorToaster(error?.message || "Something went wrong");
		} finally {
			setLoadingUploadDetails(false);
		}
	}

	useEffect(() => {
		if(modal){
			setFile();
			setErrorCount(null);
			setErrorLink(null);
			setBulkAttendeeErrorResponse(null);
			setExceededMaxUpload(false);
			setInvalidFileFormat(false);
			setNoDetailsFound(false);
			getUploadDetailsFromApi();
		}
	}, [modal]);

	const getTicketsData = async () => {
		try {
			const res = await getTickets(eventId);
			let newTicketDet = res.data.uncategorized
				// .filter(ticket => ticket.is_free)
				.map(ticket => {
					return {
						label: ticket.ticket_name,
						value: ticket.ticket_id
					};
				});
			res.data.categorized.forEach(category => newTicketDet = [ ...newTicketDet, ...category.tickets.map(ticket => {
				return {
					label: ticket.ticket_name,
					value: ticket.ticket_id
				}
			}) ]);
			setSelectedTicket(newTicketDet[0]);
			setTickets(newTicketDet);
		} catch (err) {
			let errorMessage = err?.message || "Something went wrong";
			console.log(errorMessage);
			setTickets(false);
			errorToaster("Something went wrong");
		}
	};

	const downloadSample = async selectTicket => {
		try {
			const res = await downloadSampleCSV(eventId, selectTicket.value);
			window.location.assign(res.data.download_url);
			successToaster("Successfully Downloaded");
		} catch (err) {
			let errorMessage = err?.message || "Something went wrong";
			console.log(errorMessage);
			errorToaster("Something went wrong");
		}
	};

	const handleOnChange = async e => {
		setBulkAttendeeErrorResponse("");
		setErrorLink(null);
		setErrorCount(0);
		setLoadingUploadFile(true);
		const file = e?.target?.files[0];
		if(file !== null){
			setFile(file);
			const payloadUrl = {
				file_name: file.name,
				mime_type: file.type,
				ticket_name: selectTicket.label
			};
			const promise = new Promise((resolve, reject) => {
				const fileReader = new FileReader();
				fileReader.readAsArrayBuffer(file);
		  
				fileReader.onload = (e) => {
					resolve(true);
				};
		  
				fileReader.onerror = (error) => {
				  reject(error);
				};
			});

			promise.then(async(d)=>{
				try {
					const url = await uploadBulkAttendeesUrl(eventId, payloadUrl);
					setUploadUrl(url);
					setBulkAttendeeErrorResponse("");
					if(url.data.upload.url){
						await uploadToUrl(url.data.upload.url, file);
					}
				} catch (error) {
					console.log(error);
				} finally {
					setLoadingUploadFile(false);
				}
			})
			.catch(async(error)=>{
				console.log("error", error);
				if(error === `Please provide valid names. The special characters ! $ % ^ * = { } < > ? ~ ${"`"} \\ are not allowed.`){
					try {
						const url = await uploadBulkAttendeesUrl(eventId, payloadUrl);
						setUploadUrl(url);
						setBulkAttendeeErrorResponse(error);
						if(url.data.upload.url){
							await uploadToUrl(url.data.upload.url, file);
						}
					} catch (error) {
						console.log(error);
					} finally {
						setLoadingUploadFile(false);
					}
				} else {
					setLoadingUploadFile(false);
				}
			})
			
		}
	};

	const uploadToUrl = async (uploadUrl, file) => {
		try {
			const axiosWithoutAuthorization = axios.create();
			delete axiosWithoutAuthorization.defaults.headers.common["Authorization"];
			let request_config = {
				headers: {
					"Content-Type": file?.type,
					"Access-Control-Allow-Origin": "*"
				}
			};
			let res = await axiosWithoutAuthorization.put(
				uploadUrl,
				file,
				request_config
			);
			return res;
		} catch (err) {
			console.log(err);
			errorToaster("Unable to upload image. Please try again!");
			return;
		}
	};

	const onUpload = async e => {
		if (file) {
			try {
				const payload = {
					file_name: uploadUrl.data.file_name,
					send_confirmation_mail: sendConfirmationMail
				};

				const result = await uploadBulkAttendees(
					eventId,
					selectTicket.value,
					payload
				);
				setLoading(false);
				if (!result?.data?.failed_attendees) {
					successToaster("Successfully Uploaded");
					setModal(false);
					setSuccessCount(result.data.attendees_to_process);
					setConfirmationModal(true);
					
					getUploadsData && setLoad(true);

					setTimeout(() => {
						getUploadsData && getUploadsData();
					}, 1000);
				} else {
					setFile();
					setSuccessCount(result.data.attendees_to_process);
					setErrorCount(result.data.failed_attendees);
					setErrorLink(result.data.validations_error_file_url);
					setModal(false);
					setConfirmationModal(true);
				}
			} catch (error) {
				let errorMessage = error?.message || "Something went wrong";
				if(errorMessage?.includes("You have exceeded the maximum number of uploaded attendees")){
					setBulkAttendeeErrorResponse(errorMessage);
					setExceededMaxUpload(true);
					setLoading(false);
					setModal(false);
					setConfirmationModal(true);
					getUploadsData && setLoad(false);
				} else if(errorMessage?.includes("The uploaded file is not in the expected format. Please use the sample file to enter the data, and upload again")){
					setBulkAttendeeErrorResponse("The uploaded file is not in the expected format. Please upload again.");
					setInvalidFileFormat(true);
					setLoading(false);
					setModal(false);
					setConfirmationModal(true);
					getUploadsData && setLoad(false);
				} else if(errorMessage?.includes("No Details found in the file")){
					setBulkAttendeeErrorResponse(errorMessage);
					setNoDetailsFound(true);
					setLoading(false);
					setModal(false);
					setConfirmationModal(true);
					getUploadsData && setLoad(false);
				} else {
					setBulkAttendeeErrorResponse(errorMessage);
					setLoading(false);
					getUploadsData && setLoad(false);
					setModal(false);
					errorToaster(errorMessage);
				}
			} finally {
				getUploadDetailsFromApi();
			}
		}
	};

	const renderTextErrorNoUpload = () => {
		const {
			count: {
				bulk_upload_count: bulkUploadCount = 0,
				max_bulk_upload_count: maxBulkUploadCount = 0
			} = {}
		} = uploadDetails || {};

		if (exceededMaxUpload) {
			const errorText = bulkUploadCount === 0 
				? `You are trying to upload more than ${maxBulkUploadCount} registrations. You are allowed to upload ${maxBulkUploadCount} only.`
				: `You have uploaded ${bulkUploadCount} registrations already and cannot upload more than ${maxBulkUploadCount - bulkUploadCount}.`;
	
			return (
				<div className="mt-4 text-center no_upload_error_heading">
					{errorText}
				</div>
			);
		} else {
			return (
				<div className="mt-4 text-center no_upload_error_heading">
					{`Oops! ${bulkAttendeeErrorResponse}`}
				</div>
			)
		}
	}

	useEffect(() => {
		getTicketsData();
	}, []);

	return (
		<div>
			{
				view === "mobile" ?
				<div className="upload_icon_white d-flex justify-content-center align-items-center" onClick={() => setModal(true)}>
					<i className="fas fa-upload" />
				</div>
				:
				<TippyIcon
					content={
						bulkUploadDetails?.max_bulk_upload_count === 0 ?
						"Bulk upload option is not available for this event" :
						!tickets.length
							? "Applicable for both free and paid tickets"
							: `You can upload attendees manually without asking them to register for the event. <b>Note:</b> Make sure you take consent from those attendees before adding them manually.`
					}
					placement="bottom">
					<div className={`${type ? "pr-3" : "px-3"} bulk_upload_button`}>
						<KonfhubButton
							disabled={tickets.length <= 0 || bulkUploadDetails?.max_bulk_upload_count === 0}
							btnType={type || "tertiary"}
							onClick={() => setModal(true)}>
							Bulk Upload
						</KonfhubButton>
					</div>
				</TippyIcon>
			}
			

			<CustomModal
				openModal={confirmationModal}
				setOpenModal={setConfirmationModal}
				saveBtnContent={null}
				styles={customStyles}
				header={(type && !invalidFileFormat && !exceededMaxUpload && !noDetailsFound && !errorLink) ? "Upload Attendees" : ""}
				containerClassName="p-0"
				content={
					<>
						{
							(type && !invalidFileFormat && !exceededMaxUpload && !noDetailsFound && !errorLink) ?
								<div className="px-3 text-center">
									<div className="text-center">
										<i
											className="fa fa-check-circle mb-3"
											style={{
												fontSize: "4rem",
												color: "#38b359",
												fontWeight: "900"
											}}></i>
									</div>
									<p>
										Attendees have been successfully uploaded. <br />
										You can check the status of the upload below.
									</p>
									<KonfhubButton
										btnType={"secondary"}
										onClick={() => history.push(`/e/${eventId}/attendee-uploads`)}>
										Uploaded Attendees
									</KonfhubButton>
								</div>
								:
								(invalidFileFormat || exceededMaxUpload || noDetailsFound) ?
								<div className="px-3 mb-3 text-center">
									<div className="text-center">
										<img src={Warning} alt="Warning" />
									</div>
									{renderTextErrorNoUpload()}
									<div className="px-2 mt-4 text-center no_upload_error_subHeading">
										<span>{`Please download the ${invalidFileFormat ? 'sample' : 'error'} file and retry after making the changes.`}
											{
												invalidFileFormat ? 
												<span
													onClick={()=>{
														downloadSample(selectTicket);
													}}
													role="button"
													className="ml-1 bulk_download_error_file"
												>
													{"sample"}
													<img role="button" className="ml-1" src={Download} alt="Download" />
												</span> 
												:
												uploadUrl?.data?.download?.url && (
													<span
														onClick={()=>{
															window.open(uploadUrl?.data?.download?.url, '_blank')
														}}
														role="button"
														className="ml-1 bulk_download_error_file"
													>
														{file?.name?.length > 40 ? `${file?.name?.substring(0, 41)}...` : file?.name}
														<img role="button" className="ml-1" src={Download} alt="Download" />
													</span>
												)
											}
										</span>
									</div>
								</div>
								:
								errorLink ?
								<div className="px-3 mb-3 text-center">
									<div className="text-center">
										<img src={Warning} alt="Warning" />
									</div>
									<div className="mt-4 text-center no_upload_error_heading">
										{"Not all the attendees were uploaded successfully. Some attendees have not been uploaded due to incorrect/invalid data format."}
									</div>
									<div className="d-flex justify-content-center w-100">
										<div className="mt-3 successful_failed_box">
											<div className="successful_failed_box_text">{`Successful Attendee Upload: ${successCount}`}</div>
											<div className="successful_failed_box_text">{`Failed Attendee Uploads: ${errorCount}`}</div>	
										</div>
									</div>
									<div className="px-2 mt-3 text-center no_upload_error_subHeading">
										<span>Please download the error file and retry after making the changes.
											{
												errorLink && (
													<span
														onClick={()=>{
															window.open(errorLink, '_blank')
														}}
														role="button"
														className="ml-1 bulk_download_error_file"
													>
														{"errorFile"}
														<img role="button" className="ml-1" src={Download} alt="Download" />
													</span>
												)
											}
										</span>
									</div>
								</div>
								:
								<div className="px-3 mb-3 text-center">
									<img src={Success} alt="Success" />
									<div className="mt-4 successful_upload_text">
										{`You have successfully uploaded ${successCount} ${successCount > 1 ? 'attendees' : 'attendee'}!`}
									</div>
								</div>
						}
					</>
				}
			/>

			{/* Upload Attendees Modal */}
			<CustomModal
				openModal={modal}
				saveBtnDisabled={isLoading || !file}
				setOpenModal={setModal}
				saveBtnContent={!file ? null : "Upload"}
				isLoading={isLoading}
				header={"Upload Attendees"}
				paddingRequired="yes"
				overflow="auto"
				content={
					<div className="mt-2 mx-3 justify-content-center bulk_upload_modal_root">
						{/* <p className="form-heading mb-4">Upload Attendees</p> */}

						<div className="">
							<div className="mb-4">
								<label className="label mr-5" htmlFor="mailId">
									Step 1: Select ticket to upload Attendees
								</label>

								<div className="dw-container">
									<div className="flex-grow-1 dw-inner mb-3 mr-3">
										<Dropdown
											options={tickets}
											id="ticket_type w-100"
											value={selectTicket}
											onChange={e => setSelectedTicket(e)}
											placeholder="Choose Ticket Name"
										/>
									</div>
									<KonfhubButton
										btnType="tertiary"
										onClick={() => downloadSample(selectTicket)}>
										Download Sample
									</KonfhubButton>
								</div>
							</div>

							<div className="">
								<div className="row">
									<div className="col-12 col-sm-8">
										<div>
											<label className="label text-left">
												Step 2: Upload the sample downloaded file <br />
												with Attendee details
											</label>
										</div>
									</div>
									<div className="col-12 col-sm-4">
										<div className="text-center text-sm-right">
											<label
												htmlFor="csvFileInput"
												className="cancel-btn pointer">
												Attach &nbsp;
												<i
													className="fas fa-paperclip"
													style={{ fontSize: "18px" }}></i>
											</label>
											<input
												type={"file"}
												id={"csvFileInput"}
												accept={".xlsx"}
												style={{ display: "none" }}
												onChange={e => {
													handleOnChange(e);
													e.target.value=null
												}}
											/>
											{
												bulkAttendeeErrorResponse !== "The uploaded file is not in the expected format. Please upload again." &&
												bulkAttendeeErrorResponse !== `Please provide valid names. The special characters ! $ % ^ * = { } < > ? ~ ${"`"} \\ are not allowed.` &&
												<>		
													{loadingUploadFile ?
																<div className="text-center">
																	<span style={{marginLeft: 35}} className={"spinner-border spinner-border-sm"} role="status" aria-hidden="true" />
																</div> : 
														uploadUrl?.data?.download?.url && (
														<div className="filename-card-container mb-2 pointer text-center text-md-right">
															<a
																href={uploadUrl.data.download.url}
																target="_blank"
																rel="noopener noreferrer"
																className="filename-card">
																{file?.name?.length > 40
																	? file?.name?.substring(0, 41) + "..."
																	: file?.name}
															</a>
														</div>
													)}
												</>
											}
										</div>
									</div>
									<div className="col-12">
										{
											loadingUploadDetails ?
											<div className="text-center">
												<span className={"spinner-border spinner-border-sm"} role="status" aria-hidden="true" />
											</div>
											:
											uploadDetails?.count?.bulk_upload_count === 100 ?
											<div>
												<span className="imp_note_upload_attendee_heading">Note: </span><span className="imp_note_upload_attendee">{`You have uploaded ${uploadDetails?.count?.bulk_upload_count} ${uploadDetails?.count?.bulk_upload_count > 1 ? 'registrations' : 'registration'} already and you cannot upload anymore registrations.`}</span>
											</div>
											:
											uploadDetails?.count?.bulk_upload_count > 0 ?
											<div>
												<span className="imp_note_upload_attendee_heading">Note: </span><span className="imp_note_upload_attendee">{`You have uploaded ${uploadDetails?.count?.bulk_upload_count} ${uploadDetails?.count?.bulk_upload_count > 1 ? 'registrations' : 'registration'} already and can upload ${uploadDetails?.count?.max_bulk_upload_count - uploadDetails?.count?.bulk_upload_count} more only.`}</span>
											</div> :
											uploadDetails?.count?.bulk_upload_count === 0 ?
											<div>
												<span className="imp_note_upload_attendee_heading">Note: </span><span className="imp_note_upload_attendee">You are allowed to upload only {uploadDetails?.count?.max_bulk_upload_count} registrations</span>
											</div>
											:
											""
											}
									</div>
								</div>
							</div>

							<div className="mt-3">
								<input
									className="mr-2 bulk_upload_attendee_checkbox"
									type="checkbox"
									id="confirmation-mail"
									checked={sendConfirmationMail}
									value={sendConfirmationMail}
									onClick={e => setSendConfirmationMail(!sendConfirmationMail)}
								/>

								<label for="confirmation-mail">
									Send registration confirmation email to the uploaded attendees
								</label>
							</div>
						</div>
					</div>
				}
				onCancel={() => {
					setModal(false);
					setLoading(false);
				}}
				onOkay={e => {
					if(!bulkAttendeeErrorResponse && !errorLink){
						setLoading(true);
						onUpload(e);
					}
				}}
			/>

			<style jsx>{`
				.form-heading {
					font-weight: 600;
					font-size: 15px;
					color: #572148;
				}

				.bulk_upload_modal_root {
					max-width: 485px;
				}

				.upload-container {
					text-align: center;
				}

				.ReactModal__Content--after-open {
					max-width: 50%;
					overflow: visible;
				}

				.dw-inner {
					min-width: 250px;
				}

				.dw-inner {
					min-width: 250px;
				}

				.imp_note_upload_attendee_heading {
					font-size: 14px;
					font-weight: 600;
					line-height: 22.5px;
					color: #6C757D;
				}

				.imp_note_upload_attendee {
					font-size: 14px;
					font-weight: 400;
					line-height: 22.5px;
					color: #6C757D;
				}

				.bulk_upload_error_box {
					background: #FFDADE;
					border-radius: 6px;
					opacity: 0px;
				}

				.bulk_upload_error_text {
					font-size: 15px;
					font-weight: 400;
					line-height: 22.5px;
					color: #DC3545;
				}
				.bulk_upload_attendee_checkbox {
					margin-bottom: 3px !important;
				}

				.no_upload_error_heading {
					font-size: 16px;
					font-weight: 400;
					line-height: 25.62px;
					color: #572148;
				}

				.no_upload_error_subHeading {
					font-size: 15px;
					font-weight: 400;
					line-height: 24.02px;
					color: #6D7780;
				}
				.bulk_download_error_file {
					font-size: 15px;
					font-weight: 400;
					line-height: 24.02px;
					text-decoration: underline;
					color: #007BFF;
				}
				.successful_failed_box {
					min-width: 263px;
					min-height: 76px;
					padding: 14px;
					border-radius: 5px;
					background: #F8F8F8;
					border: 1px solid #E2E2E2
				}
				.successful_failed_box_text {
					font-size: 15px;
					font-weight: 400;
					line-height: 24.02px;
					color: #572148;
				}
				.successful_upload_text {
					font-size: 16px;
					font-weight: 500;
					line-height: 25.62px;
					color: #572148;
				}
				
				@media (max-width: 450px) {
					.bulk_upload_button {
						margin-top: 10px;
					}
				}
			`}</style>
		</div>
	);
};

export default BulkAttendees;