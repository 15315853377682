/** @format */

// customToast.js
/* eslint-disable */

import React from 'react';
import { toast } from 'react-toastify';
import { Fragment } from 'react';

toast.configure();

const customToast = {
	success(msg, options = {}) {
		return toast.success(<GetSuccessIcon msg={` ${msg}`} />, {
			className: 'toast-success-container toast-success-container-after',
		});
	},
	error(msg, options = {}) {
		return toast.error(<GetErrorIcon msg={` ${msg}`} />, {
			...options,
			className: 'toast-error-container toast-error-container-after',
			onOpen: () => { this.showError = false },
			onClose: () => { this.showError = true }
		});
	},
	info(msg, options = {}) {
		return toast.error(<GetInfoIcon msg={` ${msg}`} />, {
			...options,
			className: 'toast-info-container toast-info-container-after',
		});
	},
	warning(msg, options = {}) {
		return toast.error(<GetWarningIcon msg={` ${msg}`} />, {
			...options,
			className: 'toast-warning-container toast-warning-container-after',
		});
	},
	showError: true
};

const GetSuccessIcon = ({ msg }) => {
	return (
		<Fragment>
			{/* <FontAwesomeIcon icon={faCheck} /> */}
			<span className='has-margin-left'>{msg}</span>
		</Fragment>
	);
};
const GetErrorIcon = ({ msg }) => {
	return (
		<Fragment>
			{/* <FontAwesomeIcon icon={faExclamationCircle} /> */}
			<span className='has-margin-left'>{msg}</span>
		</Fragment>
	);
};
const GetWarningIcon = ({ msg }) => {
	return (
		<Fragment>
			{/* <FontAwesomeIcon icon={faExclamationTriangle} /> */}
			<span className='has-margin-left'>{msg}</span>
		</Fragment>
	);
};
const GetInfoIcon = ({ msg }) => {
	return (
		<Fragment>
			{/* <FontAwesomeIcon icon={faInfoCircle} /> */}
			<span className='has-margin-left'>{msg}</span>
		</Fragment>
	);
};

export default customToast;
