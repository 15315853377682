import React from "react";

import { Link } from "react-router-dom";
import SubMenuItem from "./SubMenuItem";
import { ArrowIcon } from "../../assets/img/Sidebar/index";
import CountBadge from './../../revampComponents/Atom/Badge/CountBadge'

const SidebarItem = React.forwardRef(
	({ navItem, index, sideMenuHandler, eventId, pathname, eventCommonDetails, reloadDetails, isLoading, className, eventInfo }, ref) => {

		const subItems = _.isEmpty(navItem.subMenus) ? false : navItem.subMenus.filter(subMenuItem => { //updated version of nav (If userType is not 2 and event is free then settlements have to be removed, so need to update the routes)
			if (eventInfo.currency_name !== "INR" && (subMenuItem.name === "WhatsApp" || subMenuItem.name === "SMS")) return false;
            else return true
    }) 

		const getClassName = () => {
			const sideItemPath = `/e/${eventId}${navItem.to}`
			let newClassName = `${className.replaceAll("selected", "")} `
			if(!navItem.subMenus) newClassName = newClassName.replaceAll("showMenu", "")
			if(sideItemPath === pathname) {
				newClassName += 'selected'
			} else if (!!navItem.subMenus) {
				let isSubSelected = false
				navItem.subMenus.forEach(subItem => {
					const subItemPath = `/e/${eventId}${subItem.to}`
					if(subItemPath === pathname) isSubSelected = true;
				})
				if(isSubSelected) newClassName += 'selected'
			}
			return newClassName
		}

		return (
			<li className={getClassName()} key={navItem.to}>
				<div
					className="iocn-link"
					ref={ref}
					onClick={() => sideMenuHandler(index, navItem)}>
					{navItem.to ? (
						<Link to={`/e/${eventId}${navItem.to}`}>
							<div className="icon-img-wrapper">{navItem.icon}</div>
							<span className="link_name">{navItem.name}</span>
							{/*navItem.name === "Settlements" && <div className="link_name ml-2"><span className="badge badge-info sub-link">Beta</span></div>*/}
						</Link>
					) : (
						<a>
							<div className="icon-img-wrapper">{navItem.icon}</div>
							<span className="link_name">{navItem.name}</span>
							{navItem.countBadge && ( // Only for Participants
								<CountBadge className="countBadge-margin-item no-show" count={eventCommonDetails.attendees_count} onReload={reloadDetails} isLoading={isLoading}/>
							)}
						</a>
					)}
					{subItems && (
						<div className="arrow-img-wrapper">
							<ArrowIcon />
						</div>
					)}
				</div>
				<SubMenuItem
					key={index}
					subItems={subItems}
					navItemName={navItem.name}
					navItem={navItem}
					eventId={eventId}
					pathname={pathname}
					eventCommonDetails={eventCommonDetails}
					reloadDetails={reloadDetails}
					isLoading={isLoading}
				/>
				<style>{`
					.sidebar.closed .no-show {
						display: none;
					}
					.countBadge-margin-item {
						margin-left: auto !important;
						margin-right: 60px;
					}
				`}</style>
			</li>
		);
	}
);

export default SidebarItem;
