import axios from "../axios";

const API_URL = axios.defaults.baseURL;
const BASE_URL = "/event/";

export const getAttendeeDetails = (
	eventID,
	offset,
	limit,
	search_value,
	filtersQuery = "",
	sort_order = "",
	sort_column = "",
	upload_ids = "",
	kh_order_id = "",
) => {
	let searchValue = search_value ? `&search_value=${search_value}` : "";
	let sortOrder = sort_order ? `&sort_order=${sort_order}` : "";
	let sortColumn = sort_column ? `&sort_column=${sort_column}` : "";
	let uploadIds = upload_ids ? `&upload_ids=${upload_ids}` : "";
	let khBookingIds = kh_order_id ? `&kh_order_ids=${kh_order_id}` : "";

	//Temporarily hard coding search for Ahlan Mode event
	const AhlanModiEvents = {
		"3665a374-b755-43d3-9785-3957bf9674f4": ["143920"],
		"de611c9d-3bab-4ec8-b456-47a680853f2f": ["143506"],
		"c2c3c899-9b66-4952-b93e-8fc0bd0d2103": [
			"32578",
			"31028",
			"31432",
			"31029",
		],
	};

	let customFormsSearch = "";
	if (search_value) {
		customFormsSearch = AhlanModiEvents[eventID]
			? AhlanModiEvents[eventID].reduce(
				(query, formId) => `${query}&${formId}=${search_value}`,
				"",
			)
			: "";
	}

	return axios.get(
		API_URL +
		BASE_URL +
		eventID +
		"/attendees?" +
		`limit=${limit}&offset=${offset}${filtersQuery}` +
		searchValue +
		customFormsSearch +
		sortOrder +
		sortColumn +
		uploadIds +
		khBookingIds,
	);
};

export const forwardEMail = (eventID, payload) => {
	return axios.post(
		API_URL + BASE_URL + eventID + "/attendees" + "/forward",
		payload,
	);
};

export const downloadTicketAPI = (eventID, bookingId, free) => {
	return axios.get(
		API_URL +
		BASE_URL +
		eventID +
		"/attendees/" +
		bookingId +
		"/download" +
		`?is_free=${free}`,
	);
};

export const refundTicketAPI = (eventID, bookingId, payload) => {
	return axios.post(
		API_URL + BASE_URL + eventID + "/attendees/" + bookingId + "/refund",
		payload,
	);
};

export const cancelAttendeesAPI = (eventID, bookingIds, notify_attendee = true) => {
	return axios.delete(`${API_URL}${BASE_URL}${eventID}/attendees/cancel`, {
		data: {
			cancel_reason: "",
			notify_attendee: notify_attendee,
			booking_ids: bookingIds,
		},
	});
};

export const UpdateAttendeeDetail = (eventId, payload, mainPayload, query) => {
	const newQuery = query ? query : ""
	return axios.put(API_URL + BASE_URL + eventId + "/attendees/" + payload + "/edit" + newQuery,
		mainPayload,
	);
};

export const downloadAttendee = (eventId, payload, filterParams = "") => {
	return axios.post(
		API_URL +
			BASE_URL +
			eventId +
			"/download/attendees" +
			`?${filterParams}`,
		payload,
	);
};

export const refundHistory = (eventID, bookingId) => {
	return axios.get(
		API_URL +
		BASE_URL +
		eventID +
		"/attendees/" +
		bookingId +
		"/refund" +
		"/history",
	);
};

export const transferHistory = (eventID, bookingId) => {
	return axios.get(
		API_URL +
		BASE_URL +
		eventID +
		"/attendees/" +
		bookingId +
		"/transfer" +
		"/history",
	);
};

export const getIndividualAttendee = (eventID, bookingId) => {
	return axios.get(API_URL + BASE_URL + eventID + "/attendees/" + bookingId);
};

export const downloadSampleCSV = (eventID, ticketId) => {
	return axios.get(
		API_URL + BASE_URL + eventID + `/bulk-registrations/${ticketId}/sample`,
	);
};

export const uploadBulkAttendees = (eventID, ticketId, payload) => {
	return axios.post(
		API_URL + BASE_URL + eventID + `/${ticketId}/attendee/upload`,
		payload,
	);
};

export const uploadBulkAttendeesUrl = (eventID, payload) => {
	return axios.post(
		API_URL + BASE_URL + eventID + `/attendee/upload-file`,
		payload,
	);
};

export const getSettlementBalance = (eventID) => {
	return axios.get(API_URL + BASE_URL + eventID + "/balance");
};

export const deleteAttendee = (eventID, payload) => {
	return axios.delete(API_URL + BASE_URL + eventID + "/attendees/delete", payload);
};
