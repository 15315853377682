import React, { useEffect, useState, useRef } from "react";
import { LiveStatusIcon, PastStatusIcon, UpcomingStatusIcon } from "../../assets/img/Header";
import { clickOutside } from "../../reusable/common";
import KonfhubButton from "../../revampComponents/Atom/Buttons/KonfhubButton";
import searchIcon from "./../../assets/img/search-icon.svg";
import _ from "lodash";
import { updateProfileDetails } from "../../revampCRUD/app.crud";
import SmallAddButton from "../../revampComponents/Atom/Buttons/SmallAddButton";

const SwitchEventDropDown = ({ organiserDetails, reloadApi, history , token}) => {
	const [orgDetails, setOrgDetails] = useState(organiserDetails);
	const [open, setOpen] = useState(false);
	const wrapperRef = useRef("menu");
	clickOutside(wrapperRef, () => {
		setOpen(false);
	});

	const updateLastEvent = async(eventId) => {
		try{
			let res = updateProfileDetails({last_accessed_event: eventId}, token)
		} catch (err) {
			console.log(err)
		}
	}

	const onSwitchEvent = async event => {
		reloadApi(event.event_id);
		history.push(`/e/${event.event_id}/`);
		updateLastEvent(event.event_id);
	};

	const handleChange = e => {
		let searchValue = e.target.value;
		let newOrgDetails = organiserDetails.filter(event =>
			event.event_name.toLowerCase().includes(searchValue.toLowerCase()) || event.event_short_name.toLowerCase().includes(searchValue.toLowerCase())
		);
		setOrgDetails(newOrgDetails);
	};

	const getEventName = (event, i) => (
		<a key={i} className="" onClick={() => onSwitchEvent(event)}>
		{event.event_status === 3 ? <PastStatusIcon /> : event.event_status === 1 ? <LiveStatusIcon /> : <UpcomingStatusIcon />}{" "}
		&nbsp;
		{event.event_name} ({event.event_short_name})
	</a>
	)

	let hasLiveEvents = false; 
	let hasUpcomingEvents = false; 
	let hasPastEvents = false; 

	orgDetails.forEach(event => {
		if(event.event_status === 1) hasLiveEvents = true 
		if(event.event_status === 2) hasUpcomingEvents = true 
		if(event.event_status === 3) hasPastEvents = true 
	})

	return (
		<div ref={wrapperRef} className="switchEvent-container position-relative ml-3">
			<KonfhubButton
				btnType="secondaryWhite"
				onClick={e => {
					setOpen(!open);
				}}>
				Switch Event
			</KonfhubButton>

			{open && (
				<div className="switchEvent-dropDown-container position-absolute">
					<div className="switchEvent-search-container position-relative">
						<input
							autoFocus
							type="text"
							className="px-2 py-2 switchEvent-search-inpt"
							onChange={handleChange}
							placeholder="Search"
						/>
						<img
							className="position-absolute switchEvent-icon"
							src={searchIcon}
						/>
					</div>
					<div className="switchEvent-dropdown-options text-left">
						{orgDetails && _.isEmpty(orgDetails) ? (
							<p className="my-2 mx-2 mt-5 text-center switchEvent-message ">
								{" "}
								No events found
							</p>
						) : (
							<>
							{hasLiveEvents && <p className="switchEvent-heading mt-2 mb-1">Live Events</p>}
							{orgDetails.map((event, i) => (
								event.event_status === 1 && getEventName(event, i)
							))}
							{hasUpcomingEvents && <p className="switchEvent-heading mt-2 mb-1 ">Upcoming Events</p>}
							{orgDetails.map((event, i) => (
								event.event_status === 2 && getEventName(event, i)
							))}
							{hasPastEvents &&<p className="switchEvent-heading mt-2 mb-1">Past Events</p>}
							{orgDetails.map((event, i) => (
								event.event_status === 3 && getEventName(event, i)
							))}
							</>
						)}
					</div>
                    <div className="switchEvent-host-container mx-auto d-flex align-items-center pl-1">
						<SmallAddButton btnType="secondary" onClick={() => history.push("/create/event")}>Host new event</SmallAddButton>
                    </div>
				</div>
			)}
			<style>{`
             .switchEvent-dropDown-container {
                z-index: 100;
                min-width: 300px;
                max-width: 300px;
                max-height: calc(100vh - 96px);
                background: #fff;
                left: -67px;
                border-radius: 2px;
                top: 45px;
                box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
            }
            .switchEvent-dropdown-options {
                margin-top: 6px;
                max-height: calc(100vh - 220px);
                overflow-y: scroll;
                overflow-x: hidden;
            }
            .switchEvent-dropdown-options > a {
                display: block;
                width: 100%;
                padding: 8px 0px 8px 12px;
                font-size: 16px;
                color: #6D7780;
                word-break: break-all;
            }

            .switchEvent-dropdown-options > a:hover {
                background: #f3f2f2;
                text-decoration: none;
                cursor: pointer;
            }

            .switchEvent-search-container {
                width: 290px;
                left: 5px;
                color: #6D7780;
            }

            .switchEvent-message {
                color: #6D7780;
                font-size: 14px;
            }

            .switchEvent-search-inpt {
                border: none;
                border-bottom: 1px solid #CBCCCC;
                font-size: 16px;
            }

            .switchEvent-icon {
                top: 14px;
                right: 18px;
                width: 14px;
                height: 14px;
                background: #fff;
            }

            .switchEvent-host-container {
				width: 290px;
                height: 50px;
				border-top: 0.5px solid #E9ECEF;
            }

			.switchEvent-host-btn {
				border: none;
				background: #fff;
				color: #572148;
				border-radius: 6px;
				transition: all 0.2s;
			}

			.switchEvent-host-btn:hover {
				background: #ececec;
			}

			.switchEvent-host-btn:active {
				background: #e3e2e2;
			}

			.switchEvent-heading {
				padding-left: 12px;
				color: #572148;
				font-weight: 500;
			}
            `}</style>
		</div>
	);
};

export default SwitchEventDropDown;
