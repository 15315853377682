import CustomModal from "./CustomModal"
import DeleteModal from "./DeleteModal"
import QuickViewModal from "./QuickViewModal"
import RouteModal from "./RouteModal"

export {
    CustomModal,
    DeleteModal,
    QuickViewModal,
    RouteModal
}
