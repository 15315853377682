import { getBaseURL } from "../Utils/helpers";

export const baseUrl = process?.env.REACT_APP_WEBSITE_URL || "https://dev.konfhub.com";
export const websiteUrl = process?.env.REACT_APP_DOMAIN_NAME
	? `https://${process?.env.REACT_APP_DOMAIN_NAME}`
	: "https://dev-events.konfhub.com";
export const loginUrl = process?.env.REACT_APP_LOGIN_URL
	? process?.env.REACT_APP_LOGIN_URL
	: "https://dev-accounts.konfhub.com";
export const s3Url =
	getBaseURL() === "https://konfhub.com/"
		? `https://konfhub-image-files-prod.s3.ap-south-1.amazonaws.com`
		: `https://konfhub-image-files-${process?.env.REACT_APP_ENVIRONMENT || "dev"}.s3.${
				process?.env.REACT_APP_AWS_PROJECT_REGION || "ap-southeast-1"
			}.amazonaws.com`;
export const baseUrlQuizhub =
	getBaseURL() === "https://dev.konfhub.com/"
		? "https://dev-quizadmin.konfhub.com"
		: "https://quizadmin.konfhub.com";
export const geoLocationAPIKey =
	process?.env.REACT_APP_GOOGLE_GEOLOCATION_API_KEY || "AIzaSyBkCKa3Rg5BeGbk2uxvOVFEbLcxNulxLOc";
export const konfhubTaxID = process?.env.REACT_APP_KONFHUB_TAX_ID || "KONFHUBTAXID12";
export const certhubUrl = process?.env.REACT_APP_CERTHUB_URL || "https://dev-cert.konfhub.com";
export const checkinAppUrl = process?.env.REACT_APP_CHECKIN_APP_URL
	? process?.env.REACT_APP_CHECKIN_APP_URL
	: "dev-checkin.konfhub.com";
export const sponsorAppUrl = process?.env.REACT_APP_SPONSOR_APP_URL
	? process?.env.REACT_APP_SPONSOR_APP_URL
	: "dev-sponsor.konfhub.com";
export const mediaUrl = `https://${
	process?.env.REACT_APP_ENVIRONMENT === "prod" ? "media.konfhub.com" : "dev-media.konfhub.com"
}`;
export const regButtonsUrl =
	process?.env.REACT_APP_REG_BUTTONS || "https://dev-widget.konfhub.com/widget.js";
export const quizHubUrl = process?.env.REACT_APP_QUIZHUB_URL || "https://dev-quiz.konfhub.com";
export const quizHubAdminUrl =
	process?.env.REACT_APP_QUIZHUB_ADMIN_URL || "https://dev-quizadmin.konfhub.com";
export const quizhubAppUrl =
	process?.env.REACT_APP_QUIZHUB_APP_URL || "https://dev-join.quizhub.app";
export const stripeClientID =
	process?.env.REACT_APP_STRIPE_CLIENT_ID || "ca_NLgApa3ts2fjAZLZgAaHWPYb4PBidgHy";

export const STRIPE_PG = 6;
export const TAP_PG = 7;
