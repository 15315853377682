import axios from "../axios";

const API_URL = axios.defaults.baseURL;
const BASE_URL = "/event/";
const failures = "/failures";
const uploads = "/uploads";

export const getUploads = eventID => {
	return axios.get(API_URL + BASE_URL + eventID + uploads);
};

export const getUploadFailures = (eventID, uploadId) => {
	return axios.get(
		API_URL + BASE_URL + eventID + uploads + `/${uploadId}` + failures
	);
};
