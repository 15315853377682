import React, { useState, useEffect, useMemo } from "react";
import LegendColor from "../Atom/LegendColor";
import BackendPaginatedDataTable from "../Atom/BackendPaginatedDataTable";
import SectionHeader from "../Atom/SectionHeader";
const BackendTableWizard = ({
	sectionHeading,
	buttonName,
	onButtonClick,
	onButtonLoading = false,
	setSearchFunction,
	search,
	rowData,
	columns,
	noDataComponent,
	totalCount,
	handlePerRowsChange,
	handlePageChange,
	onRowClicked,
	onProgress,
	selectableRows,
	setCheckboxArray,
	clearSelectedRows,
	selectableRowDisabled,
	conditionalRowStyles,
	legendColors,
	isFilter,
	getDataFunction,
	perPage,
	eventId,
	filterParams,
	setFilterParams,
	onSort,
	sortServer,
	overflowY,
	showUploadAttendees,
	refreshData,
	refreshDataLoading,
	BulkActions,
	selectableRowSelected,
	onCallFunction,
	paginationPerPage,
	pagination,
	paginationServer,
	paginationRowsPerPageOptions,
	headerMargin,
	refreshHeaderComponent,
	tempData = false,
	hideSectionHeader,
	prop = {},
	bulkUploadDetails = {}
}) => {
	return (
		<div>
			{/* Header - Title, searchbar, and Launch button */}
			{!hideSectionHeader && (
				<SectionHeader
					tempData={tempData}
					heading={sectionHeading}
					refreshData={refreshData}
					refreshDataLoading={refreshDataLoading}
					disabledButton={_.isEmpty(rowData) ? true : false}
					onButtonLoading={onButtonLoading}
					setSearchFunction={setSearchFunction}
					search={search}
					buttonName={buttonName}
					onButtonClick={onButtonClick}
					isFilter={isFilter}
					getDataFunction={getDataFunction}
					perPage={perPage}
					eventId={eventId}
					filterParams={filterParams}
					setFilterParams={setFilterParams}
					showUploadAttendees={showUploadAttendees}
					onCallFunction={onCallFunction}
					headerMargin={headerMargin}
					refreshHeaderComponent={refreshHeaderComponent}
					prop={prop}
					bulkUploadDetails={bulkUploadDetails}
				/>
			)}

			{BulkActions}
			{/* Datatable */}
			<BackendPaginatedDataTable
				rowData={rowData}
				columns={columns}
				noDataComponent={noDataComponent}
				totalCount={totalCount}
				handlePerRowsChange={handlePerRowsChange}
				handlePageChange={handlePageChange}
				onRowClicked={onRowClicked}
				onProgress={onProgress}
				selectableRows={selectableRows}
				setCheckboxArray={setCheckboxArray}
				clearSelectedRows={clearSelectedRows}
				selectableRowDisabled={selectableRowDisabled}
				conditionalRowStyles={conditionalRowStyles}
				onSort={onSort}
				sortServer={sortServer}
				overflowY={overflowY}
				paginationPerPag={paginationPerPage}
				pagination={pagination}
				paginationServer={paginationServer}
				paginationRowsPerPageOptions={paginationRowsPerPageOptions}
			/>
			{legendColors && legendColors.length && rowData.length ? (
				<LegendColor legendColors={legendColors} />
			) : (
				""
			)}
		</div>
	);
};
export default BackendTableWizard;