import React from "react";

const QuickViewModal = ({
	show,
	setShow,
	title,
	children,
	showRefundHistory,
}) => {
	return (
		<div className={show ? `quickView-container` : `quickView-container close`}>
			<header className="quickView-header">
				<p className="m-0">{title ? title : ""}</p>
				<i
					className={`far fa-times-circle closeIcon text-white`}
					onClick={() => setShow(false)}
				></i>
			</header>
			<div className="quickView-body">{children}</div>
			<style>{`
        .quickView-header {
          min-height: 3.2rem;
          display: flex;
          justify-content: space-between;
          color: white;
          padding: 0.5rem;
          background-color: #572148;
          align-items: center;
          box-shadow: -0px 0px 10px #0f0f0fd3;
      }
      
      .close {
          right: -100% !important;
      }
      
      .quickView-container {
          width: ${showRefundHistory ? "400px" : "400px"};
          max-width: 100vw;
          z-index: 99;
          position: fixed;
          top: 0;
          right: 0;
          background-color: white;
          transition: .3s all;
          bottom:0;
          box-shadow: -0px 0px 10px #0f0f0f2b;
          overflow: hidden;
      }
      
      .closeIcon {
          font-size: 22px;
          cursor: pointer;
          transition: 0.3s all;
      }
      
      .quickView-body {
          height: 95vh;
          overflow-y: auto;
          overflow-x: hidden;
      }
      
      .closeIcon:hover {
          opacity: 0.8;
      }
      `}</style>
		</div>
	);
};

export default QuickViewModal;
