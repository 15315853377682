import { api } from "./axios.config";
import axios from "./axios";
import React, { useContext } from "react";

const API_URL = api.apiUrl;
const EVENT_URL = "/event/";

export const getPaymentMethodDetails = async (eventId) => {
	const PAYMENT_METHOD = "/payment/methods";
	return axios.get(API_URL + EVENT_URL + eventId + PAYMENT_METHOD);
};

export const getEventPlan = async (eventId, event_plan, pgMethod = 6,) => {
	const QUERY = `?pg_method=${pgMethod}&event_plan=${event_plan}`;
	return axios.get(`${API_URL + EVENT_URL + eventId}/subscribe-event${QUERY}`);
};
