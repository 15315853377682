import {api} from "./axios.config";
import axios from "./axios";
import React, {useContext} from "react"
// import AuthContext from "./../contexts/AuthContext"

const API_URL = api.apiUrl;
const EVENT_URL = "/event/";
const EVENT_PLAN = "/plan"

export const createEventAPI = payload => {
	return axios.post(API_URL + "/event", payload);
};

export const getEventDetails = async (eventId) => {
	return axios.get(API_URL + EVENT_URL + eventId);
};

export const postEventDetails = eventId => {
	return axios.post(API_URL + EVENT_URL + eventId);
};

export const UpdateEventDetails = (payload, eventId) => {
	return axios.put(API_URL + EVENT_URL + eventId, payload);
};

export const changeEventPlan = (eventId, payload) => {
	return axios.put(API_URL + EVENT_URL + eventId + EVENT_PLAN, payload);
};


export const ValidateURL = async url => {
	try {
		return await axios.get(
			API_URL + EVENT_URL + `url/validate?event_url=${url}`
		);
	} catch (err) {
		return await err;
	}
};
