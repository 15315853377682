/* eslint-disable */

import React from "react";
import getSymbolFromCurrency from "currency-symbol-map";

const GetCurrency = props => {

	const getWhichCountrySymbolForDollar = (currency) => {
		let countrySign = ""
		switch (currency) {
			case "AUD": //Australia 
				countrySign = "A"
				break;
			case "CAD": //Canada
				countrySign = "CA"
				break;
			case "HKD": //Hong Kong
				countrySign = "HK"
				break;
			case "NZD": //New Zealand
				countrySign = "NZ"
				break;
			case "SGD": //New Zealand
				countrySign = "S"
				break;	
			default:
				countrySign = ""
				break;
		}
		return countrySign;
	}

	return (
		<p className={`d-inline-block mb-0 ${props.className ? props.className : ""}`} style={props.style ? props.style : props.fontSize ? { fontSize: props.fontSize } : {}}>
			{getWhichCountrySymbolForDollar(props.currency)}
			{getSymbolFromCurrency(props.currency)}
		</p>
	);
};

export default GetCurrency;
