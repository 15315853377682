import React from 'react';
import tickMark from '../assets/img/orangetick.svg';

const TickImage = () => {
  return (
    <img
      src={tickMark}
      alt="tick mark"
      className="mr-2 tick_style"
    />
  );
};

export default TickImage;