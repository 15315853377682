import React, { useState } from "react";
import _ from "lodash";
import { Spinner } from "react-rainbow-components";
import SmallAddButton from "./Buttons/SmallAddButton";
import SearchBar from "./SearchBar";
import TippyIcon from "./TippyIcon";
import FilterBadge from "./Badge/FilterBadge";
import DataTableFilter from "./Filters/DataTableFilter";
import { KonfhubButton } from "./Buttons";
import BulkAttendees from "./BulkUpload/BulkAttendees";
import DownloadIcon from "../../assets/img/DownloadWhite.svg";
import { capitalizeFirst } from "../../Utils/helpers";
import useIsMobile from "../../Utils/useForceUpdate";
import LockableButton from "../Molecule/Buttons/LockableButton";

// Header - Title, searchbar, and a button
const SectionHeader = ({
	tempData,
	heading,
	setSearchFunction,
	search,
	dropDown,
	buttonName,
	onButtonClick,
	onButtonLoading = false,
	disabledButton,
	credits,
	onClickOfCredits,
	decsription,
	tooltipButton,
	isFilter,
	getDataFunction,
	perPage,
	eventId,
	filterParams,
	setFilterParams,
	showUploadAttendees,
	refreshData,
	refreshDataLoading,
	onCallFunction,
	headerMargin,
	refreshHeaderComponent,
	restrictedButton= false,
	customFilterComp = false,
	customFilter = false,
	setCustomFilter = false,
	searchPlaceholder = "Search...",
	prop,
	bulkUploadDetails
}) => {
	const [selectedFilters, setSelectedFilters] = useState([]); // This will contain the selected filters if any
	const [filterToRemove, setFilterToRemove] = useState(false); // This will contain the neccessary details of the filter that needs to be removed
	const isMobile = useIsMobile(450);

	return (
		<>
			<div
				className={`d-md-flex alignCustomY mb-${
					headerMargin ? headerMargin : "3"
				}`}
			>
				{refreshHeaderComponent ? (
					<>{refreshHeaderComponent}</>
				) : (
					<div className="d-flex align-items-start align-items-sm-center flex-fill flex-grow-1">
						<span className="sectionTitle mt-0 mt-sm-3">{heading}</span>
						{refreshData && (
							<span className="refresh-con ml-3" onClick={() => refreshData()}>
								{refreshDataLoading ? (
									<Spinner size="xx-small" type="arc" variant="brand" />
								) : (
									<TippyIcon content="Refresh">
										<i className="fas fa-redo refresh-icon"></i>
									</TippyIcon>
								)}
							</span>
						)}
						{credits !== undefined && (
							<TippyIcon content="Request Credits" placement="bottom">
								<div className="d-inline">
									<SmallAddButton
										className="ml-2"
										btnType="primary"
										onClick={onClickOfCredits}
									>
										Available Credits: {credits}
									</SmallAddButton>
								</div>
							</TippyIcon>
						)}
					</div>
				)}

				{/* Search bar for datatable */}
				{setSearchFunction != null && (
					<div
						className={`d-flex d-sm-${
							heading === "Checked-in Attendees" ? "flex" : "block"
						} mr-${
							heading === "Checked-in Attendees" || !buttonName ? "0" : "4"
						} mt-1 mb-4 mb-sm-1`}
					>
						<SearchBar
							containerClass={heading === "Checked-in Attendees" ? "searchInputBox_checkedIn" : ""}
							search={search}
							setSearchFunction={setSearchFunction}
							searchPlaceholder={searchPlaceholder}
						/>

						{heading === "Checked-in Attendees" && (
							<TippyIcon
								content={
									!disabledButton ? (
										<div className="text-center">
											Reset your attendee's check-in data to{" "}
											<div>restart checking-in for your event</div>
										</div>
									) : (
										<div>You do not have any check-in data to reset</div>
									)
								}
								placement="bottom"
							>
								<div className={"px-3 bulk_upload_button"}>
									<KonfhubButton
										disabled={disabledButton}
										className={"quaternary-btn"}
										onClick={() => {
											if (typeof onCallFunction === "function") {
												onCallFunction();
											}
										}}
									>
										Reset Check-ins
									</KonfhubButton>
								</div>
							</TippyIcon>
						)}

						{isFilter && isMobile && (
							<div className="filter_options_mobile ml-3">
								<DataTableFilter
									heading={heading}
									getDataFunction={getDataFunction}
									setFilterParams={setFilterParams}
									filterParams={filterParams}
									setSelectedFilters={setSelectedFilters}
									perPage={perPage}
									search={search}
									eventId={eventId}
									filterToRemove={filterToRemove}
									setFilterToRemove={setFilterToRemove}
									tempData={tempData}
									view="mobile"
								/>
							</div>
						)}
						{/* <div 
							className="ml-3 download_icon_white  d-flex justify-content-center align-items-center" 
							onClick={() => {
								onButtonClick();
							}}
						>
							<img src={DownloadIcon} alt="DownloadIcon" />
						</div> */}
						{heading === "Attendees" && !showUploadAttendees && (
							<div className="filter_options_mobile ml-3">
								<BulkAttendees
									type="secondary"
									eventId={eventId}
									view="mobile"
									bulkUploadDetails={bulkUploadDetails}
									prop={prop}
								/>
							</div>
						)}
					</div>
				)}

				{/* custom filter component for whatsapp attendee analytics  */}
				{customFilterComp && (
					<div className="ml-0 ml-md-3">{customFilterComp}</div>
				)}

				{/* Filter Option Component*/}
				{isFilter && !isMobile && (
					<div className="filter_options_desktop mt-3 mt-sm-0">
						<DataTableFilter
							heading={heading}
							getDataFunction={getDataFunction}
							setFilterParams={setFilterParams}
							filterParams={filterParams}
							setSelectedFilters={setSelectedFilters}
							perPage={perPage}
							search={search}
							eventId={eventId}
							filterToRemove={filterToRemove}
							setFilterToRemove={setFilterToRemove}
							view="desktop"
							tempData={tempData}
						/>
					</div>
				)}
				{heading == "Attendees" && !showUploadAttendees && (
					<div className="filter_options_desktop mt-3 mt-sm-0">
						<BulkAttendees type="secondary" eventId={eventId} view="desktop" prop={prop} bulkUploadDetails={bulkUploadDetails} />
					</div>
				)}
				{showUploadAttendees == true ? (
					""
				) : (
					<div className="pb-lg-3 pb-md-3 pb-0 section_header_buttonClassName mt-0 mt-lg-3 mt-md-3">
						<TippyIcon content={tooltipButton} placement="bottom">
							<div className="">
								{buttonName != null && (
									<LockableButton
										isLoading={onButtonLoading}
										btnType="tertiary"
										disabled={disabledButton || onButtonLoading}
										isRestricted={restrictedButton}
										onClick={() => {
											onButtonClick();
										}}
									>
										{buttonName}
									</LockableButton>
								)}
							</div>
						</TippyIcon>
					</div>
				)}
				<style jsx>{`
					.alignCustomY {
						align-items: stretch;
						flex-flow: row nowrap;
						justify-content: space-around;
						align-content: space-between;
						align-items: center;
					}
					.userCredits-button-container {
						border: 1px solid #ddd;
						padding: 5px 10px;
						margin-right: 5px;
						min-width: 130px;
						position: relative;
						border-radius: 3px;
						// display: flex;
						align-items: center;
						justify-content: center;
					}
					.searchIconContainer {
						width: 250px;
					}
					.head-cont {
						border-bottom: 1px solid #c3cddf78;
					}
					.flt-head {
						font-weight: 500;
						color: #572148;
						font-size: 18px;
					}
					.btn-wrap {
						position: absolute;
						background: white;
						bottom: 0px;
						width: 65%;
						padding: 0.7rem;
						right: 0px;
						border-top: 1px solid #c3cddf78;
					}
					.fltBtn {
						border: 1px solid #cdd4db;
						border-radius: 2px;
						background: transparent;
						padding: 5px 10px;
					}
					.dropBtn {
						position: relative;
					}
					.l-side {
						background-color: #f6f7f9;
						width: 35%;
						height: 301px;
						float: left;
					}
					.r-side {
						position: relative;
						overflow: auto;
						width: 65%;
						background-color: white;
						padding: 10px;
						height: 298px;
						padding-top: 0.7rem !important;
					}
					.r-side label {
						display: inline;
					}
					.fil-btn {
						border: none;
						background-color: transparent;
						padding: 5px 10px 5px 3px;
						cursor: pointer;
					}
					.fil-btn:hover {
						background-color: #eae5e9;
					}
					.fil-btn-active {
						background-color: #572148;
						color: white;
						border: none;
						padding: 5px 10px 5px 3px;
						cursor: pointer;
					}
					.cnl-btn {
						background-color: transparent;
						padding: 2px 20px;
						color: #dc3545;
						border: 2px solid #dc3545;
						border-radius: 2px;
					}
					.rst-btn {
						background-color: transparent;
						padding: 2px 20px;
						color: #dc3545;
						border: none;
					}
					.apl-btn {
						float: right;
						background-color: #dc3545;
						padding: 2px 20px;
						color: White;
						border: 2px solid #dc3545;
						border-radius: 2px;
					}
					.refresh-icon {
						opacity: 1;
						transition: opacity 0.1s, transform 0.2s !important;
					}
					.refresh-con {
						position: relative;
						display: flex;
						justify-content: center;
						align-items: center;
						transition: 0.2s all;
					}
					.refresh-con i {
						font-size: 11px;
						margin-left: -9px;
						transform: translateY(0px);
					}
					.refresh-con:hover {
						transform: scale(1.1);
					}
					.refresh-con:hover .refresh-icon {
						color: #572148 !important;
						transform: scale(1);
						opacity: 1 !important;
					}
					.filter_icon {
						width: 36px;
						height: 36px;
						border-radius: 2px;
						border: 1px solid #CDD4DB;
					}
					.download_icon_white {
						width: 44px;
						height: 36px;
						border-radius: 2px;
						background: #DC3545;
						padding: 0px 8px;
						color: white;
					}
					.upload_icon_white {
						width: 36px;
						height: 36px;
						border-radius: 2px;
						background: #DC3545;
						padding: 0px 8px;
						color: white;
					}
					.searchInputBox_checkedIn {
						align-self: center !important;
					}
					@media (max-width: 450px) {
						.section_header_buttonClassName {
							margin-top: 10px;
						}
						.filter_options_desktop {
							display: none;
						}
						.refresh-con {
							margin-top: 7px !important;
						}
					}
					@media (min-width: 450px) {
						.filter_icon {
							display: none !important;
						}
						.download_icon_white {
							display: none !important;
						}
						.filter_options_mobile {
							display: none;
						}
					}
				`}</style>
			</div>
			{decsription && (
				<p
					className="sectionDesc mb-0"
					style={{
						transform: "translateY(-20px)",
					}}
				>
					{decsription}
				</p>
			)}
			{/* Selected Filter Badges */}
			{isFilter && !_.isEmpty(selectedFilters) && (
				<div className="mb-2">
					{selectedFilters.map((filterObj) => (
						<FilterBadge
							onClick={() =>
								setFilterToRemove([
									`${filterObj.filterType}-${filterObj.statusNo}`,
									!!filterObj.isTicket,
									true,
								])
							}
						>
							{filterObj.filterName}
						</FilterBadge>
					))}
				</div>
			)}
			{/* Selected Filter Badges for  attendee analytics*/}
			{customFilter && !_.isEmpty(customFilter) && (
				<div className="mb-2">
					{customFilter?.map((filter) => (
						<>
							{filter && (
								<FilterBadge
									onClick={() => {
										const newFilter = customFilter.filter(
											(item) => item !== filter,
										);
										setCustomFilter(newFilter);
									}}
								>
									{capitalizeFirst(filter)}
								</FilterBadge>
							)}
						</>
					))}
				</div>
			)}
		</>
	);
};
export default React.memo(SectionHeader);