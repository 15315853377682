import React from "react";
import KonfhubButton from "../../Atom/Buttons/KonfhubButton";

const FormButtons = ({
	isLoading,
	onCancel,
	onSave,
	formik,
	saveBtnContent,
	cancelBtnContent,
	cancelBtnDisabled,
	saveBtnDisabled,
	paddingRequired,
	justifyContent
}) => {
	return (
		<div
			className={`${
				paddingRequired === "no" &&
				justifyContent !== "" &&
				justifyContent !== undefined &&
				justifyContent !== null
					? `py-0 justify-content-${justifyContent}`
					: paddingRequired === "no" && !justifyContent
						? "py-0 justify-content-center"
						: "py-3 justify-content-end"
			} row w-100 m-0 d-flex`}
		>
			<KonfhubButton
				className="mr-0 mr-sm-3 mt-3 mt-sm-0"
				onClick={() => onCancel()}
				disabled={cancelBtnDisabled}
				formik={formik}
				btnType="secondary"
			>
				{cancelBtnContent ? cancelBtnContent : "Cancel"}
			</KonfhubButton>
			<KonfhubButton
				className="mt-3 mt-sm-0 ml-3 ml-sm-0"
				isLoading={isLoading}
				formik={formik}
				disabled={saveBtnDisabled}
				onClick={() => onSave()}
				btnType="primary"
			>
				{saveBtnContent ? saveBtnContent : "Save"}
			</KonfhubButton>
		</div>
	);
};

export default FormButtons;
