import React from "react"
import TippyIcon from "../../TippyIcon";

const DeleteQuickButton = ({onClick, className, disabled, style, content, size}) => (
    <TippyIcon content={content ? content : `Delete`} placement="bottom">
        <i
        className={`fas fa-trash ${className}`}
        disabled={disabled ? disabled : false}
        onClick={onClick}
        style={{fontSize: `${size ? size : "16px"}`, color: "#DC3545" , ...(style && {...style}) }}
        ></i>
    </TippyIcon>
)

export default DeleteQuickButton;