const ValidateCloneEvent = (
    values
) => {
    const errors = {};
    const event_urlRegExp = /^[a-zA-Z0-9-]+$/;
    const numRegExp = /^[-:()\[\]|,/<>#,.&@'+\w\sÀ-ÖØ-öø-ÿ]+$/;
    const emojiRegex = /[^\u0000-\u007F]+/;


    if (values.event_name === "") errors.event_name = "Please provide an event name";
    else if (!values.event_name.match(numRegExp))
        errors.event_name = "Please enter a valid event name (special characters are not allowed)";
    else if (values.event_name.match(emojiRegex)) errors.event_name = "Emojis are not allowed";
    else if (values.event_name.legnth < 2)
        errors.event_name = "Please enter a valid event name greater than 1 character";

    if (!values.start_date && !values.start_time)
        errors.start_date = "Please choose a start date and time";

    if (!values.end_date && !values.end_time)
        errors.end_date = "Please choose a end date and time";

    if (values.start_date && values.end_date) {
        if (values.start_date > values.end_date)
            errors.start_date = "Start date can not be greater than End date";
    }

    if (values.event_url === "") errors.event_url = "Please provide an event name";
    else if (!values.event_url.match(event_urlRegExp))
        errors.event_url =
            "For event URL, special characters and spaces are not allowed (only alphanumerics & hyphes are allowed)";

    return errors;
}

export default ValidateCloneEvent;