import axios from "./axios";
const API_URL = axios.defaults.baseURL;
const BASE_URL = "/event/";
const Coupons_base = "/coupons";
const Tickets_base = "/tickets";
const validateCode = "/validate-name";

// Get Event Details
export const eventDetails = (eventId) => {
	return axios.get(API_URL + BASE_URL + eventId);
};

//////// Tax and Charges ////////
// Edit Charges
export const editTaxNCharge = (eventId, payload) => {
	return axios.put(API_URL + BASE_URL + eventId, payload);
};

// --------------------------- Coupons APIs -----------------------------

// Get Coupon details
export const getCoupons = (
	eventId,
	codeType,
	offset = 0,
	limit = 10,
	search_value = "",
	filtersQuery = "",
	sort_order = "",
	sort_column = "",
	ticket_ids = "",
	is_expired,
	is_exhausted
) => {
	let query = `?`;
	
	if (codeType && (typeof codeType === "number" || typeof codeType === "string")) {
		query += `code_type=${codeType}&`;
	}
	query += search_value ? `search_value=${search_value}&` : "";
	query += offset >= 0 ? `offset=${offset}&` : "";
	query += limit ? `limit=${limit}&` : "";
	query += sort_order ? `sort_order=${sort_order}&` : "";
	query += sort_column ? `sort_column=${sort_column}&` : "";
	query += ticket_ids ? `ticket_ids=${ticket_ids}&` : "";
	query += filtersQuery ? `${filtersQuery}&` : "";
	if (typeof is_expired === "boolean") {
		query += `is_expired=${is_expired}&`;
	}
	if (typeof is_exhausted === "boolean") {
		query += `is_exhausted=${is_exhausted}&`;
	}
	
	if (query.endsWith('&')) {
		query = query.slice(0, -1);
	}

	return axios.get(API_URL + BASE_URL + eventId + Coupons_base + "/v2" + query);
};

// Get ticket details
export const getTickets = (eventId) => {
	return axios.get(API_URL + BASE_URL + eventId + Tickets_base);
};

// Create new Coupon
export const createCoupon = (eventId, payload, isAccessCode = false) => {
	let query = `?is_access_code=${isAccessCode}`;
	return axios.post(
		API_URL + BASE_URL + eventId + Coupons_base + query,
		payload,
	);
};

export const createMultipleCoupon = (eventId, payload) => {
	return axios.post(
		API_URL + BASE_URL + eventId + Coupons_base + "/bulk",
		payload,
	);
};

// Edit a Coupon
export const editCoupon = (eventId, coupon_id, payload) => {
	let tempPayload = payload;
	return axios.put(
		API_URL + BASE_URL + eventId + Coupons_base + "/" + coupon_id,
		tempPayload,
	);
};

//generate Upload URL for Upload access codes
export const uploadAccessCodes = (eventId, payload) => {
	let tempPayload = payload;
	return axios.post(
		API_URL + BASE_URL + eventId + Coupons_base + "/upload",
		tempPayload,
	);
};

//Download coupon codes
export const downloadCouponCodes = (eventId, code_type = "1") => {
	return axios.get(
		`${
			API_URL + BASE_URL + eventId + Coupons_base
		}/download?code_type=${code_type}`,
	);
};

// Delete a Coupon
export const deleteCoupon = (eventId, coupon_ids) => {
	return axios.delete(
		API_URL + BASE_URL + eventId + Coupons_base + "?coupon_ids=" + coupon_ids,
	);
};

// Clone a Coupon
export const cloneCoupon = (eventId, coupon_id, payload) => {
	return axios.post(
		API_URL + BASE_URL + eventId + Coupons_base + "/" + coupon_id + "/clone",
		payload,
	);
};

// Validate a coupon code - with deleted coupons only
export const duplicateCouponCheck = (eventId, code) => {
	return axios.get(
		API_URL +
			BASE_URL +
			eventId +
			Coupons_base +
			validateCode +
			`?coupon_code=` +
			code,
	);
};
