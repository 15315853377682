import React from "react";

const Container = ({ children, style, className }) => {
	return (
		<>
			<div
				className={`box-shadow container-padding container-margin main-container-Page ${className ? className : ""}`}
				style={style}
			>
				{children}
			</div>
			<style>{`
				.main-container-Page {
					background-color: ${localStorage.getItem("darkMode") ? "slateGray" : "auto"};
				}
			`}</style>
		</>
	);
};

export default Container;
