import {api} from "./axios.config";
import axios from "./axios";
import { getCurrentEventId } from "../Utils/helpers";

const API_URL = api.apiUrl;
const USER_URL = "/user";
const BASE_URL = "/event/";
const EVENTS_URL = "/events";
const INFO_URL = "/info";

// Getting Organizer Event Details
export const getOrganiserEventsDetails = () => {
    return axios.get(API_URL + USER_URL +  EVENTS_URL)
}

// Getting user details
export const getProfileDetails = () => {
    return axios.get(API_URL + USER_URL)
}

// Getting user details
export const updateProfileDetails = (payload, token) => {
    return axios.put(API_URL + USER_URL, payload, { headers: { 'access_token': token } })
}

//Get Event Common Details
export const getEventCommonDetails = (eventId) => {
    return axios.get(API_URL + BASE_URL + eventId + INFO_URL)
}

//Get event details
export const getEventDetails = async (eventId) => {
	return axios.get(API_URL + BASE_URL + eventId);
};


export const getEventNdOrganiserDetails = async (eventId) => {
    const [eventComRes, eventInfoRes ] = await Promise.all([
        // getOrganiserEventsDetails(organiserId),
        getEventCommonDetails(eventId),
        getEventDetails(eventId)
      ]);

    return [ eventComRes, eventInfoRes];
}

