import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import LoadingSpinner from "./Loading";

const BackendPaginatedDataTable = ({
	columns,
	rowData,
	totalCount,
	handlePerRowsChange,
	handlePageChange,
	noDataComponent,
	onRowClicked,
	onProgress,
	selectableRows,
	setCheckboxArray,
	clearSelectedRows,
	selectableRowDisabled,
	conditionalRowStyles,
	onSort,
	sortServer,
	overflowY,
	paginationPerPage,
	pagination,
	paginationServer,
	paginationRowsPerPageOptions,
	paginationIconFirstPage,
	paginationIconLastPage,
	paginationComponentOptions = { rowsPerPageText: 'Rows per page:', rangeSeparatorText: 'of', noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'All' }
}) => {
	const [paginationServerAdded, setPaginationServerAdded] = useState(undefined);
	useEffect(()=>{
		if(paginationServerAdded === undefined){
			if(paginationServer !== undefined){
				setPaginationServerAdded(paginationServer === false ? false : true);
			} else {
				setPaginationServerAdded(true);
			}
		}
	}, [paginationServer])

	return (
		<div>
			<DataTable
				responsive
				customTheme={myTheme}
				pointerOnHover={true}
				Clicked
				highlightOnHover
				pagination={pagination || true}
				paginationServer={paginationServerAdded}
				// data that we need from parent
				columns={columns}
				data={rowData}
				noDataComponent={noDataComponent}
				paginationTotalRows={totalCount}
				paginationPerPage={paginationPerPage}
				onChangeRowsPerPage={handlePerRowsChange}
				onChangePage={handlePageChange}
				onRowClicked={onRowClicked}
				progressPending={onProgress}
				progressComponent={<LoadingSpinner />}
				selectableRows={selectableRows}
				onSelectedRowsChange={(state) => {
					setCheckboxArray && setCheckboxArray(state.selectedRows);
				}}
				clearSelectedRows={clearSelectedRows}
				selectableRowDisabled={selectableRowDisabled}
				conditionalRowStyles={conditionalRowStyles}
				onSort={onSort}
				sortServer={sortServer}
				overflowY={overflowY}
				paginationRowsPerPageOptions={paginationRowsPerPageOptions}
				paginationComponentOptions={paginationComponentOptions}
				paginationIconFirstPage={paginationIconFirstPage}
				paginationIconLastPage={paginationIconLastPage}
			/>
			<style jsx>{`
				.rdt_TableHeader + div {
					scrollbar-width: thin;
					overflow-x: auto; 
					overflow-y: hidden;
					scrollbar-color: #888 transparent;
				}
				.rdt_TableHeader {
					display: none;
				}

				.rdt_TableCol_Sortable {
					color: black;
					margin-left: 16px;
					font-weight: bold;
					font-size: 14px;
				}

				.rdt_TableHeadRow {
					background: #f5f5f0 !important;
					color: black;
				}

				.rdt_TableCol {
					justify-content: center;
					text-align: center;
					height: 100%;
				}
				.rdt_TableCell {
					justify-content: center;
					text-align: center;
					font-size: 14px !important;
				}
			`}</style>
		</div>
	);
};

const myTheme = {
	title: {
		fontSize: "22px",
		fontColor: "#1f2933",
		fontWeight: "800",
		backgroundColor: "transparent",
		height: "56px",
	},
	header: {
		fontSize: "15px",
		fontWeight: "500",
		fontColor: "#FFFFFF",
		fontColorActive: "#FFFFFF",
		backgroundColor: "#572148",
		height: "48px",
		denseHeight: "32px",
		cellPaddingLeft: "0px",
	},
	contextMenu: {
		backgroundColor: "#e3f2fd",
		fontSize: "18px",
		fontColor: "rgba(0,0,0,.87)",
		transitionTime: "225ms",
	},
	rows: {
		// default || spaced
		spacing: "default",
		fontSize: "14px",
		fontColor: "hsl(0, 0%, 29%)",
		backgroundColor: "white",
		borderWidth: "1px",
		borderColor: "#ececec",
		stripedColor: "BCBFC2",
		hoverFontColor: "#000000",
		hoverBackgroundColor: "#C1C4C6",
		hoverBorderColor: "#8F86FF",
		height: "48px",
		denseHeight: "32px",
	},
	cells: {
		cellPadding: "48px",
		fontWeight: "600 !imprtant ",
		maxWidth: "200px",
	},
	expander: {
		fontColor: "rgba(0,0,0,.87)",
		expanderColor: "rgba(0,0,0,.54)",
		expanderColorDisabled: "rgba(0,0,0,.12)",
		backgroundColor: "transparent",
	},
	pagination: {
		fontSize: "13px",
		fontColor: "rgba(0,0,0,.54)",
		backgroundColor: "transparent",
		buttonFontColor: "rgba(0,0,0,.54)",
		buttonHoverBackground: "rgba(0,0,0,.12)",
	},

	columnDefs: [{ width: "20%", targets: 0 }],
};

export default BackendPaginatedDataTable;