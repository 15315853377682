import React, { useRef, useState } from "react";
import { baseUrl, websiteUrl } from "../../config/config";
import { clickOutside } from "../../reusable/common";
import calender from "../../assets/pages/Section/ManageEvents/Union.svg";
import Location from "../../assets/pages/Section/ManageEvents/Location.svg";
import Redirect from "../../assets/pages/Section/ManageEvents/Redirect.svg";
import Share from "../../assets/pages/Section/ManageEvents/Share.svg";
import Setting from "../../assets/pages/Section/ManageEvents/Setting.svg";
import ActionMenu from "../../assets/pages/Section/ManageEvents/ActionMenu.svg";
import Mail from "../../assets/pages/Section/ManageEvents/Mail.svg";
import Facebook from "../../assets/pages/Section/ManageEvents/Facebook.svg";
import Whatsapp from "../../assets/pages/Section/ManageEvents/Whatsapp.svg";
import Twitter from "../../assets/pages/Section/ManageEvents/Twitter.svg";
import LinkedIn from "../../assets/pages/Section/ManageEvents/LinkedIn.svg";
import Copy from "../../assets/pages/Section/ManageEvents/Copy.svg";
import Team from "../../assets/pages/Section/ManageEvents/Team.svg";
import Clone from "../../assets/pages/Section/ManageEvents/Clone.svg";
import {
	TwitterShareButton,
	FacebookShareButton,
	WhatsappShareButton,
	LinkedinShareButton,
	EmailShareButton
} from "react-share";
import TippyIcon from "../../revampComponents/Atom/TippyIcon";
import moment from "moment";

const EventCardComponent = ({ data, callGetEventDetails, setOpenCloneModal, history, formatDate }) => {
	const [openSharePopup, setOpenSharePopup] = useState("");
	const [openActionPopup, setOpenActionPopup] = useState("");
	const [isCopied, setIsCopied] = useState(false);
	const shareRef = useRef(null);
	const actionRef = useRef(null);

	clickOutside(shareRef, () => {
		setOpenSharePopup(false);
	});

	clickOutside(actionRef, () => {
		setOpenActionPopup(false);
	});

	const { venue, city, state, country } = data;
	const locationArray = [venue, city, state, country];
	const filteredLocationArray = locationArray.filter(Boolean);
	const locationString = filteredLocationArray.join(", ");

	return (
		<div
			className="col-12 col-sm-6 col-md-6 col-lg-3 mt-5"
			onClick={e => {
				e.stopPropagation();
			}}
		>
			<div className="w-100 d-flex justify-content-center h-100">
				<div
					className="event_card_wrapper d-flex flex-column"
					onClick={e => {
						e.stopPropagation();
						window.open(`/e/${data?.event_id}`, "_blank");
					}}
				>
					<div
						className="event_card_background_image"
						style={{ background: `url('${data?.event_poster_url}')` }}
					></div>
					<div className="event_card_event_name text-left d-flex align-items-center">
						{data?.event_name}
					</div>
					<div className="d-flex align-items-center event_card_event_date_wrapper  d-flex align-items-center">
						<span className="mr-2">
							<img
								src={calender}
								className="event_card_item_img_calender"
								alt="calender"
							/>
						</span>
						<span className="event_card_event_date text-left">{`${formatDate(data)} - ${formatDate(data, true)}`}</span>
					</div>
					{!locationString && <div style={{ flexGrow: 1 }} />}

					{locationString && (
						<div className="d-flex align-items-center event_card_location_wrapper  d-flex align-items-center">
							<span className="mr-1 align-self-start">
								<img
									src={Location}
									className="event_card_item_img_location"
									alt="Location"
								/>
							</span>
							<span className="event_card_event_location text-left">
								{locationString}
							</span>
						</div>
					)}
					{locationString && <div style={{ flexGrow: 1 }} />}

					<div className="my-3 event_card_border_line" />
					<div
						ref={shareRef}
						className="d-flex align-items-center w-100"
						onClick={e => {
							e.stopPropagation();
						}}
					>
						<div className="text-left event_card_event_type" style={{ width: "40%" }}>
							{data?.is_free ? "Free" : "Paid"}
						</div>
						<div
							className="d-flex align-items-center justify-content-end"
							style={{ width: "60%" }}
						>
							<div className="w-25 text-right">
								<TippyIcon content={"Go to Event Page"}>
									<img
										role="button"
										src={Redirect}
										alt="Redirect_link"
										onClick={() => {
											window.open(`${baseUrl}/${data?.event_url}`, "_blank");
										}}
									/>
								</TippyIcon>
							</div>
							<div className="w-25 text-right">
								<TippyIcon content={"Share"}>
									<img
										role="button"
										src={Share}
										alt="Share"
										onClick={e => {
											e.stopPropagation();
											setOpenActionPopup(false);
											setOpenSharePopup(!openSharePopup);
										}}
									/>
								</TippyIcon>

								{openSharePopup && (
									<div className="event_card_share_popup">
										<div className="text-left mb-2 share_via">Share Via</div>
										<div className="d-flex justify-content-between">
											<EmailShareButton
												subject={`Event - Konfhub Technologies`}
												body={`Hey! Register for our latest ${data?.event_name}\n\n`}
												url={`${baseUrl}/${data?.event_url}`}
											>
												<img role="button" src={Mail} alt="Mail" />
											</EmailShareButton>
											<WhatsappShareButton
												title={`Hey! Register for our latest ${data?.event_name}\n\n`}
												url={`${baseUrl}/${data?.event_url}`}
											>
												<img role="button" src={Whatsapp} alt="Whatsapp" />
											</WhatsappShareButton>
											<FacebookShareButton
												quote={`Hey! Register for our latest ${data?.event_name}\n\n`}
												url={`${baseUrl}/${data?.event_url}`}
											>
												<img role="button" src={Facebook} alt="Facebook" />
											</FacebookShareButton>
											<TwitterShareButton
												title={`Hey! Register for our latest ${data?.event_name}\n\n`}
												url={`${baseUrl}/${data?.event_url}`}
											>
												<img role="button" src={Twitter} alt="Twitter" />
											</TwitterShareButton>
											<LinkedinShareButton
												summary={`Hey! Register for our latest ${data?.event_name}\n\n`}
												url={`${baseUrl}/${data?.event_url}`}
											>
												<img role="button" src={LinkedIn} alt="LinkedIn" />
											</LinkedinShareButton>
											<TippyIcon content={isCopied ? "" : "Copy"}>
												<img
													role="button"
													src={Copy}
													alt="Copy"
													onClick={() => {
														navigator.clipboard.writeText(
															`Hey! Register for our latest ${data?.event_name}\n\n ${baseUrl}/${data?.event_url}`
														);
														setIsCopied(true);
														setTimeout(() => setIsCopied(false), 1000);
													}}
												/>
											</TippyIcon>
										</div>
									</div>
								)}
							</div>
							<div className="w-25 text-right">
								<TippyIcon content={"Go to Event Dashboard"}>
									<img
										role="button"
										src={Setting}
										alt="Setting"
										onClick={() => {
											history.push(`/e/${data?.event_id}`);
										}}
									/>
								</TippyIcon>
							</div>
							<div ref={actionRef} className="text-right" style={{ width: "20%" }}>
								<TippyIcon content={"Menu Items"}>
									<img
										role="button"
										src={ActionMenu}
										alt="ActionMenu"
										onClick={e => {
											e.stopPropagation();
											setOpenSharePopup(false);
											setOpenActionPopup(!openActionPopup);
										}}
									/>
								</TippyIcon>

								{openActionPopup && (
									<div className="event_card_action_popup d-flex flex-column">
										{/* <div
											role="button"
											className="d-flex justify-content-between align-items-center"
											onClick={() => {
												if (data?.event_id) {
													callGetEventDetails(data);
													setOpenCloneModal(true);
												}
											}}
										>
											<img src={Clone} alt="Mail" />
											<div className="action_texts">Clone</div>
										</div> */}
										<div
											role="button"
											className="d-flex justify-content-between align-items-center"
											onClick={() => {
												history.push(`/e/${data?.event_id}/team`);
											}}
										>
											<img src={Team} alt="Mail" />
											<div className="action_texts">Team</div>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
			<style>
				{`
                    .event_card_wrapper {
                        width:100%;
                        min-height: 322.3px;
                        padding: 12px;
                        border-radius: 5px;
                        box-shadow: 0px 0px 16.72px 0px #57214833;
                        background: #FFFFFF;
                        border: 3px solid #FFF;
                    }
                    .event_card_wrapper:hover {
                        width:100%;
                        min-height: 322.3px;
                        padding: 12px;
                        border-radius: 5px;
                        box-shadow: 0px 0px 16.72px 0px #57214833;
                        background: #FFFFFF;
                        border: 3px solid #572148;
						cursor: pointer;
                    }
                    .event_card_background_image {
                        height: 129px !important;
                        background-position: center !important;
                        background-repeat: no-repeat !important;
                        background-size: cover !important;
                        box-shadow: 0px 0px 3px 0px #57214833;
                    }
                    .event_card_event_name {
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 19.2px;
                        color: #333333;
                        margin-top: 20px;
                    }
                    .event_card_event_date_wrapper {
                        margin-top: 12px;
                    }
                    .event_card_item_img_calender {
                        margin-bottom: 3px;
                    }
                    .event_card_item_img_location {
                        margin-top: 7px;
						margin-left: -1px;
						width: 15.44px;
						height: 15.44px;
						max-width: fit-content;
                    }
                    .event_card_event_location {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 22.41px;
                        color: #333333;
                        margin-top: 5px;
						margin-left: 2px;
						overflow: hidden;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
						text-overflow: ellipsis;
                    }
                    .event_card_event_date {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 22.41px;
                        color: #333333;
                    }
                    .event_card_border_line {
                        border-top: 1px solid #C3CDDF3D;
                    }
                    .event_card_event_type {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 22.41px;
                        color: #572148;
                    }
                    .event_card_share_popup {
                        padding: 10px 7px;
                        bottom: 45px;
                        left: 60px;
                        position: absolute;
                        background: #fff;
                        width: 190px;
                        border-radius: 2px;
                        box-shadow: 0px 2.13px 13.29px 0px #0000001A, 0px -0.53px 13.29px 0px #0000001A;
                    }
                    .event_card_share_popup .share_via {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 19.21px;
                        color: #6C757D;
                    }
                    .event_card_action_popup {
                        width: 78px;
                        // height: 66px;
						height: 41px;
                        border-radius: 2px;
                        background: #fff;
                        padding: 10px;
                        position: absolute;
                        bottom: 40px;
                        right: 32px;
                        box-shadow: 0px 2.13px 13.29px 0px #0000001A, 0px -0.53px 13.29px 0px #0000001A;
                    }
                    .event_card_action_popup .action_texts {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 24px;
                        color: #6D7780;
                    }
                    @media (max-width: 426px){
                        .event_card_share_popup {
                            position: absolute;
                            bottom: 45px;
                            left: 118px !important;
                        }
                    }
                `}
			</style>
		</div>
	);
};

export default EventCardComponent;
