import React from "react";
import CustomModal from "./CustomModal";
import warning from "../../../assets/img/Models/warning.svg";
import ticketRejectIcon from "./../../../assets/img/bulk-actions/Ticket.svg";
import ReactHtmlParser from "react-html-parser";

const DeleteModal = ({
	openModal,
	setOpenModal,
	setLoad,
	onOkay,
	onCancel,
	isLoading,
	item,
	text,
	subText,
	width,
	primaryBtnContent,
	cancelBtnDisabled,
	saveBtnDisabled,
	cancelBtnContent,
	extraComponent,
	notifyAttendee,
	setNotifyAttendee,
	showCheckbox = false
}) => {
	return (
		<>
			<CustomModal
				width={width}
				openModal={openModal}
				setOpenModal={setOpenModal}
				setLoad={setLoad}
				saveBtnContent={primaryBtnContent || "Delete"}
				content={
					<div className="mt-2 mx-4 d-flex flex-column align-items-center justify-content-center text-center">
						<div>
							<img
								src={showCheckbox ? ticketRejectIcon : warning}
								className="mb-3"
								style={{
									width: "4rem"
								}}
							/>
						</div>
						<p className="formTitle fw-bold mb-2 text-center text-danger">
							{ReactHtmlParser(item)}
						</p>

						<div className="text-center custom_text_style">
							<div className="text-center">{ReactHtmlParser(text)}</div>
						</div>
						{showCheckbox && (
							<div className="note-text label text-center mt-4">
								<input
									type="checkbox"
									checked={notifyAttendee}
									onChange={() => setNotifyAttendee(!notifyAttendee)}
								/>
								{ReactHtmlParser(subText)}
							</div>
						)}
						{extraComponent && <>{extraComponent}</>}
					</div>
				}
				isLoading={isLoading}
				onClose={onCancel}
				onCancel={onCancel}
				onOkay={onOkay}
				cancelBtnDisabled={cancelBtnDisabled}
				saveBtnDisabled={saveBtnDisabled}
				cancelBtnContent={cancelBtnContent}
			/>
			<style jsx>{`
				.note-text {
					background: #f2f2f2;
					border: 1px solid #cdd4db;
					border-radius: 5px;
					padding: 10px;
					color: #6c757d;
				}
				.custom_text_style {
					font-family: Hind;
					font-size: 15px;
					font-weight: 400;
					line-height: 24px;
					letter-spacing: 0em;
					text-align: center;
					color: #572148;
				}
				.warning--subtext-box {
					border: 1px solid #e2e2e2;
					background: #f8f8f8;
					border-radius: 5px;
					font-family: Hind;
					font-size: 14px;
					font-weight: 400;
					line-height: 22px;
					letter-spacing: 0em;
					text-align: center;
					color: #6c757d;
				}
			`}</style>
		</>
	);
};

export default DeleteModal;
