import React, { Suspense, useContext, useEffect, useState } from "react";
import {
	Route,
	Switch,
	Redirect,
	useHistory,
	useParams
} from "react-router-dom";
import { logout, restrictedLitePages } from "./../reusable/common";
// import Loading from './../components/Loading';
import AllRoutes from "./Routes";
import AuthContext from "./../contexts/AuthContext";
import KonfhubLoad from "../components/KonfhubLoad";
import {
	getEventNdOrganiserDetails, updateProfileDetails
} from "./../revampCRUD/app.crud";
// import EventContext from "./../contexts/EventContext";
import { featureRestrictions, getCurrentEventId, isFeatureRestricted, sortEventDetails } from "../Utils/helpers";
import Banner from "../revampComponents/Atom/Banner";
import Container from "../components/Container";
import DashboardLayout from "./../containers/DashboardLayout";
import DashboardContext from "../contexts/DashboardContext";

const Page404 = React.lazy(() => import("./../pages/Page404"));
// const DashboardLayout = React.lazy(() =>
// 	import("./../containers/DashboardLayout")
// );
// const ProfileLayout = React.lazy(() => import('./../containers/ProfileLayout'));

export default function DashboardRoutes(props) {
	const Context = useContext(AuthContext);
	const history = useHistory();
	const { eventId } = useParams();
	const [prefeventinfo, setPrefeventinfo] = useState(null);
	const [isApiLoading, setIsApiLoading] = useState(false);
	const [eventInfo, setEventInfo] = useState(null);
	const [free, setFree] = useState(null);
	const [switchedPlan, setSwitchedPlan] = useState(false);

	const getAllDetails = async (event_id) => {
		// Getting Organizer Event Details
		let organiserDetails = Context.organiserDetails;
		let lastEvent = Context.user.attributes["custom:lastEvent"];
		setIsApiLoading(true);
		if(Context.organiserDetails.length > 0 && event_id) {
			try {
				const [eventComRes, eventInfoRes] = await getEventNdOrganiserDetails(
					event_id
				);
				// setOrganiserDetails(organiserDetails);
				if (eventComRes.status == 200) {
					setPrefeventinfo(eventComRes.data);
					setFree(eventComRes.data.is_free);
					if (eventInfoRes.status == 200) {
						// setEventInfo(eventInfoRes.data);
						const eventPlans = {1: "Free", 2: "Lite", 3: "Silver", 4: "Gold"}
						eventInfoRes.data.eventPlanName =  eventPlans[eventInfoRes.data.event_plan]
						Context.setEventDetails(eventInfoRes.data);
					}
					if (lastEvent !== event_id) updateLastAccessedEvent(event_id); // only update last accessed event id if it is different from previous last accessed id
				} else if (
					eventComRes.status == 403
				) {
					alert("You are not authorized to access this event");
					history.push(
						`/e/${organiserDetails[0].event_id}/`
					);
					getAllDetails(organiserDetails[0].event_id);
				}
			} catch (err) {
				console.log("cs -- error log -- ", err);
				alert("This event doesn't exist, we will redirect you to your last accessed event.");
				history.push(
					`/e/${lastEvent ? lastEvent : organiserDetails[0].event_id}/`
				);
			}
		} else {
			alert("You currently have no events, please create an event");
			history.push(
				`/create/event`
			);
		}
		setIsApiLoading(false);
	};

	const updateLastAccessedEvent = async (eventId) => {
		try {
			let res = updateProfileDetails({ last_accessed_event: eventId }, Context.user.signInUserSession.accessToken.jwtToken)
		} catch (err) {
			console.log(err)
		}
	}

	useEffect(() => {
		if (eventId) getAllDetails(eventId);
	}, [eventId]);

	return (
		<Suspense fallback={<KonfhubLoad />}>
			<DashboardContext.Provider value={{
				switchedPlan,
				setSwitchedPlan
			}}>
				{(prefeventinfo && !isApiLoading) ? <DashboardLayout
					{...props}
					prefeventinfo={prefeventinfo}
					eventInfo={Context.eventInfo}
					getAllDetails={getAllDetails}>
					<Switch>
						{AllRoutes.map((route, index) => {
							switch (route.authentication) {
								case "unauthenticated":
									return (
										<Redirect key={route.path} exact from={route.path} to={`/`} />
									);
								case "authenticated":
								case "both":
								default:
									if (route.layout) {
										return (
											<Route
												exact={route.exact}
												path={route.path}
												key={route.path}
												name={route.name}
												render={pageProps => (
													<Container>
														{((featureRestrictions[Context.eventInfo.event_plan]?.includes(route.name)) && route.name !== "Email") && (
															<Banner>{route.restrictionMessage ? route.restrictionMessage : `${route.name} is not available in your current plan. Upgrade your plan to access this feature.`}</Banner>
														)}
														<route.component
															prefeventinfo={prefeventinfo}
															eventInfo={Context.eventInfo}
															isRestricted={isFeatureRestricted(Context.eventInfo.event_plan, route.name)}
															free={free}
															pageName={route.name}
															getAllDetails={getAllDetails}
															{...props}
															{...pageProps}
															key={index}
														/>
													</Container>
												)}
											/>
										);
									}
							}
						})}
						<Redirect to="/error" />
					</Switch>
				</DashboardLayout> : <KonfhubLoad />}
			</DashboardContext.Provider>
		</Suspense>
	);
}
