import React, { useState, useRef } from "react";
import { clickOutside } from "../../reusable/common";
import LoadingSpinner from "../../revampComponents/Atom/Loading";
import Menu from "../../assets/img/Profile Dropdown/Circled Menu.svg";
import KonfHub from "../../assets/img/Profile Dropdown/KonfHub.svg";
import QuizHub from "../../assets/img/Profile Dropdown/QuizHub.svg";
import CertHub from "../../assets/img/Profile Dropdown/CertHub.svg";
import { certhubUrl } from "../../config/config";
import MyCommunity from "./MyCommunity/MyCommunity";
import { KonfhubButton } from "../../revampComponents/Atom/Buttons";
const SwitchApplications = ({
	className,
	callCommunityFunction,
	handleCommunityStateChange,
	communityButtonLoading,
	history,
	eventId,
	communityRef
}) => {
	const wrapperRef = useRef("menu");
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);

	clickOutside(wrapperRef, () => {
		setOpen(false);
	});

	return (
		<>
			{loading ? (
				<LoadingSpinner />
			) : (
				<div
					className={`switch-container position-relative ml-3  ${className ? className : ""}`}
				>
					{open ? <span className="arrow-up" /> : ""}

					<div ref={wrapperRef} className="switch-img-container d-inline-block">
						<div className="d-flex align-items-center justify-content-center h-100 w-100 position-relative">
							<img
								style={{ width: "22px", height: "22px" }}
								src={Menu}
								alt={"Profile Picture"}
							/>
							<button
								onClick={() => setOpen(!open)}
								className="switch-overlay h-100 w-100 position-absolute"
							></button>
						</div>
						{open && (
							<div className="switch-dropDown-container p-2 position-absolute">
								<div className="switch-dropdown-options text-left">
									{/* <a href={`https://www.konfhub.com`}><span className="d-flex align-items-center"> <img src={KonfHub} className="mr-3" alt="" /> KonfHub</span> </a> */}
									<a href={`https://quizadmin.konfhub.com/`}>
										<span className="d-flex align-items-center">
											<img src={QuizHub} className="mr-2 mb-1" alt="" />{" "}
											QuizHub
										</span>
									</a>
									<a href={certhubUrl}>
										<span className="d-flex align-items-center">
											<img
												src={CertHub}
												style={{ marginRight: "10px" }}
												alt=""
												className="mb-1"
											/>{" "}
											CertHub
										</span>
									</a>
									{
										<>
											<div
												className="my_community_div"
												onClick={() => {
													if (!communityButtonLoading) {
														callCommunityFunction();
														setOpen(false);
													}
												}}
											>
												<span>
													<i
														style={{ color: "#572148" }}
														className="fas fa-users ml-1"
													/>
												</span>
												<span>&nbsp;&nbsp;My Community</span>
											</div>
										</>
									}
								</div>
							</div>
						)}
					</div>
					<style>
						{`
                        .switch-container {
                            height: 35px;
                        }
                        .switch-img-container {
                            height: 35px;
                            width: 35px;
                            border-radius: 100%;
                            overflow: hidden;
                            // border: 1px solid #fff;
                            background: #fff;
                            box-shadow:1px 1px 10px  #0002;
                        }

                        .switch-overlay {
                            display:none;
                            top: 0;
                            background: #000;
                            opacity: 0.3;
                            border-radius: 100%;
                        }

                        .switch-img-container:hover .switch-overlay {
                            display:block;
                            cursor: pointer;
                        }

                        .arrow-up {
                            width: 0;
                            height: 0;
                            margin-top: -50px !important;
                            border-left: 8px solid transparent;
                            border-right: 8px solid transparent;
                            position: absolute;
                            top: 87px;
                            right: 12px;
                            border-bottom: 16px solid white;
                        }
                        .switch-dropDown-container {
                            z-index: 1030;
                            width: 175px;
                            background: #fff;
                            right: 0px;
                            border-radius: 3px;
                            top: 48px;
                            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
                        }

                        .switch-dropdown-options > *:not(.my_community_div) {
                            display: block !important;
                            width: 100% !important;
                            padding: 5px 0px 5px 6px !important;
                            border-radius:2px !important;
                            color: #2D2D2D !important;
                            font-family: Nunito !important;
                            font-size: 16px !important;
                            font-weight: 500 !important;
                            line-height: 16px !important;
                            letter-spacing: 0em !important;
                            text-align: left !important;
                            text-decoration: none !important;
                        }

                        .switch-dropdown-options > *:hover {
                            background: #80747C33;
                            color: #2D2D2D;
                            cursor:pointer
                        }

                        .switchEvent-pad {
                            padding-bottom: 0.8rem !important;
                        }

                        ${
							communityButtonLoading &&
							`
                                .my_community_div {
                                    cursor: not-allowed !important;
                                    pointer-events: none !important;
                                }
                            `
						}
                        `}
					</style>
				</div>
			)}
		</>
	);
};

export default SwitchApplications;
