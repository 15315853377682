import React from "react"
import TippyIcon from "../../TippyIcon";
import CloneIcon from "../../../../assets/CloneIcon.svg";

const CloneQuickButton = ({onClick, className, disabled, style, content, size}) => (
    <TippyIcon content={content ? content : `Clone`} placement="bottom">
        <i className={`far fa-clone ${className}`}
         style={{fontSize: `${size ? size : "16px"}`, color: "#10377b", ...(style && {...style}) }}
         disabled={disabled}
         onClick={onClick}
        ></i>
		{/* <img
			className={`${className}`}
            disabled
			src={CloneIcon}
			style={{ cursor: "pointer" , ...(style && {...style})}}
			onClick={onClick}
		/> */}
    </TippyIcon>
)

export default CloneQuickButton;